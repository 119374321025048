import React from 'react';
import config from '../config';
import { CircularProgress } from '@mui/material';


// Define your themes
const lightTheme = {
  backgroundColor: '#f2f2f2',
  color: '#000000',
  optionSelectedBg: '#e0e0e0',
  optionFocusedBg: '#f2f2f2',
  menuBg: 'white',
};

const darkTheme = {
  backgroundColor: '#353A36',
  color: '#FFFFFF',
  optionSelectedBg: '#353A36',
  optionFocusedBg: '#4a4a4a',
  menuBg: '#353A36',
};

// Function to get theme styles
const getThemeStyles = (theme) => (theme === 'dark' ? darkTheme : lightTheme);

// Styles for the + selects
export const customStylesPlus = (theme = 'light') => {
  const themeStyles = getThemeStyles(theme);

  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: themeStyles.backgroundColor,
      border: 'none',
      borderRadius: '2rem',
      padding: '0 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '3rem',
      maxHeight: '3rem',
      overflow: 'auto',
      cursor: 'pointer',
      fontSize: '1.25rem',
      whiteSpace: 'nowrap',
      minWidth: '5rem',
      color: themeStyles.color,
      fontFamily: "'SF Pro Display Bold', sans-serif !important"
     
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? themeStyles.optionSelectedBg
        : isFocused
        ? themeStyles.optionFocusedBg
        : themeStyles.menuBg,
      color: themeStyles.color,
      padding: '0.5rem 2rem',
      cursor: 'pointer',
      fontSize: '1.25rem',
      textAlign: 'left',
      
      fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: themeStyles.backgroundColor,
      borderRadius: '1rem',
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      fontSize: '1.25rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: themeStyles.color,
      fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: themeStyles.color,
      fontSize: '1.25rem',
      padding: '0 0.5rem',
      backgroundColor: theme === 'dark' ? '#353A36' : '#f2f2f2',
      borderRadius: '0.25rem 0 0 0.25rem',
      cursor: 'pointer',
      fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: themeStyles.color,
      backgroundColor: theme === 'dark' ? '#353A36' : '#f2f2f2',
      marginRight: '0.5rem',
      borderRadius: '0 0.25rem 0.25rem 0',
      height: '1.5rem',
      cursor: 'pointer',
      fontFamily: "'SF Pro Display Bold', sans-serif !important",
      ':hover': {
        backgroundColor: theme === 'dark' ? '#4a4a4a' : '#e0e0e0',
        color: themeStyles.color,
         fontFamily: "'SF Pro Display Bold', sans-serif !important"
      },
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '0.5rem',
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
      backgroundColor: themeStyles.menuBg,
      color: themeStyles.color,
      
      fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    singleValue: (styles) => ({
      ...styles,
      padding: '0 0.5rem',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      cursor: 'pointer',
      fontSize: '1.25rem',
      color: themeStyles.color,
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    placeholder: (styles) => ({
      ...styles,
      color: themeStyles.color,
      fontSize: '1.25rem',
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
  };
};



// Styles for other selects
export const customStyles = (theme = 'light') => {
  const themeStyles = getThemeStyles(theme);

  return {
    control: (provided) => ({
      ...provided,
      backgroundColor: themeStyles.backgroundColor,
      height: '3rem',
      minHeight: '3rem',
      padding: '0 2rem',
      borderRadius: '2rem',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      fontSize: '1.25rem',
      cursor: 'pointer',
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: themeStyles.color,
      padding: '0',
      cursor: 'pointer',
      fontSize: '1.25rem',
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.5rem',
      fontSize: '1.25rem',
      cursor: 'pointer',
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0.5rem',
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
      backgroundColor: themeStyles.menuBg,
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? themeStyles.optionSelectedBg
        : state.isFocused
        ? themeStyles.optionFocusedBg
        : themeStyles.menuBg,
      color: themeStyles.color,
      padding: '0.5rem 2rem',
      cursor: 'pointer',
      fontSize: '1.25rem',
      textAlign: 'left',
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
  };
};


// Styles for the + selects
export const customStylesPlusTrans = (theme = 'light') => {
  const themeStyles = getThemeStyles(theme);

  return {
    control: (styles) => ({
      ...styles,
      backgroundColor:'transparent',
      border: 'var(--border)',
      borderRadius: '2rem',
      padding: '0 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '3rem',
      maxHeight: '3rem',
      overflow: 'auto',
      cursor: 'pointer',
      fontSize: '1.25rem',
      whiteSpace: 'nowrap',
      minWidth: '5rem',
      color: themeStyles.color,
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? themeStyles.optionSelectedBg
        : isFocused
        ? themeStyles.optionFocusedBg
        : themeStyles.menuBg,
      color: themeStyles.color,
      padding: '0.5rem 2rem',
      cursor: 'pointer',
      fontSize: '1.25rem',
      textAlign: 'left',
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      borderRadius: '1rem',
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      fontSize: '1.25rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: themeStyles.color,
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: themeStyles.color,
      fontSize: '1.25rem',
      padding: '0 0.5rem',
      backgroundColor: 'transparent',
      borderRadius: '0.25rem 0 0 0.25rem',
      cursor: 'pointer',
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: themeStyles.color,
      backgroundColor: 'transparent',
      marginRight: '0.5rem',
      borderRadius: '0 0.25rem 0.25rem 0',
      height: '1.5rem',
      cursor: 'pointer',
       fontFamily: "'SF Pro Display Bold', sans-serif !important",
      ':hover': {
        backgroundColor: theme === 'dark' ? '#4a4a4a' : '#e0e0e0',
        color: themeStyles.color,
         fontFamily: "'SF Pro Display Bold', sans-serif !important"
      },
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '0.5rem',
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
      backgroundColor: 'transparent',
      color: themeStyles.color,
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    singleValue: (styles) => ({
      ...styles,
      padding: '0 0.5rem',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      cursor: 'pointer',
      fontSize: '1.25rem',
      color: themeStyles.color,
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    placeholder: (styles) => ({
      ...styles,
      color: themeStyles.color,
      fontSize: '1.25rem',
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
  };
};

// Styles for other selects
export const customStylesTrans = (theme = 'light') => {
  const themeStyles = getThemeStyles(theme);

  return {
    control: (provided) => ({
      ...provided,
      backgroundColor:'transparent',
      height: '3rem',
      minHeight: '3rem',
      padding: '0 2rem',
      borderRadius: '2rem',
      display: 'flex',
      alignItems: 'center',
      border: 'var(--border)',
      fontSize: '1.25rem',
      cursor: 'pointer',
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: themeStyles.color,
      padding: '0',
      cursor: 'pointer',
      fontSize: '1.25rem',
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.5rem',
      fontSize: '1.25rem',
      cursor: 'pointer',
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0.5rem',
      borderRadius: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      cursor: 'pointer',
      fontSize: '1.25rem',
      backgroundColor: themeStyles.menuBg,
      color: themeStyles.color,
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? themeStyles.optionSelectedBg
        : state.isFocused
        ? themeStyles.optionFocusedBg
        : themeStyles.menuBg,
      color: themeStyles.color,
      padding: '0.5rem 2rem',
      cursor: 'pointer',
      fontSize: '1.25rem',
      textAlign: 'left',
      
       fontFamily: "'SF Pro Display Bold', sans-serif !important"
    }),
  };
};




// Custom Date Input component
export const CustomDateInput = React.forwardRef(
  ({ pretext='', value, onClick, theme = 'light', mainColor = false}, ref) => {
    const themeStyles = getThemeStyles(theme);
    const displayText = pretext.length === 0 && value.length === 0 ? 'Select Date' : `${pretext} ${value}`;
    return (
      <div
      className='bold'
        onClick={onClick}
        ref={ref}
        style={{
          backgroundColor: mainColor ? 'var(--main-color)' : themeStyles.backgroundColor,
          color: themeStyles.color,
          border: 'none',
          borderRadius: '2rem',
          padding: '0 2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '3rem',
          maxHeight: '3rem',
          overflow: 'auto',
          cursor: 'pointer',
          fontSize: '1.25rem',
        }}
      >
        {displayText}
        <ArrowSvg />
      </div>
    );
  }
);

  const ArrowSvg  = () => {
    return (
      <svg height="2rem" width="2rem" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
    );
  }
  


  export const getImageUrl = (urlString) => {
    return urlString ? `${config.backendUrl}/${urlString}` : '';

  }
  
  export const LoadingSpinner = () => { 
    return <CircularProgress 
    size="3rem" 
    sx={{ color: 'var(--stock-change-pos)', margin: '1rem auto', display: 'block' }} 
  />

  }

export const formatAmount = (amount) => {
  if (typeof amount !== 'number' || isNaN(amount)) {
    return '+0.00';
  }
  const sign = amount >= 0 ? '+' : '-';
  const formattedAmount = Math.abs(amount).toFixed(2);
  return `${sign}${formattedAmount}`;
};

// Helper function to format percentages with sign
export const formatPercentage = (percentage) => {
  if (typeof percentage !== 'number' || isNaN(percentage)) {
    return '+0.00%';
  }
  const sign = percentage >= 0 ? '+' : '-';
  const formattedPercentage = Math.abs(percentage).toFixed(2);
  return `${sign}${formattedPercentage}%`;
};



export const formatCurrency = (value, decimalPlaces = 2, abs = false) => {
  let originalValue = value;
  let numValue = typeof value === 'number' ? value : Number(value);
  if (abs) {
    numValue = Math.abs(numValue);
  }
  if (isNaN(numValue)) {
    return abs ? Math.abs(originalValue) : originalValue; 
  }

  return numValue.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
};

export const removeCommas = (value) => {
  if (typeof value !== 'string') return value; // Return the original value if it's not a string
  return value.replace(/,/g, '');
};

export const formatOptionNameFromTicker = (ticker) => {
  if (!ticker.startsWith('O:')) return ticker; // Return the original ticker if it's not an option
  const regex = /^O:([A-Z]+)(\d{2})(\d{2})(\d{2})([CP])(\d{8})$/;
  const match = ticker.match(regex);
  if (match) {
    const [, underlying, year, month, day, type, strikePrice] = match;

    // Construct the expiration date without time zone issues
    const expirationDate = `${month}/${day}/20${year}`;
    const formattedStrikePrice = (parseInt(strikePrice, 10) / 1000).toFixed(2);
    const optionType = type === 'C' ? 'Call' : 'Put';

    return `${underlying} $${formattedStrikePrice} ${optionType} ${expirationDate}`;
  }

  return ticker; // Return the original ticker if parsing fails
};

export const extractTicker = (ticker) =>{ 
  if (!ticker.startsWith('O:')) return ticker; 
  return ticker.match(/:(\D+)\d/)?.[1] || ticker

};



export const textFieldStyles = {
  marginBottom: '1rem',
  '& .MuiInputBase-input': {
    color: 'var(--text-color)', // Input text color
  },
  '& .MuiInputLabel-root': {
    color: 'var(--text-color)', // Label text color
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--text-color)', // Border color
    },
    '&:hover fieldset': {
      borderColor: 'var(--text-color)', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--text-color)', // Border color when focused
    },
  },
}
