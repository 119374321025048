import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import dots from '../../assets/3dots.svg';  
import { handleDeletePost, handleReportClick, handleTrendingRankSubmit } from '../../helper/PostHelper';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { textFieldStyles } from '../../helper/StylesHelper';


export default function Dots({
    title,
    content,
    categories,
    sentiment,
    taggedAssets,
    type,
    caption,
    images,
    isQuotePost,
    quotePostId,
    postId, 
    isAdmin, 
    username, 
    isComment, 
    parentPostId, 
    isCopyRepost, 
    refreshFeed
}) {
    const { userToken, isAuthenticated, username: currentUsername } = useContext(AuthContext);
    const { dk } = useContext(ThemeContext);
    const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
    const [trendingRankInput, setTrendingRankInput] = useState('');
    const navigate = useNavigate();

    const closePopover = () => {
        setDeleteAnchorEl(null);
    };

    const handleEditPost = () => {  
        if (!isAuthenticated) {
            alert('Please sign in to edit posts');
        }
    
        navigate(`/createpost`, {
            state: {
                fromFeed: true,
                editPost: {
                    title,
                    content,
                    categories,
                    sentiment,
                    type,
                    caption,
                    images,
                    _id: postId,
                    isQuotePost,
                    quotePostId,
                    taggedAssets,
                },
            },
        });
    };

    const handleDeleteClick = async () => {
        const confirm = window.confirm('Are you sure you want to delete this post?');
        if (!confirm) {
            closePopover();
            return;
        }

        const success = await handleDeletePost({ postId, userToken, config, isCopyRepost, parentPostId, isComment });
        if (success) {
            refreshFeed();
        } else {
            alert('Failed to delete the post. Please try again.');
        }
    };

    const handleActionsClick = (event) => {
        event.stopPropagation();
        if (!isAuthenticated) {
            alert('Please sign in to access this feature');
            return;
        }
        setDeleteAnchorEl(event.currentTarget);
    };


    return (
        <>
            <div className="ActionItem" onClick={handleActionsClick}>
                <object aria-label="actions" type="image/svg+xml" data={dots} alt="More options" className="ActionIcon" />
            </div>

            {/* Delete Confirmation & More Options Popover */}
            <Popover
                open={Boolean(deleteAnchorEl)}
                anchorEl={deleteAnchorEl}
                onClose={closePopover}
                slotProps={{
                    paper: {
                        sx: {
                            backgroundColor: dk() ? '#353A36' : '#FFFFFF', // Replace background logic with theme-based dynamic colors
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
                    {/* Delete option */}
                    {(isAdmin || currentUsername === username) && <Button onClick={handleDeleteClick} style={{ color: dk() ? '#fff' : '#000'}}>Delete Post</Button>}

                    {/* Edit option */}
                    {(currentUsername === username) && <Button onClick={handleEditPost} style={{ color: dk() ? '#fff' : '#000'}}>Edit Post</Button>}

                    {/*  Report button */}
                    <Button style={{color:dk() ? '#fff' : '#000'}} onClick={() => handleReportClick(postId, userToken, closePopover)} >Report</Button>

                    {/* Copy Link button */}
                    <Button onClick={async () => {
                        await navigator.clipboard.writeText(`https://vestr.io/post/${postId}`);
                        alert('Link copied to clipboard');
                    }} style={{ color: dk() ? '#fff' : '#000' }}>Copy Link</Button>

                    {/* Set as trending button */}
                    {isAdmin && <Button style={{ color: dk() ? '#fff' : '#000' }} onClick={() => setTrendingRankInput(true)}>Set trending rank</Button>}

                    {/* Cancel button */}
                    <Button style={{ color: dk() ? '#fff' : '#000' }} onClick={closePopover}>Cancel</Button>
                </div>
            </Popover>

            {/* Trending Rank Popover */}
            <Popover
                open={Boolean(trendingRankInput)}
                anchorEl={deleteAnchorEl} 
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            backgroundColor: dk() ? '#353A36' : '#FFFFFF', // Replace background logic with theme-based dynamic colors
                        },
                    },
                }}
            >
                <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        label="Set Trending Rank"
                        type="number"
                        value={trendingRankInput}
                        onChange={(e) => setTrendingRankInput(e.target.value)}
                        sx={{textFieldStyles}}
                        slotProps={{
                            input: {
                                inputProps: { min: 1 },
                            },
                        }}
                    />
                    <Button style = {{color: dk() ? '#fff' : '#000'}} onClick={() => handleTrendingRankSubmit(postId, userToken, trendingRankInput, setTrendingRankInput)}>Submit</Button>
                    <Button style = {{color: dk() ? '#fff' : '#000'}} onClick={() => setTrendingRankInput(null)}>Cancel</Button>
                </div>
            </Popover>
        </>
    );
}
