import { calculateTotalCount } from './PostHelper';
import axios from 'axios';
import config from '../config';

/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 *
**/

export const sortOptions = [
  { value: 'Most popular', label: 'Most popular' },
  { value: 'Most recent', label: 'Most recent' },
  { value: 'Most commented', label: 'Most commented' },
];

export const timeOptions = [
  { value: 'Today', label: 'Today' },
  { value: '7 days', label: 'Last 7 days' },
  { value: '1 month', label: 'Last 1 month' },
  { value: 'All time', label: 'All time' },
];

export const postTypeOptions = [
  { value: 'News', label: 'News' },
  { value: 'Research', label: 'Research' },
  { value: 'Trades', label: 'Trades' },
  { value: 'General', label: 'General' },
];

export const categoryOptions = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Crypto', label: 'Crypto' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Materials', label: 'Materials' },
  { value: 'Defence', label: 'Defence' },
  { value: 'Meme', label: 'Meme' },
  { value: 'Media', label: 'Media' },
  { value: 'NFTs', label: 'NFTs' },
  { value: 'Politics', label: 'Politics' },
  { value: 'Personal-Finance', label: 'Personal Finance' },
  { value: 'Science', label: 'Science' },
  { value: 'Tech', label: 'Tech' },
  { value: 'Real-Estate', label: 'Real Estate' },
  { value: 'United-States', label: 'United States' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Cloud-Computing', label: 'Cloud Computing' },
  { value: 'AI', label: 'AI' },
  { value: 'Economy', label: 'Economy' },
  { value: 'Dividends', label: 'Dividends' },
];

export const sentimentOptions = [
  { value: 'Positive', label: 'Positive' },
  { value: 'Neutral', label: 'Neutral' },
  { value: 'Negative', label: 'Negative' },
];

  /**
 * @param {PostObject[]} posts 
 * @param {string} selectedTime 
 * @returns {PostObject[]}
 */
export const filterPostsByTime = (posts, selectedTime) => {
  const now = new Date();
  return posts.filter((post) => {
    const postDate = new Date(post.timePosted);
    if (selectedTime === 'Today') {
      return now - postDate < 24 * 60 * 60 * 1000; // 24 hours
    } else if (selectedTime === '7 days') {
      return now - postDate < 7 * 24 * 60 * 60 * 1000; // 7 days
    } else if (selectedTime === '1 month') {
      return now - postDate < 30 * 24 * 60 * 60 * 1000; // 30 days
    } else {
      return true; // All time
    }
  });
};

/**
 * @param {PostObject[]} posts
 * @param {string} selectedSort
 * @returns {PostObject[]}
 */

export const sortPosts = (posts, selectedSort) => {
  return posts.sort((a, b) => {
    if (selectedSort === 'Most popular') {
      return (
        calculateTotalCount(b.upvotes) - calculateTotalCount(b.downvotes) -
        (calculateTotalCount(a.upvotes) - calculateTotalCount(a.downvotes))
      );
    } else if (selectedSort === 'Most recent') {
      return new Date(b.timePosted) - new Date(a.timePosted);
    } else if (selectedSort === 'Most commented') {
      return b.comments.length - a.comments.length;
    } else {
      return 0;
    }
  });
};

/** 
 * @param {PostObject[]} posts
 * @param {Object[]} selectedPostTypes
 * @param {Object[]} selectedCategories
 * @param {Object[]} selectedSentiments
 * @param {string[]} keywords
 * @returns {PostObject[]}
 * */
export const filterPostsByCriteria = (posts, selectedPostTypes, selectedCategories, selectedSentiments, keywords) => {
  return posts.filter((post) => {
    // Filter by Post Types
    const postTypeMatch = selectedPostTypes.some(
      (type) => type.value === 'All' || type.value === post.type
    );

    // Filter by Categories
    const categoryMatch = selectedCategories.some(
      (category) => category.value === 'All' || post.categories.includes(category.value)
    );

    // Filter by Sentiments
    const sentimentMatch = selectedSentiments.some(
      (sentiment) => sentiment.value === 'All' || sentiment.value === post.sentiment
    );

    const keywordMatch = keywords.length === 0 || keywords.some((keyword) => post.content.includes(keyword));

    // Return true only if the post matches all selected criteria
    return postTypeMatch && categoryMatch && sentimentMatch && keywordMatch;
  });
};

/**
 * @param {string} quickPostContent
 * @param {string} userToken
 *  
 * @returns {Promise}
 */
export const handleQuickPostSaveToDrafts = async (quickPostContent, userToken) => {
  const formData = new FormData();
  formData.append('content', quickPostContent);
  formData.append('isDraft', 'true');  
  formData.append('isQuotePost', 'false');
  formData.append('categories', '[]');


  try {
    const response = await axios.post(`${config.backendUrl}/api/posts/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${userToken}`,
      },
    });
    return response;
  } catch (error) {
    alert('Error saving post');
    console.error(error);
  }

}

/**
 * @param {string} quickPostContent
 * @param {string} userToken
 * @param {string[]} selectedCategoryQuick
 * @param {string} selectedPostTypeQuick
 * 
 *  s
 * @returns {Promise}
 */
export const handleQuickPostSubmit = async (quickPostContent, selectedCategoryQuick, selectedPostTypeQuick, userToken) => {


  const formData = new FormData();
  formData.append('content', quickPostContent);
  formData.append('isDraft', 'false');  
  formData.append('isQuotePost', 'false');
  formData.append('categories', selectedCategoryQuick ? JSON.stringify(selectedCategoryQuick) : '[]');
  formData.append('type', selectedPostTypeQuick ? selectedPostTypeQuick : '');
  


  try {
    const response = await axios.post(`${config.backendUrl}/api/posts/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${userToken}`,
      },
    });
    return response;
  } catch (error) {
    alert('Error submitting post');
    console.error(error);
  }
}


