import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import "../styles/Portfolio.css";
import { AuthContext } from '../context/AuthContext';
import {
  fetchAllTransactionsGeneral,
  getChangeColor,
  getChangePercentageColor,
  filters,
  fetchUserPortfolio,
  fetchUserCashBalance,
  getUnixTimestampRange,
  getUnixTimestamp,
  calculateReturns,
  fetchCompanyDetails,
  portfolioIntervalMap,
  isOption,
} from '../helper/MarketsHelper';
import { LoadingSpinner, formatCurrency as fc } from '../helper/StylesHelper';
import config from '../config';
import { createPortfolioChart } from '../helper/PortfolioChartHelper';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

export default function Portfolio() {
  useResponsiveWidth();
  const [transactions, setTransactions] = useState([]);
  const [cashBalance, setCashBalance] = useState(null); // Changed from 0 to null
  const [assetsValue, setAssetsValue] = useState(null); // Changed from 0 to null
  const [mainPortfolio, setMainPortfolio] = useState(null);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [filter, setFilter] = useState('1d');
  const [livePriceMap, setLivePriceMap] = useState(null);
  const [quantityMap, setQuantityMap] = useState(null);
  const [intervalPriceMap, setIntervalPriceMap] = useState(null); 
  const [portfolioChangeAmount, setPortfolioChangeAmount] = useState(null);
  const [portfolioChangePercentage, setPortfolioChangePercentage] = useState(null);
  const wsRef = useRef(null); // Reference to the WebSocket connection
  const navigate = useNavigate();

  const [referenceValue, setReferenceValue] = useState(null);

  const { userToken, isAuthenticated } = useContext(AuthContext);
  const {tc, theme} = useContext(ThemeContext);

  // New state variables for the chart
  const [portfolioData, setPortfolioData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [stats, setStats] = useState({});
  const [portfolioStats, setPortfolioStats] = useState({}); 
  const [tickerCompanyMap, setTickerCompanyMap] = useState({});
  const [balanceLoading, setBalanceLoading] = useState(true); // New state variable
  const [tableLoading, setTableLoading] = useState(true); // New state variable
  const [showAddCash, setShowAddCash] = useState(false); // New state variable
  const [cashToAdd, setCashToAdd] = useState('');
  const [stock, setStock] = useState('');


  useEffect(() => {
    if (!mainPortfolio || !mainPortfolio.assets) {
      return;
    }
  
    const tickerSet = new Set(mainPortfolio.assets.map((stock) => stock.ticker));
    const tickerArray = Array.from(tickerSet);
  
    // Function to fetch company details for all tickers
    const fetchAllCompanyDetails = async () => {
      try {
        const companyDetailsPromises = tickerArray.map((ticker) =>
          fetchCompanyDetails(ticker) // Fetch company details for each ticker
        );
  
        // Wait for all promises to resolve
        const allCompanyDetails = await Promise.all(companyDetailsPromises);
  
        // Create a new object to map ticker to company details
        const tickerCompanyDetailsMap = allCompanyDetails.reduce((acc, companyInfo, index) => {
          const ticker = tickerArray[index];
          acc[ticker] = companyInfo.name;
          return acc;
        }, {});
  
        // Update the state with the mapped data
        setTickerCompanyMap(tickerCompanyDetailsMap);
  
      } catch (error) {
        console.error("Error fetching company details for tickers:", error);
      }
    };

    const fetchIntervalPrices = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/polygon/intervalprices`, {
          params: {
            tickers: tickerArray.join(','),
          },
        });
    
        const data = response.data;
    
        // Create a map from ticker to prices
        const intervalPriceMapTemp = {};
    
        data.forEach((item) => {
          const ticker = item.ticker;
          const prices = item.prices;
          intervalPriceMapTemp[ticker] = prices;
        });
    
        setIntervalPriceMap(intervalPriceMapTemp);
      } catch (error) {
        console.error("Error fetching interval prices:", error);
      }
    };
    fetchAllCompanyDetails();
    fetchIntervalPrices();
  }, [mainPortfolio]);






  const renderTable = () => { 
    if (transactions?.length === 0) {
      return;
    }

    if (tableLoading || !intervalPriceMap || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    const keysToUse = Object.keys(portfolioStats).filter((ticker) => {
      return ticker !== 'totalInvested' && ticker !== 'marketValue'
        && ticker !== 'totalUnrealizedPercentage' && ticker !== 'totalUnrealizedDollar'
        && ticker !== 'totalRealizedDollar' && ticker !== 'totalRealizedPercentage'
        && ticker !== 'totalBuys' && ticker !== 'totalSells' && ticker !== 'totalBuyVolume' && ticker !== 'totalSellVolume';
    });
  
 
  
    const calculatePercentageChange = (currentPrice, previousPrice) => {
      if (!currentPrice || !previousPrice || previousPrice === 0) return '--';
      const change = ((currentPrice - previousPrice) / previousPrice) * 100;
      return isNaN(change) ? '--' : change;
    };
  
    return (
      <div className="port-stats-table">
        <h2 className="port-right-sidebar-header">
          Holdings
        </h2>
        <table className="stats-table">
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Name</th>
              <th>Total Investment</th>
              <th>Present Value</th>
              <th>Unrealized Return</th>
              <th>Realized Return</th>
              <th>24h %</th>
              <th>7d %</th>
              <th>1y %</th>
              <th>Buys</th>
              <th>Sells</th>
            </tr>
          </thead>
          <tbody>
            {keysToUse.map((ticker) => {
            
              const currentPrice = livePriceMap[ticker];


              let intervalPrices = { ...intervalPriceMap[ticker] } || {};
  
              if (isOption(ticker)) {
                intervalPrices['24h'] = intervalPrices['24h'] !== undefined ? intervalPrices['24h'] * 100 : undefined;
                intervalPrices['7d'] = intervalPrices['7d'] !== undefined ? intervalPrices['7d'] * 100 : undefined;
                intervalPrices['1y'] = intervalPrices['1y'] !== undefined ? intervalPrices['1y'] * 100 : undefined;
              }
            
              // Calculate percentage changes based on available interval prices
              const change24h = calculatePercentageChange(currentPrice, intervalPrices['24h']);
              const change7d = calculatePercentageChange(currentPrice, intervalPrices['7d']);
              const change1y = calculatePercentageChange(currentPrice, intervalPrices['1y']);
         
              // Calculate percentage changes based on available interval prices
           

  
              return (
                <tr 
                className='port-stats-row'
                onClick={() =>{ 
                  !ticker.startsWith('O:') ? navigate(`/stocks/${ticker}`) : navigate(`/options/${ticker}`)
                }}
                key={ticker}>
                  <td className='bold'>{ticker}</td>
                  <td className='bold'>{tickerCompanyMap[ticker] || "N/A"}</td>
                  <td className='bold'>${fc(portfolioStats[ticker]?.totalInvested) || "--"}</td>
                  <td className='bold'>${fc(portfolioStats[ticker]?.marketValue) || "--"}</td>
                  <td
                    className={
                      portfolioStats[ticker]?.unrealizedPercentage >= 0
                        ? "port-positive bold"
                        : "port-negative bold"
                    }
                  >
                    {portfolioStats[ticker]?.unrealizedDollar >= 0 ? '+' : ''}
                    {fc(portfolioStats[ticker]?.unrealizedDollar)} ({fc(portfolioStats[ticker]?.unrealizedPercentage, 2, true)}%)
                  </td>
                  <td
                    className={
                      portfolioStats[ticker]?.realizedPercentage >= 0
                        ? "port-positive bold"
                        : "port-negative bold"
                    }
                  >
                  {portfolioStats[ticker]?.realizedDollar >= 0 ? '+' : ''}
                    {fc(portfolioStats[ticker]?.realizedDollar)} ({fc(portfolioStats[ticker]?.realizedPercentage, 2, true)}%)
                  </td>
                  <td className="bold" style = {{color: getChangePercentageColor(change24h, tc())}}>{change24h >= 0 && '+'}{fc(change24h, 2)}%</td>
                  <td className="bold" style = {{color: getChangePercentageColor(change7d, tc())}}>{change7d >= 0 && '+'}{fc(change7d, 2)}%</td>
                  <td className="bold" style = {{color: getChangePercentageColor(change1y, tc())}}>{change1y >= 0 && '+'}{fc(change1y, 2)}%</td>
                  <td className='bold'>{portfolioStats[ticker]?.buyCount !== undefined ? fc(portfolioStats[ticker].buyCount, 0) : "--"}</td>
                  <td className='bold'>{portfolioStats[ticker]?.sellCount !== undefined ? fc(portfolioStats[ticker].sellCount, 0) : "--"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(()=> {
    if (!mainPortfolio || !mainPortfolio.assets || !livePriceMap || !tickerCompanyMap || !transactions) {
      return;

    }

    const buyMap = {};  
    const sellMap = {};
    const buyVolumeMap = {};  
    const sellVolumeMap = {};

    transactions.forEach((transaction) => {
      const { ticker, type, assetType } = transaction;
      if (type === 'buy') {
        if (buyMap[ticker]) {
          buyMap[ticker] ++;
          buyVolumeMap[ticker] += transaction.quantity * transaction.price * (assetType === 'option' ? 100 : 1);
        } else {
          buyMap[ticker] = 1;
          buyVolumeMap[ticker] = transaction.quantity * transaction.price * (assetType === 'option' ? 100 : 1);
        }
       
      }
      if (type === 'sell') {
        if (sellMap[ticker]) {
          sellMap[ticker] ++;
          sellVolumeMap[ticker] += transaction.quantity * transaction.price * (assetType === 'option' ? 100 : 1);
        } else {
          sellMap[ticker] =1;
          sellVolumeMap[ticker] = transaction.quantity * transaction.price * (assetType === 'option' ? 100 : 1);
        }
      }
    });

    mainPortfolio.assets.forEach((stock) => {
      const ticker = stock.ticker.toUpperCase();
      
      // Get the live price for this asset
      let livePrice = livePriceMap[ticker];

      let tickerTransactions = transactions.filter((transaction) => transaction.ticker === ticker); 

      // Calculate returns using the function
      const { unrealizedDollar, unrealizedPercentage, realizedDollar, realizedPercentage } = calculateReturns(stock, livePrice, tickerTransactions);
    
      // Calculate other stats (buyCount, sellCount, etc.)
      const stats = {
        totalInvested: stock.quantity * stock.avgBuyPrice,
        marketValue: livePrice * stock.quantity,
        unrealizedDollar,
        unrealizedPercentage,
        realizedDollar,
        realizedPercentage,
        buyCount: buyMap[ticker] || 0,
        sellCount: sellMap[ticker] || 0,
        buyVolumeMap: buyVolumeMap[ticker] || 0,
        sellVolumeMap: sellVolumeMap[ticker] || 0,
      };
    
    
      setStats((prevStats) => ({
        ...prevStats,
        [ticker]: stats,
      }));
    });

  }, [mainPortfolio, livePriceMap, transactions, tickerCompanyMap]);

  useEffect(() => {
    let totalInvested = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].totalInvested;
    }, 0);
  
    let marketValue = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].marketValue;
    }, 0);
  
    let totalUnrealizedDollar = marketValue - totalInvested;
    let totalUnrealizedPercentage = totalInvested > 0 ? (totalUnrealizedDollar / totalInvested) * 100 : 0;
  
    // Calculate total realized dollar and percentage
    let totalRealizedDollar = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].realizedDollar;
    }, 0);
  
    // Updated calculation for totalRealizedPercentage
    let totalRealizedPercentage = totalInvested > 0
      ? (totalRealizedDollar / totalInvested) * 100
      : 0;
  
    let totalBuys = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].buyCount;
    }, 0);
  
    let totalSells = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].sellCount;
    }, 0);

    let totalBuyVolume = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].buyVolumeMap;
    }
    , 0);

    let totalSellVolume = Object.keys(stats).reduce((total, ticker) => {
      return total + stats[ticker].sellVolumeMap;
    }
    , 0);
  
    setPortfolioStats({
      totalInvested,
      marketValue,
      totalUnrealizedPercentage,
      totalUnrealizedDollar,
      totalRealizedDollar,
      totalRealizedPercentage,
      totalBuys,
      totalSells,
      totalBuyVolume,
      totalSellVolume,
      ...stats,
    });
  }, [stats]);

  useEffect(() => {
    if (portfolioStats && tickerCompanyMap ) {
      setTableLoading(false);
    }
  }, [portfolioStats, tickerCompanyMap]);



  const handleConfirmAddCash = async () => {
    try {
      const amount = parseFloat(cashToAdd);
      if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount greater than 0.');
        return;
      }
  
      const response = await axios.post(
        `${config.backendUrl}/api/stockActions/addCash`,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      // Update the cash balance and reset states
      setCashBalance(response.data.cash);
      setShowAddCash(false);
      setCashToAdd('');

      alert(response.data.message);
      
      // Optionally, you may want to refresh other data like transactions
      fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions);
      fetchUserPortfolio(isAuthenticated, userToken, setMainPortfolio);
    } catch (error) {
      console.error('Error adding cash:', error);
      alert(error.response?.data?.message || 'An error occurred while adding cash.');
    }
  };
  
  const handleCancelAddCash = () => {
    setShowAddCash(false);
    setCashToAdd('');
  };

 

  // Fetch portfolio data points for the chart
  const fetchPortfolioDataPoints = async () => {
    setDataFetched(false);
    try {
        let fromDateUnixMs;
        let multiplier, timespan;
        let toDateUnixMs = getUnixTimestamp();

        if (filter === 'all time') {
            fromDateUnixMs = new Date('2024-08-01').getTime();
        } else {
            fromDateUnixMs = getUnixTimestampRange(filter);
        }

        const intervalArray = portfolioIntervalMap[filter];
        multiplier = intervalArray[0];
        timespan = intervalArray[1];
       

      const response = await axios.get(`${config.backendUrl}/api/polygon/portfolio/datapoints`, {
        params: {
          multiplier,
          timespan,
          fromDateUnixMs,
          toDateUnixMs,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response.data);
      setReferenceValue(response.data[0]?.portfolioValue);
      setPortfolioData(response.data);
     
    } catch (error) {
      setPortfolioData([]);
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
      console.error('Error fetching portfolio data points:', error);
    }
    setDataFetched(true);
  };

  // Fetch portfolio data points whenever the filter changes
  useEffect(() => {
    if (userToken) {
      fetchPortfolioDataPoints();
    }
    // eslint-disable-next-line
  }, [userToken, filter, cashBalance]);



  // Create the portfolio chart
  useEffect(() => {
    if (!chartRef.current || !dataFetched || !portfolioData.length) return;

    const referenceValueForChart = portfolioData[0]?.portfolioValue;
    setReferenceValue(referenceValueForChart);

    const cleanup = createPortfolioChart({
      chartRef,
      chartInstanceRef: chartInstance,
      portfolioData,
      referenceValueForChart,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });

    return cleanup;
  }, [portfolioData, dataFetched, filter, theme, cashBalance]);

  // Update live prices via WebSocket
  useEffect(() => {
    if (!mainPortfolio || !mainPortfolio.assets) return;

    const ws = new WebSocket(config.socketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      mainPortfolio.assets.forEach((stock) => {
        const ticker = stock.ticker.toUpperCase();
        ws.send(JSON.stringify({ action: 'subscribe', ticker }));
      });
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'instantPrice') {
        message.data.forEach((data) => {
          if (data.ev === 'T') {
            const ticker = data.sym;
            const livePrice = parseFloat(data.p).toFixed(2);
            updateLivePrice(ticker, livePrice);
          }
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = ws;

    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        mainPortfolio.assets.forEach((stock) => {
          const ticker = stock.ticker.toUpperCase();
          wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        });
        wsRef.current.close();
      }
    };
  }, [mainPortfolio]);

  // Update live prices every second
  useEffect(() => {
    if (!mainPortfolio || !mainPortfolio.assets) return;

    const tickers = mainPortfolio.assets.map((stock) => stock.ticker.toUpperCase());

    const fetchPrice = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        tickers.forEach((ticker) => {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        });
      }
    };

    const intervalId = setInterval(fetchPrice, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [mainPortfolio]);

  // Fetch current prices as a fallback
  useEffect(() => {
    if (!mainPortfolio) return;

    const fetchCurrentPrices = async () => {
      try {
        const pricePromises = mainPortfolio.assets.map((stock) => {
          const ticker = stock.ticker.toUpperCase();
          return axios
            .get(`${config.backendUrl}/api/polygon/dailychange?ticker=${ticker}`)
            .then((response) => ({ ticker, price: response.data.currentPrice.toFixed(2) }))
            .catch((error) => {
              console.error(`Error fetching price for ${ticker}:`, error);
              return { ticker, price: '--' };
            });
        });

        const prices = await Promise.all(pricePromises);
        const updatedPriceMap = prices.reduce((map, { ticker, price }) => {
          if (isOption(ticker)) {
            price = price * 100;
          }
          map[ticker] = price;
          return map;
        }, {});

        setLivePriceMap((prevMap) => ({ ...prevMap, ...updatedPriceMap }));
      } catch (error) {
        console.error('Error fetching current prices:', error);
      }
    };

    fetchCurrentPrices();
  }, [mainPortfolio]);


  // Fetch user data on mount
  useEffect(() => {
    fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions).then(() =>
      setTransactionsLoading(false)
    );
    fetchUserPortfolio(isAuthenticated, userToken, setMainPortfolio);
    fetchUserCashBalance(isAuthenticated, userToken, setCashBalance);
  }, [userToken, isAuthenticated]);

  // Update quantity map when main portfolio changes
  useEffect(() => {
    if (!mainPortfolio || !mainPortfolio.assets) return;

    const quantityMapTemp = mainPortfolio.assets.reduce((map, stock) => {
      map[stock.ticker.toUpperCase()] = stock.quantity;
      return map;
    }, {});

    setQuantityMap(quantityMapTemp);


  }, [mainPortfolio]);

 


  useEffect(() => {
    // Ensure that both livePriceMap and quantityMap have been populated before calculating assetsValue
    if (!livePriceMap  || !quantityMap ) {
      return;
    }
  
    const liveValueMapTemp = Object.keys(livePriceMap).reduce((map, ticker) => {
      const price = parseFloat(livePriceMap[ticker]);
      const quantity = quantityMap[ticker];
      if (price && quantity) {
        map[ticker] = price * quantity;
      }
      return map;
    }, {});
  
    const assetsValueTemp = Object.values(liveValueMapTemp).reduce((total, value) => total + value, 0);
  
    setAssetsValue(assetsValueTemp);
  }, [livePriceMap, quantityMap]);

  useEffect(() => {
    if (cashBalance !== null && assetsValue !== null) {
      setBalanceLoading(false);
    }
  }, [cashBalance, assetsValue]);

 

  useEffect(()=> {
    if (!dataFetched) {
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
    } else
    if (typeof cashBalance === 'number' && typeof assetsValue === 'number' && typeof referenceValue === 'number' && portfolioData.length > 0) {

      const changeAmount = (cashBalance + assetsValue) - referenceValue;
      const changePercentage = (changeAmount / referenceValue) * 100;
      setPortfolioChangeAmount(changeAmount);
      setPortfolioChangePercentage(changePercentage);
    } 
  }, [cashBalance, assetsValue, referenceValue, portfolioData, dataFetched]);

  // Update live prices in the map
  const updateLivePrice = (ticker, price) => {
    if (isOption(ticker)) {
      price = price * 100;
    }
    setLivePriceMap((prevMap) => ({
      ...prevMap,
      [ticker]: price,
    }));
  };

  const renderTradeCard = (transaction) => {  
    if (transactionsLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    let { ticker, type, price, date, quantity} = transaction;

  
    if (isOption(ticker)) {
      price = price * 100;
    }
    const formattedDate = new Date(date).toLocaleString();

    const livePrice = parseFloat(livePriceMap[ticker]);
    const liveChangeAmount = livePrice && price ? livePrice - price : null;
    const liveChangePercentage =
      livePrice && price ? ((livePrice - price) / price) * 100 : null;
    const isBuy = type === 'buy';
    let changePercentageToUse = liveChangePercentage || 0;

    return <div className='PortTrade' 
    style={{
      backgroundColor: isBuy ? getChangeColor(changePercentageToUse, tc()) : 'transparent',
      border:
      // Use negative change percentage for sells
        !isBuy && `0.5px solid ${getChangePercentageColor(-changePercentageToUse, tc())}`,
    }}
    >
      <div className='PortTradeHeader'>
        
      <span className='PortTradeLabel'>
      {`${type === 'buy' ? 'BUY' : 'SELL'} - ${formattedDate}`}
        </span>
        <div className='PortTradeCompanyHeader'>
          <span className='PortTradeLabel'>
          {ticker}
          </span>
         <span className="PortTradeValue bold">
          {tickerCompanyMap[ticker]}
          </span>
        </div>
        <div className="PortTradeTable">
        <div className="PortTradeTableRow">
          <span className='PortTradeLabel'>Price:</span>
          <span className="PortTradeValue bold">${fc(price)}</span>
          <span></span>
          <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}: </span>
          <span style = {{color:getChangePercentageColor(liveChangeAmount, tc())}}
          className="PortTradeValue bold">
            {liveChangeAmount >=0 && '+'}{fc(liveChangeAmount * quantity)}</span>
        </div>
        <div className="PortTradeTableRow">
          <span className='PortTradeLabel'>{isOption(ticker) ? 'Contracts:' : 'Shares:'} </span>
          <span className="PortTradeValue bold">{fc(quantity)}</span>
          <span></span>
          <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}:</span>
          <span style = {{color:getChangePercentageColor(liveChangeAmount, tc())}}
          className="PortTradeValue bold">
            {liveChangePercentage >= 0 && '+'}{fc(liveChangePercentage)}%</span>
        </div>
        <div className="PortTradeTableRow">
          <span className='PortTradeLabel'>Total:</span>
          <span className="PortTradeValue bold">${fc(price * quantity)}</span>
          <span></span>
          <span className="PortTradeLabel">Present Value:</span>
          <span className="PortTradeValue bold">${fc(livePrice * quantity)}</span>
        </div>
      </div>
        </div>

    </div>
  

    
  };

  // Render the trade history
  const renderTradeHistory = (transaction) => {
    if (transactionsLoading || !livePriceMap) {
      return <LoadingSpinner />;
    }


    return (
      renderTradeCard(transaction)
    );
  };

  // Render the portfolio header
  const renderPortfolio = () => {
    if (!mainPortfolio || balanceLoading || portfolioChangeAmount === null || portfolioChangePercentage === null) {
      return <LoadingSpinner />;
    }

    // Determine the change amount and percentage to display
    const displayChangeAmount = isHovering
      ? parseFloat(hoveredChangeAmount)
      : portfolioChangeAmount;
    const displayChangePercentage = isHovering
      ? parseFloat(hoveredChangePercent)
      : portfolioChangePercentage;

    return (
      <div className="port-header-wrapper">
        <span className="port-value bold">
          ${isHovering ? hoveredPrice : fc(cashBalance + assetsValue)}
        </span>
        <span
          className={`StockChange bold ${
            !isNaN(displayChangeAmount) && displayChangeAmount >= 0
              ? 'port-positive'
              : 'port-negative'
          }`}
          style={{ textAlign: 'left' }}
        >
          {!isNaN(displayChangeAmount) ? (
            <>
              {displayChangeAmount >= 0 ? '+' : ''}
              {fc(displayChangeAmount)} (
              {fc(displayChangePercentage, 2, true)}%)
            </>
          ) : (
            <span style={{ color: 'var(--action-grey)' }}>--</span>
          )}

          <span className="StockDuration"> {isHovering ? hoveredDateTime : filters[filter] === 'live'
              ? ' live'
              : filters[filter] === 'all time'
              ? ' all time'
              : ` past ${filters[filter]}`}
          </span>
        </span>
        {showAddCash ? (
        <div className="AddCashContainer">
          <input
            type="number"
            value={cashToAdd}
            onChange={(e) => setCashToAdd(e.target.value)}
            placeholder="Enter amount"
            className="AddCashInput"
          />
          <button onClick={handleConfirmAddCash} className="CreatePostFeedButton greenButton">Confirm</button>
          <button onClick={handleCancelAddCash} className="CreatePostFeedButton">Cancel</button>
        </div>
      ) : (
        <button
          onClick={() => setShowAddCash(true)}
          className='CreatePostFeedButton greenButton'
          style={{ width: 'fit-content', marginTop: '0.5rem', marginBottom: '2rem' }}
        >
          Add Cash
        </button>
      )}
  
      </div>
    );
  };

  return (
    <div className="port-container">
  
  
      <div className="port-main-content">
        {renderPortfolio()}
        {!dataFetched ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
             height: 'var(--stock-chart-height)'
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ height: 'var(--stock-chart-height)'}}>
            <canvas  ref={chartRef}></canvas>
          </div>
        )}
        <div className="StockFilters">
          {Object.keys(filters).map((key) => (
            <button
              key={key}
              className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
              onClick={() => setFilter(key)}
            >
              {key}
            </button>
          ))}
        </div>
        <div className="port-stats-filter-section">
          {balanceLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="port-stats-section">
                <div className="port-invested-section">
                  <p>
                    <span>Invested:</span> <strong className="port-value-stat">${fc(portfolioStats?.totalInvested)}</strong>
                  </p>
                  <p>
                    <span>Market value:</span> <strong className="port-value-stat">${fc(portfolioStats?.marketValue)}</strong>
                  </p>
                  <p>
                    <span>Cash: </span> <strong className="port-value-stat">${fc(cashBalance)}</strong>
                  </p>
                </div>

                <div className="port-unrealized-section">
                  <p>
                    <span>Unrealized Return: </span>
                    <strong
                      className={
                        portfolioStats?.totalUnrealizedDollar >= 0 ? 'port-positive bold' : 'port-negative bold'
                      }
                    >
                      { portfolioStats?.totalUnrealizedDollar >= 0 ? '+' : ''}
                      {fc(portfolioStats?.totalUnrealizedDollar)}&nbsp;(
                      <span
                        className={
                          portfolioStats?.totalUnrealizedPercentage >= 0
                            ? 'port-positive bold'
                            : 'port-negative bold'
                        }
                      >
                        {fc(portfolioStats?.totalUnrealizedPercentage, 2, true)}%
                      </span>
                      )
                    </strong>
                  </p>
              
                  <p>
                    <span>Buys: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalBuys !== undefined ? fc(portfolioStats.totalBuys, 0) : "--"}
                    </strong>
                  </p>
                  <p>
                    <span>Buy Volume: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalBuyVolume !== undefined ? `$${fc(portfolioStats?.totalBuyVolume)}` : "--"}
                    </strong>
                  </p>
                </div>

                <div className="port-buys-sells-section">
                <p>
                    <span>Realized Return: </span>
                    <strong
                      className={
                        portfolioStats?.totalRealizedDollar >= 0 ? 'port-positive bold' : 'port-negative bold'
                      }
                    >
                      {portfolioStats?.totalRealizedDollar >= 0 ? '+' : ''}
                      {fc(portfolioStats?.totalRealizedDollar)}&nbsp;(
                      <span
                        className={
                          portfolioStats?.totalRealizedPercentage >= 0
                            ? 'port-positive bold'
                            : 'port-negative bold'
                        }
                      >
                        {fc(portfolioStats?.totalRealizedPercentage, 2, true)}%
                      </span>
                      )
                    </strong>
                  </p>
                  
                  <p>
                    <span>Sells: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalSells !== undefined ? fc(portfolioStats.totalSells,0) : "--"}
                    </strong>
                  </p>
                  <p>
                    <span>Sell Volume: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalSellVolume !== undefined ? `$${fc(portfolioStats?.totalSellVolume)}`: "--"}
                    </strong>
                  </p>
                </div>
              </div>
              {renderTable()}
            </>
          )}
        </div>
      </div>
  
      <div className="port-right-sidebar">
        {/*h2 matches margin for Holdings h2 */}
        <h2 style = {{marginBottom: 'calc(0.83rem + 1rem)'}}className="port-right-sidebar-header">Transaction history</h2>
        <div className="port-trade-history">
          {transactionsLoading ?  <LoadingSpinner /> :
          transactions.length === 0 ? (
              <span style = {{textAlign: 'left'}}className="ov-trade-type bold ov-15">No trades</span>
          ) :
          transactions.map((transaction) => renderTradeHistory(transaction))
          
          }
        </div>
      </div>
    </div>
  );
}