import React, { useState, useContext, useEffect } from 'react';
import '../styles/NewsV2.css';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import axios from 'axios';
import { LoadingSpinner } from '../helper/StylesHelper';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
export default function NewsV2() {
  useResponsiveWidth(); 
  const [newSearchQuery, setNewSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Changed from arrays to strings to allow only one selection per category
  const [selectedStyle, setSelectedStyle] = useState('');
  const [selectedLength, setSelectedLength] = useState('');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('');
  const [selectedSource, setSelectedSource] = useState('');

  const { userToken, name, isAuthenticated } = useContext(AuthContext);
  const [userNewsStories, setUserNewsStories] = useState([]);
  const [savedNewsIds, setSavedNewsIds] = useState([]);
  const [searchingNews, setSearchingNews] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [quoteOfTheDay, setQuoteOfTheDay] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);


const [updateMode, setUpdateMode] = useState(false);
const [selectedForUpdate, setSelectedForUpdate] = useState([]);
const [updatingNewsIds, setUpdatingNewsIds] = useState([]);

  // Explore data and loading states
  const [exploreData, setExploreData] = useState({});
  const [exploreLoadingStates, setExploreLoadingStates] = useState({});

  // Define the explore sections
  const exploreSections = {
    'Headlines today': 'General',
    'Stock market': 'Finance',
    'World events': 'World',
    'Politics': 'Politics',
    'Tech': 'Tech',
  };

useEffect(() => {
  fetchExploreSections();
}, []);

const fetchExploreSections = () => {
  const delay = 1000; // Delay in milliseconds between each request (adjust as needed)

  Object.keys(exploreSections).forEach((section, index) => {
    setTimeout(() => {
      fetchExploreSection(section);
    }, index * delay); // Each subsequent section is delayed by `index * delay` ms
  });
};


const fetchExploreSection = async (section) => {
  // Set loading state for this section to true
  setExploreLoadingStates((prevState) => ({
    ...prevState,
    [section]: true,
  }));

  try {
    const response = await axios.get(`${config.backendUrl}/api/news/get-explore-section`, {
      params: {
        section,
      },
    });


    setExploreData((prevData) => ({
      ...prevData,
      [section]: response.data.articles,
    }));
  } catch (error) {
    console.error(`Error fetching explore data for ${section}:`, error);
  }

  // Set loading state for this section to false
  setExploreLoadingStates((prevState) => ({
    ...prevState,
    [section]: false,
  }));
};



  useEffect(() => {
    if (userToken) {
      setCurrentPage(1);
      getUserNewsStories(1); // Fetch user's saved news stories on component mount
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken) {
      getUserNewsStories(currentPage);
    }
  }, [currentPage]);



  useEffect(() => {
    fetchQuoteOfTheDay(); 
  }, []);

  const searchNews = async (keyword, topics = 'detailed', duration = '') => {
    // Set currentNews to null to show 'Loading...' placeholders
    setCurrentNews(null);
    setShowFilters(false);
    setSearchingNews(true);
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/app/news/search`,
        {
          keyword,
          topics,
          duration,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log('Search successful:', response.data.data);
        setCurrentNews(response.data.data);
      } else {
        console.error('Search failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error searching news:', error);
    }
    setSearchingNews(false);
  };

  const getUserNewsStories = async (page = 1, size = 10) => {
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/app/news/list`,
        { page, size },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log('User news stories fetched:', response.data.data);
        // Map stories to include news_id and update states
        const stories = response.data.data.map((story) => ({
          ...story,
          news_id: story._id,
        }));
        setUserNewsStories(stories);
        setSavedNewsIds(stories.map((story) => story.news_id));
        setTotalPages(response.data.totalPages);
      } else {
        console.error('Failed to fetch user news stories:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user news stories:', error);
    }
  };


  const toggleEditMode = () => {
    setUpdateMode(false);
    setEditMode((prev) => !prev);
    if (editMode) {
      setSelectedForDeletion([]);
    }
  };

  // Handle selection of news for deletion
  const handleSelectForDeletion = (news_id) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(news_id)
        ? prevSelected.filter((id) => id !== news_id)
        : [...prevSelected, news_id]
    );
  };

  // Confirm unsaving the selected news
  const confirmDeletion = async () => {
    if (!window.confirm('Are you sure you want to unsave the selected news?')) {
      return;
    }
    try {
      await Promise.all(
        selectedForDeletion.map((news_id) =>
          axios.post(
            `${config.backendUrl}/api/app/news/save`,
            { news_id, action: 'unsave' }, // Sending unsave action
            { headers: { Authorization: `Bearer ${userToken}` } }
          )
        )
      );

      getUserNewsStories(currentPage);
      setSelectedForDeletion([]);
      setEditMode(false);
    } catch (error) {
      console.error('Error unsaving selected news:', error);
    }
  };

  const handleSelectForUpdate = (news_id) => {
    setSelectedForUpdate((prevSelected) =>
      prevSelected.includes(news_id)
        ? prevSelected.filter((id) => id !== news_id)
        : [...prevSelected, news_id]
    );
  };

  const confirmUpdate = async () => {
    if (!window.confirm('Are you sure you want to update the selected news?')) {
      return;
    }
    try {
      setUpdatingNewsIds(selectedForUpdate); // Mark all selected news as updating
  
      const updatePromises = selectedForUpdate.map(async (news_id) => {
        try {
          await axios.post(
            `${config.backendUrl}/api/app/news/update`,
            { news_id },
            { headers: { Authorization: `Bearer ${userToken}` } }
          );
        } catch (error) {
          console.error(`Error updating news ${news_id}:`, error);
        } finally {
          // Remove news_id from updatingNewsIds after attempt
          setUpdatingNewsIds((prevIds) => prevIds.filter((id) => id !== news_id));
        }
      });
  
      await Promise.all(updatePromises);
  
      // Refresh the news stories to reflect updates
      await getUserNewsStories(currentPage);
      setSelectedForUpdate([]);
      setUpdateMode(false);
    } catch (error) {
      console.error('Error updating selected news:', error);
      // Clear the updatingNewsIds in case of error
      setUpdatingNewsIds([]);
    }
  };

  const fetchQuoteOfTheDay = async () => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/app/market/get-daily-quote`);
      if (response.data.status === 1) {
        console.log('Quote of the day fetched:', response.data.data.quote);
        setQuoteOfTheDay(response.data.data.quote);
      } else {
        console.error('Failed to fetch the quote of the day');
      }
    } catch (error) {
      console.error('Error fetching the quote of the day:', error);
    }
  };

  // Save or unsave news
const handleSaveOrUnsave = async (news_id, action = 'save') => {
  if (!isAuthenticated) {
    alert('Please log in to save news');
    return;
  }
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/app/news/save`,
      {
        news_id,
        action,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response.data.status === 1) {
      console.log(`${action === 'save' ? 'Saved' : 'Removed'} news successfully`);

      if (action === 'save') {
        const newNewsId = response.data.data.news_id;
        const savedNews = {
          ...currentNews,
          news_id: newNewsId,
        };

        // Update currentNews
        setCurrentNews(savedNews);

        // Add to userNewsStories
        setUserNewsStories((prevStories) => [savedNews, ...prevStories]);

        // Update savedNewsIds
        setSavedNewsIds((prevIds) => [newNewsId, ...prevIds]);
      } else {
        // Remove from userNewsStories
        setUserNewsStories((prevStories) =>
          prevStories.filter((story) => story.news_id !== news_id)
        );

        // Update savedNewsIds
        setSavedNewsIds((prevIds) => prevIds.filter((id) => id !== news_id));

      }
    } else {
      console.error(`Failed to ${action} news:`, response.data.message);
    }
  } catch (error) {
    console.error(`Error trying to ${action} news:`, error);
  }
};

const renderUserNewsStories = () => {
  return userNewsStories.map((story) => {
    const isUpdating = updatingNewsIds.includes(story.news_id);

    if (isUpdating) {
      return (
        <div className="ne-news-story" key={story.news_id}>
          <LoadingSpinner />
        </div>
      );
    }

    // Extract the content of the first paragraph tag from the story's description
    const parser = new DOMParser();
    const doc = parser.parseFromString(story.description, "text/html");
    const firstParagraph = doc.querySelector("p")?.textContent || "";

    // Extract the first three sentences from the paragraph
    const sentences = firstParagraph.split(". ").slice(0, 3).join(". ") + (firstParagraph.split(". ").length > 3 ? "..." : "");

    return (
      <div
        className="ne-news-story"
        key={story.news_id}
        onClick={() => !editMode && !updateMode && setCurrentNews(story)}
        style={{
          cursor: "pointer",
        }}
      >
        <div className="ne-title-wrapper">
          <h2>{story.title}</h2>
          {(editMode || updateMode) && (
            <input
              className="checkbox-input"
              type="checkbox"
              checked={
                editMode
                  ? selectedForDeletion.includes(story.news_id)
                  : selectedForUpdate.includes(story.news_id)
              }
              onChange={() => {
                if (editMode) {
                  handleSelectForDeletion(story.news_id);
                } else if (updateMode) {
                  handleSelectForUpdate(story.news_id);
                }
              }}
            />
          )}
        </div>
        <p>{sentences}</p>
      </div>
    );
  });
};

const handleNewsLinkClick = (article) => {  
  if (article.url) {
    window.open(article.url, '_blank');
  }

}
const renderExploreSection = (section) => {
  const articles = exploreData[section];
  const isLoading = exploreLoadingStates[section];

  return (
    <div className="ne-explore-cards">
      {isLoading ? (
        <LoadingSpinner />
      ) : articles && articles.length > 0 ? (
        articles.map((article, index) => (
          <div className="ne-explore-card" key={index} onClick={() => handleNewsLinkClick(article)}>
            <h3>{article.title}</h3>
          </div>
        ))
      ) : (
        <p>No articles available</p>
      )}
    </div>
  );
};

  // Toggle selection for each filter category
  const toggleSelection = (filter, setSelected, selected) => {
    if (!searchingNews) {
      setSelected((prevSelected) => (prevSelected === filter ? '' : filter));
    }
  };

  const renderCurrentNews = () => {
    const isSaved = savedNewsIds.includes(currentNews?.news_id);
    return (
      <div className="ne-main-news-container">
        <div className="ne-main-news-top-row">
          <h2 className="ne-date">{currentNews?.title || newSearchQuery}</h2>
          <div className='ne-current-news-btns'>
          <button
            onClick={() =>
              handleSaveOrUnsave(
                currentNews?.news_id || currentNews?.tempNewsId,
                isSaved ? 'unsave' : 'save'
              )
            }
            className="CreatePostFeedButton transBorder"
            style = {{
              color: isSaved ? 'var(--stock-change-neg)' : 'var(--stock-change-pos)',
          borderColor: isSaved ? 'var(--stock-change-neg) !important' : 'var(--stock-change-pos) !important',
            }}
          >
            {isSaved ? 'Unsave' : 'Save'}
          </button>
          <button onClick ={()=> {setCurrentNews(null)}}className='CreatePostFeedButton transBorder'>
            Cancel
          </button>
          </div>
        </div>
        <div className="ne-main-news-description">
          {currentNews?.description ? (
            <div dangerouslySetInnerHTML={{ __html: currentNews.description }} />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    );
  };

  const getUsersDateWithGreeting = () => {
    const now = new Date();
    // Format the date as "Day, Month Day, Year"
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = now.toLocaleDateString('en-US', options);
    // Get the current hour to determine the greeting
    const hour = now.getHours();
    let greeting;
    if (hour < 12) {
      greeting = 'Good morning';
    } else if (hour < 18) {
      greeting = 'Good afternoon';
    } else {
      greeting = 'Good evening';
    }
    return { formattedDate, greeting };
  };

  const handleInputChange = (e) => {
    setNewSearchQuery(e.target.value);
  };

  const handleAddSearchQuery = (e) => {
    if (e.key === 'Enter' && !searchingNews) {
      searchNews(newSearchQuery, selectedStyle, selectedLength);
    }
  };

  const toggleUpdateMode = () => {
    setEditMode(false);
    setUpdateMode((prev) => !prev);
    if (updateMode) {
      setSelectedForUpdate([]);
    }
  };

  // Pagination handlers
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="NewsContainerV2">
      <div
        className="FeedSearchWrapper"
        style={{
          margin: 0,
          borderBottomLeftRadius: showFilters && 0,
          borderBottomRightRadius: showFilters && 0,
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <input
          className="FeedSearchBar bold"
          type="text"
          placeholder="Search news topics"
          value={newSearchQuery}
          onChange={handleInputChange}
          onKeyDown={handleAddSearchQuery}
          disabled={searchingNews}
        />
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <button
            onClick={() => searchNews(newSearchQuery, selectedStyle, selectedLength)}
            className="CreatePostFeedButton ne-search-button"
            disabled={searchingNews}
          >
            Search
          </button>
          <button
            className="ne-filter-button"
            onClick={() => setShowFilters((prev) => !prev)}
            disabled={searchingNews}
          >
            {`Filters ${showFilters ? '-' : '+'}`}
          </button>
        </div>

        {showFilters && (
          <div className="ne-FilterDropdown">
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Style</span>
              <div className="ne-FilterOptions ne-style">
                {['Creative', 'Funny', 'Blunt', 'Simple'].map((style) => (
                  <button
                    key={style}
                    className={`ne-FilterOption ${selectedStyle === style ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(style, setSelectedStyle, selectedStyle)}
                    disabled={searchingNews}
                  >
                    {style}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Length</span>
              <div className="ne-FilterOptions ne-length">
                {['Detailed', 'Quick'].map((length) => (
                  <button
                    key={length}
                    className={`ne-FilterOption ${selectedLength === length ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(length, setSelectedLength, selectedLength)}
                    disabled={searchingNews}
                  >
                    {length}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Timeframe</span>
              <div className="ne-FilterOptions ne-timeframe">
                {['Today', 'Past week', 'Past month', 'Past year'].map((timeFrame) => (
                  <button
                    key={timeFrame}
                    className={`ne-FilterOption ${selectedTimeFrame === timeFrame ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(timeFrame, setSelectedTimeFrame, selectedTimeFrame)}
                    disabled={searchingNews}
                  >
                    {timeFrame}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Sources</span>
              <div className="ne-FilterOptions ne-sources">
                {['Top financial sources', 'Major publications'].map((source) => (
                  <button
                    key={source}
                    className={`ne-FilterOption ${selectedSource === source ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(source, setSelectedSource, selectedSource)}
                    disabled={searchingNews}
                  >
                    {source}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="ne-main-content">
        <div className="ne-left-sidebar">
          <div className="ne-greeting-section">
            <h2 className="ne-date">{getUsersDateWithGreeting().formattedDate}</h2>
            <h2 className="ne-greeting">
              {getUsersDateWithGreeting().greeting}, {name || 'user'}!
            </h2>

          {quoteOfTheDay &&  <p className="ne-daily-quote">
           {quoteOfTheDay}
            </p>}

            
            <div className="ne-buttons-row">
            {userNewsStories?.length > 0 && (
              <>
              {!updateMode &&  <button className="CreatePostFeedButton transBorder" onClick={toggleEditMode}>
                  {editMode ? 'Cancel' : 'Edit'}
                </button>}
               {!editMode && <button className="CreatePostFeedButton transBorder" onClick={toggleUpdateMode}>
                  {updateMode ? 'Cancel' : 'Update'}
                </button>}
              </>
            )}
            {editMode && (
              <button className="CreatePostFeedButton transBorder" onClick={confirmDeletion}>
                Unsave selected
              </button>
            )}
           {updateMode && (
            <button
              className="CreatePostFeedButton transBorder"
              onClick={confirmUpdate}
              disabled={updatingNewsIds.length > 0} // Disable when updates are in progress
            >
              Update Selected
            </button>
          )}
          </div>
            <div className="ne-news-story-wrapper">
              {renderUserNewsStories()}
              {/* Pagination Controls */}
            {userNewsStories?.length >0 &&  <div className="ne-pagination">
                <button 
                className="CreatePostFeedButton transBorder"
                onClick={handlePrevPage} disabled={currentPage === 1}>
                  Prev
                </button>
                <span className='ne-page-span'>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                 className="CreatePostFeedButton transBorder"
                 onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            }
            </div>
          </div>
        </div>

        <div className="ne-right-sidebar">
          <div className="ne-greeting-section">
            {searchingNews || currentNews ? (
              renderCurrentNews()
            ) : (
              <>
                <h2 className="ne-date" style={{ color: 'transparent' }}>
                  ㅤ
                </h2>
                <h2 className="ne-greeting">Explore</h2>
                <div className="ne-explore-section-container">
                  {Object.keys(exploreSections).map((section) => (
                    <div className="ne-explore-section" key={section}>
                      <h2>{section}</h2>
                      {renderExploreSection(section)}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}