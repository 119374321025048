import React, { useContext, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCheckCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../styles/CreatePost.css';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import config from '../config';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatTimeSince, timeSince } from '../helper/PostHelper';
import { postTypeOptions, categoryOptions, sentimentOptions } from '../helper/FeedHelper';
import Select from 'react-select';
import { customStylesPlus, getImageUrl, LoadingSpinner  } from '../helper/StylesHelper';
import { renderTagLine , fetchDailyDataForTaggedAssets} from '../helper/PostHelper';
import DOMPurify from 'dompurify';
import { allowedHTML } from '../helper/PostHelper';
import { customStyles, CustomDateInput} from '../helper/StylesHelper';
import { hourOptions, minuteOptions, convertToScheduledAt, parseScheduledDateTime } from '../helper/CreatePostHelper';
import { fetchTickerSuggestions  } from '../helper/MarketsHelper';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import useResponsiveWidth from '../hooks/useResponsiveWidth';


/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * @typedef {import('../helper/PostHelper').QuotePostDetailsObject} QuotePostDetailsObject
 */

const CreatePost = () => {
  useResponsiveWidth();
  const location = useLocation();
  const navigate = useNavigate();


  /** @type {QuotePostDetailsObject} */
  const quotePostDetails = location.state?.quotePostDetails || null;

  const isQuotePost = location.state?.isQuotePost || false;
  const quickPostContent = location.state?.quickPostContent || '';
  const selectedCategoryQuick = location.state?.selectedCategoryQuick || [];  
  const selectedPostTypeQuick = location.state?.selectedPostTypeQuick || '';
  const fromFeed = location.state?.fromFeed || false;
  const viewingDraft = location.state?.viewingDraft || false;
  const editPost = location.state?.editPost || null; // The post is being edited

  const [caption, setCaption] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState(fromFeed ? quickPostContent : '');
  const [image, setImage] = useState(null);
  const [topicLine, setTopicLine] = useState('');
  const [view, setView] = useState(viewingDraft ? 'drafts' : 'create');
  const [drafts, setDrafts] = useState([]);
  const [currentDraftId, setCurrentDraftId] = useState(null);
  const [draftLoading, setDraftsLoading] = useState(false);

  /** @type {[QuotePostDetailsObject, Function]} */
  const [quotePostDetailsState, setQuotePostDetailsState] = useState(quotePostDetails);

  const [isQuotePostState, setIsQuotePostState] = useState(isQuotePost);
  const { userToken } = useContext(AuthContext);
  const {tc} = useContext(ThemeContext);  

  //These are used for the select dropdowns
  const [selectedPostTypes, setSelectedPostTypes] = useState(selectedPostTypeQuick ? [{ value: selectedPostTypeQuick, label: selectedPostTypeQuick }] : []);  
  const [selectedCategories, setSelectedCategories] = useState(selectedCategoryQuick.map((category) => ({ value: category, label: category })));
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinute, setSelectedMinute] = useState(null);


//These are submitted to the backend
  const [selectedCategory, setSelectedCategory] = useState(selectedCategoryQuick);
  const [selectedSentiment, setSelectedSentiment] = useState('');
  const [selectedType, setSelectedType] = useState(selectedPostTypeQuick);
  const [taggedAssets, setTaggedAssets] = useState([]);
  const [assetSuggestions, setAssetSuggestions] = useState([]); 
  const [placeholderText, setPlaceholderText] = useState('Tag assets +');
  const [dailyChangeData, setDailyChangeData] = useState(null); 
  const [quotePostTagLine, setQuotePostTagLine] = useState(null);
  const fileInputRef = useRef(null);
  const [draftKeywords, setDraftKeywords] = useState([]);
  const [newDraftKeyword, setNewDraftKeyword] = useState('');

  const handleDraftSearchInputChange = (e) => {

  }

  const handleAddDraftKeyword = (e) => {

  } 

  const handleRemoveDraftKeyword = (keyword) => {

  }




  useEffect(() => {
    if (quotePostDetailsState  ) {
      setQuotePostTagLine(
        renderTagLine(
          quotePostDetailsState.type,
          quotePostDetailsState.categories,
          quotePostDetailsState.sentiment,
          quotePostDetailsState.taggedAssets,
          dailyChangeData,
          tc
        )
      );
    }
  }, [quotePostDetailsState, dailyChangeData, tc]);

  


  useEffect(() => {
    const fetchTaggedData = async () => {
      if (taggedAssets.length > 0 || (quotePostDetailsState && quotePostDetailsState?.taggedAssets?.length > 0)) {
        let allAssets = quotePostDetailsState ? [...taggedAssets, ...quotePostDetailsState.taggedAssets] : [...taggedAssets];
        const dailyData = await fetchDailyDataForTaggedAssets(allAssets);
        setDailyChangeData(dailyData);
      }
    }
    fetchTaggedData();
  }, [taggedAssets, quotePostDetailsState]);







  const [showHeadline, setShowHeadline] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [showLink, setShowLink] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [selectedText, setSelectedText] = useState('');

  const contentEditableRef = useRef(null);
  const [savedRange, setSavedRange] = useState(null);


  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const toggleShowFullText = () => setShowFullText(!showFullText);
  



  useEffect(() => {
    if (editPost) {
      handleEdit(editPost);
    }
    // eslint-disable-next-line
  }, [editPost]);




  useEffect(() => {
    if (fromFeed && quickPostContent && contentEditableRef.current) {
      contentEditableRef.current.innerHTML = quickPostContent;
      setBody(quickPostContent); // Set the body state as well
    }
 
    // eslint-disable-next-line
  }, [ fromFeed, quickPostContent]);

  useEffect(() => {
    if (view === 'create' && currentDraftId && contentEditableRef.current) {
      contentEditableRef.current.innerHTML = body;
    }
    // eslint-disable-next-line
  }, [view, currentDraftId, contentEditableRef.current]);
  
  

  
  const handlePostTypeChange = (selectedOption) => {
    if (selectedOption.length > 1) {
      alert('You can only select one post type');
      return;
    }
    if (selectedOption.value === selectedType) {
      setSelectedType('');
      setSelectedPostTypes([]);
      return;
    }
    setSelectedPostTypes(selectedOption);
    setSelectedType(selectedOption.value);
  };

  const handleCategoryChange = (selectedOption) => {
    if (selectedOption.length > 3) {
      alert('You can only select up to 3 categories');
      return;
    }
    setSelectedCategories(selectedOption);
    setSelectedCategory(selectedOption.map((option) => option.value));
  };

  const handleSentimentChange = (selectedOption) => {
    if (selectedOption.length > 1) {
      alert('You can only select one sentiment');
      return;
    }
    if (selectedOption.value === selectedSentiment) {
      setSelectedSentiments([]);
      setSelectedSentiment('');
      return;
    }
    setSelectedSentiments(selectedOption);
    setSelectedSentiment(selectedOption.value);
  };



 




  useEffect(() => {
    if (selectedType && selectedCategory.length > 0) {
      const categoryText = selectedCategory.join(', ');
      const generatedTopicLine = `${selectedType} - ${categoryText}`;
      setTopicLine(generatedTopicLine);
    } else {
      setTopicLine(selectedType);
    }
  }, [selectedType, selectedCategory]);



  useEffect(() => {
    if (view === 'drafts' || view === 'scheduled' ) {
      setSelectedCategory([]);
      setSelectedSentiment('');
      setSelectedType('');
      setQuotePostDetailsState(null);
      setIsQuotePostState(false);
      setSelectedDate(null);
      setSelectedHour(null);
      setSelectedMinute(null);
      fetchDrafts();

      
    }
    // eslint-disable-next-line
  }, [view, isQuotePostState, quotePostDetailsState]);

  const fetchDrafts = async () => {
    setDraftsLoading(true);
    try {
      const response = await axios.get(`${config.backendUrl}/api/drafts`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setDrafts(response.data);
    } catch (error) {
      console.error('Error fetching drafts:', error);
      alert('Error fetching drafts');
    }
    setDraftsLoading(false);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const maxSize = 100 * 1024; // 100 KB

      if (file.size > maxSize) {
        alert('File size exceeds 100 KB. Please upload a smaller file.');
        return;
      }

      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
      if (validImageTypes.includes(file.type)) {
        setImage(file);
      } else {
        alert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP).');
      }
    } else {
      console.error('No file selected or invalid file');
    }
  };

  const handleImageRemove = () => {
    setImage(null);
    setShowImage(false);
    setCaption('');
  };





  const handleSubmit = async () => {
    if (!body) {
      alert('Content cannot be empty');
      return;
    }
    const isSchedulingIncomplete = 
    (selectedDate && (!selectedHour || !selectedMinute)) ||
    (selectedHour && (!selectedDate || !selectedMinute)) ||
    (selectedMinute && (!selectedDate || !selectedHour));

    if (isSchedulingIncomplete) {
      alert('Please select a date, hour, and minute for scheduling.');
      return;
    }


    let scheduledAt = (selectedDate && selectedHour && selectedMinute) ? convertToScheduledAt(selectedDate, selectedHour, selectedMinute) : null;
      // Check if the scheduled time is in the past
    if (scheduledAt && scheduledAt < new Date()) {
      alert('Scheduled time cannot be in the past');
      return;
    }
    const formData = new FormData();
    formData.append('type', selectedType ? selectedType : '');
    formData.append('categories', selectedCategory?.length > 0 ? JSON.stringify(selectedCategory) : '[]');
    formData.append('sentiment', selectedSentiment ? selectedSentiment : '');
    formData.append('content', body);
    formData.append('title', title ? title : '');
    formData.append('topicLine', topicLine ? topicLine : '');
    formData.append('isDraft', scheduledAt ? 'true' : 'false'); // If it is scheduled, it is a draft until the scheduled time
    formData.append('caption', caption ? caption : '');
    formData.append('taggedAssets', JSON.stringify(taggedAssets));

    if (scheduledAt) {
      formData.append('scheduledAt', scheduledAt);
    }

    if (isQuotePostState) {
      formData.append('quotePostId', quotePostDetailsState.postId);
      formData.append('isQuotePost', 'true');
    } else {
      formData.append('isQuotePost', 'false');
    }

    if (image && typeof image !== 'string') {
      formData.append('images', image);
  } else if (image && typeof image === 'string') {
      formData.append('images', image.substring(image.indexOf('uploads')));
  } 

    try {
      currentDraftId
        ? await axios.put(`${config.backendUrl}/api/posts/${currentDraftId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          })
        : await axios.post(`${config.backendUrl}/api/posts/create`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          });

      alert(!scheduledAt ? 'Post created successfully' : `Post scheduled for ${scheduledAt.toLocaleString()}`);
      setCurrentDraftId(null);
      navigate('/feed');
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Error creating post');
    }
  };

  const handleSaveToDrafts = async () => {
    if (!body) {
      alert('Content cannot be empty');
      return;
    }
    const isSchedulingIncomplete = 
    (selectedDate && (!selectedHour || !selectedMinute)) ||
    (selectedHour && (!selectedDate || !selectedMinute)) ||
    (selectedMinute && (!selectedDate || !selectedHour));

    if (isSchedulingIncomplete) {
      alert('Please select a date, hour, and minute for scheduling.');
      return;
    }

    let scheduledAt = (selectedDate && selectedHour && selectedMinute) ? convertToScheduledAt(selectedDate, selectedHour, selectedMinute) : null;
    // Check if the scheduled time is in the past
    if (scheduledAt && scheduledAt < new Date()) {
      alert('Scheduled time cannot be in the past');
      return;
    }
    const formData = new FormData();
    formData.append('type', selectedType ? selectedType : '');
    formData.append('categories', selectedCategory?.length > 0 ? JSON.stringify(selectedCategory) : '[]');
    formData.append('sentiment', selectedSentiment ? selectedSentiment : '');
    formData.append('content', body);
    formData.append('title', title ? title : '');
    formData.append('topicLine', topicLine ? topicLine : '');
    formData.append('isDraft', 'true');
    formData.append('caption', caption ? caption : '');
    formData.append('taggedAssets', JSON.stringify(taggedAssets));
    
        if (scheduledAt) {
        formData.append('scheduledAt', scheduledAt);
        }

    if (isQuotePostState) {
      formData.append('quotePostId', quotePostDetailsState.postId);
      formData.append('isQuotePost', 'true');
    } else {
      formData.append('isQuotePost', 'false');
    }

    if (image && typeof image !== 'string') {
      formData.append('images', image);
  } else if (image && typeof image === 'string') {
      formData.append('images', image.substring(image.indexOf('uploads')));
  } 

    try {
      const response = currentDraftId
        ? await axios.put(`${config.backendUrl}/api/posts/${currentDraftId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          })
        : await axios.post(`${config.backendUrl}/api/posts/create`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userToken}`,
            },
          });

      alert(!scheduledAt ? 'Draft saved successfully' : `Post scheduled for ${scheduledAt.toLocaleString()}`);
      setCurrentDraftId(response.data._id);
      setView('drafts');
      fetchDrafts();
    } catch (error) {
      console.error('Error saving draft:', error);
      alert('Error saving draft');
    }
  };

  const handleResetSchedule = () => {
    setSelectedDate(null);
    setSelectedHour(null);
    setSelectedMinute(null);
  };



  const handleEdit = async (draft) => {
    if (draft.isQuotePost) {
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${draft.quotePostId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        const draftQuotePost = response.data;
  
        setQuotePostDetailsState({
          postId: draftQuotePost._id,
          profilePic: `${draftQuotePost.author.profilePicture}`,
          author: draftQuotePost.author.name,
          verified: draftQuotePost.author.isVerified,
          username: draftQuotePost.author.username,
          content: draftQuotePost.content,
          title: draftQuotePost.title,
          time: draftQuotePost.time,
          sentiment: draftQuotePost.sentiment,
          type: draftQuotePost.type,
          categories: draftQuotePost.categories,
          images: draftQuotePost.images,
          caption: draftQuotePost.caption,
          taggedAssets: draftQuotePost.taggedAssets,
        });
        setIsQuotePostState(true);
      } catch (error) {
        console.error('Error fetching quote post:', error);
      }
    } else {
      setIsQuotePostState(false);
    }
  
    // Set the properties for the post to edit
    setShowHeadline(draft.title ? true : false);
    setShowImage(draft.images.length > 0 ? true : false);
    setTitle(draft.title || '');
    setCaption(draft.caption || '');
  
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = draft.content || ''; // Set the content from the draft
    }
  
    // Sync other states
    setBody(draft.content || '');
    setSelectedCategory(draft.categories.length > 0 ? draft.categories : []);
    setSelectedSentiment(draft.sentiment || '');
    setSelectedType(draft.type || '');
    setSelectedPostTypes(draft.type ? [{ value: draft.type, label: draft.type }] : []);
    setSelectedCategories(draft.categories.map((category) => ({ value: category, label: category })));
    setSelectedSentiments(draft.sentiment ? [{ value: draft.sentiment, label: draft.sentiment }] : []);
    setTaggedAssets(draft.taggedAssets || []);
    setTopicLine(draft.topicLine || '');
    setCurrentDraftId(draft._id || null);
    setImage(draft.images.length > 0 ? draft.images[0] : null);

    if (draft.scheduledAt) {
      const { date, hour, minute } = parseScheduledDateTime(draft.scheduledAt);

      setSelectedDate(date);
      setSelectedHour(hour);
      setSelectedMinute(minute);

    }


  
    setView('create');
  };
  

  const handleDelete = async (draftId) => {
    if (!window.confirm('Are you sure you want to delete this draft?')) {
      return;
    }

    try {
      await axios.delete(`${config.backendUrl}/api/drafts/${draftId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      alert('Draft deleted successfully');
      fetchDrafts();
    } catch (error) {
      console.error('Error deleting draft:', error);
      alert('Error deleting draft');
    }
  };

  useEffect(() => {
    if (!showHeadline && !editPost) {
      setTitle('');
    }
    if (!showImage && !editPost) {
      setImage(null);
    }
  }, [showHeadline, showImage, editPost]);

  const handleShowHeadline = () => {
    setShowHeadline((prevShow) => !prevShow);
  };

  const handleShowImage = () => {
    setShowImage((prevShow) => !prevShow);
    if (!showImage) {
      fileInputRef.current.click();
    }
  };



  // Formatting functions
// Modify applyBold function
const applyBold = () => {
  document.execCommand('bold', false, null);
  const selection = window.getSelection();
  const selectedText = selection.toString();
  if(!selectedText) {
    alert('Please highlight text to bold.');
    return;
  }
  if (selection.rangeCount > 0) {
    selection.collapseToEnd(); // Collapse the selection to the end
    document.execCommand('bold', false, null); // Toggle bold off
  }
};

// Modify applyItalic function
const applyItalic = () => {
  document.execCommand('italic', false, null);
  const selection = window.getSelection();
  const selectedText = selection.toString();
    if(!selectedText) {
    alert('Please highlight text to italicize.');
    return;
  }
  if (selection.rangeCount > 0) {
    selection.collapseToEnd(); // Collapse the selection to the end
    document.execCommand('italic', false, null); // Toggle italic off
  }
};

// Modify applyUnderline function
const applyUnderline = () => {
  document.execCommand('underline', false, null);
  const selection = window.getSelection();
  const selectedText = selection.toString();

  if(!selectedText) {
    alert('Please highlight text to underline.');
    return;
  }

  if (selection.rangeCount > 0) {
    selection.collapseToEnd(); // Collapse the selection to the end
    document.execCommand('underline', false, null); // Toggle underline off
  }
};



const handleShowLink = () => {
  const selection = window.getSelection();
  const selectedText = selection.toString();
  if (selectedText) {
    setAnchorEl(selection.anchorNode.parentElement);
    setSelectedText(selectedText);
    // Save the selection range
    setSavedRange(selection.getRangeAt(0).cloneRange());
    setShowLink(true);
  } else {
    alert('Please highlight text to create a link.');
  }
};


const handleLinkInsert = () => {
  const url = linkUrl;
  if (url) {
    const selection = window.getSelection();
    // Restore the saved selection range
    if (savedRange) {
      selection.removeAllRanges();
      selection.addRange(savedRange);
    } else {
      alert('Could not restore text selection. Please try again.');
      return;
    }
    if (selection.rangeCount > 0) {
      const linkHTML = `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color:#1DCF5B; text-decoration: underline;">${selectedText}</a>`;
      document.execCommand('insertHTML', false, linkHTML);
    }
    setShowLink(false);
    setLinkUrl('');
    setSavedRange(null); // Clear the saved range
  }
};


  const renderCreatePost = () => (
    <>

{showAdvancedControls && (
  <>
    <div className="cp-CreatePostFiltersRow">
      <span className="FeedSortText">Filters: </span>
      <Select
        className="FeedPostType"
        value={selectedPostTypes}
        onChange={handlePostTypeChange}
        options={postTypeOptions}
        unstyled
        isSearchable={false}
        styles={customStylesPlus(tc())}
        closeMenuOnSelect={true}
        placeholder="Post type +"
      />
      <Select
        className="FeedPostType"
        value={selectedCategories}
        onChange={handleCategoryChange}
        options={categoryOptions}
        isMulti
        unstyled
        isSearchable={false}
        styles={customStylesPlus(tc())}
        closeMenuOnSelect={false}
        placeholder="Category +"
      />
      <Select
        className="FeedCategory"
        value={selectedSentiments}
        onChange={handleSentimentChange}
        options={sentimentOptions}
        unstyled
        isSearchable={false}
        styles={customStylesPlus(tc())}
        closeMenuOnSelect={true}
        placeholder="Sentiment +"
      />
    </div>

    <div className="cp-CreatePostFiltersRow">
      <span className="FeedSortText">Post elements: </span>
      {!isQuotePostState && (
        <button className="cp-AddElementButton" onClick={handleShowHeadline}>
          Headline {showHeadline ? '-' : '+'}
        </button>
      )}
     <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />

      <button className="cp-AddElementButton" onClick={handleShowImage}>
        Image {showImage ? '-' : '+'}
      </button>
      <Select
  className="FeedAssetTag"
  value={taggedAssets.map((ticker) => ({ value: ticker, label: ticker }))}
  onChange={(selectedOptions) => {
    if (selectedOptions.length > 5) {
      alert('You can tag a maximum of 5 assets.');
      return;
    }
    setTaggedAssets(selectedOptions.map((option) => option.value));
  }}
  onInputChange={(inputValue) => {
    fetchTickerSuggestions(inputValue, setAssetSuggestions); // Fetch asset suggestions based on input
  }}
  options={assetSuggestions.map((suggestion) => ({
    value: suggestion.ticker,
    label: `${suggestion.ticker} - ${suggestion.name}`,
  }))}
  isMulti
  isSearchable
  placeholder={placeholderText}
  onFocus={() => setPlaceholderText("Search for a ticker")}
  onBlur={() => setPlaceholderText("Tag assets +")}
  styles={{
    ...customStylesPlus(tc()),
    menu: (provided) => ({
      ...provided,
      minWidth: '20rem', 
    }),
  }}
  noOptionsMessage={() => null}
  closeMenuOnSelect={false}
  unstyled
/>
         
    </div>

    <div className="cp-CreatePostFiltersRow">
      <span className="FeedSortText">Highlight text to: </span>
      <button className="cp-AddElementButton" onClick={handleShowLink}>
        Link
      </button>
      <button className="cp-AddElementButton" onClick={applyBold}>
        Bold
      </button>
      <button className="cp-AddElementButton" onClick={applyItalic}>
        Italicize
      </button>
      <button className="cp-AddElementButton" onClick={applyUnderline}>
        Underline
      </button>
    </div>

    <div className="cp-CreatePostFiltersRow">
      <span className="FeedSortText">Schedule Post: </span>

      <DatePicker
        selected={selectedDate}
        onSelect={(date) => {
          if (date && date.getTime() === selectedDate?.getTime()) {
            setSelectedDate(null); // Reset to null if the same date is selected
          } else {
            setSelectedDate(date); // Set the selected date
          }
        }}
        minDate={new Date()}
        customInput={<CustomDateInput theme={tc()} />}
        dateFormat="MMMM d, yyyy"
      />

      <Select
        className="FeedSortButton"
        isSearchable={false}
        unstyled
        styles={customStyles(tc())}
        title="Hour"
        options={hourOptions}
        value={hourOptions.find((option) => option.value === selectedHour) || null}
        onChange={(option) => {
          if (option.value === selectedHour) {
            setSelectedHour(null);
          } else {
            setSelectedHour(option.value);
          }
        }}
        placeholder="Hour"
      />

      <Select
        className="FeedSortButton"
        isSearchable={false}
        unstyled
        styles={customStyles(tc())}
        title="Minute"
        options={minuteOptions}
        value={minuteOptions.find((option) => option.value === selectedMinute) || null}
        onChange={(option) => {
          if (option.value === selectedMinute) {
            setSelectedMinute(null);
          } else {
            setSelectedMinute(option.value);
          }
        }}
        placeholder="Minute"
      />

      <button className="cp-AddElementButton" onClick={handleResetSchedule}>
        Reset
      </button>
    </div>
  </>
)}

      <button 
      style={{marginBottom: '1rem'}}
      className="CreatePostFeedButton transBorder" onClick={() => setShowAdvancedControls((prev) => !prev)} >
      {showAdvancedControls ? 'Back to basic' : 'Advanced controls'}
        </button>
      

      {!isQuotePostState && showHeadline && (
        <>
          <input
            type="text"
            placeholder="Headline"
            className="cp-CreatePostTitle"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <hr />
        </>
      )}

      <div className="cp-CreatePostMain">
        <div className="cp-CreatePostRow">
          {/* ContentEditable Div */}
          <div
        ref={contentEditableRef}
        contentEditable
        placeholder="Share your thoughts"
        className={!isQuotePostState ? 'cp-CreatePostBody' : 'cp-CreatePostBodyQuotePost'}
        onInput={(e) => setBody(e.currentTarget.innerHTML)}
        onPaste={handlePaste} 
        style={{ resize:'none', whiteSpace: 'pre-wrap', textAlign: 'left', outline: 'none', overflow: 'auto', marginTop: showHeadline ? '1rem' : '0', maxHeight: '80vh' }}
      ></div>
          <div>
            {!image && showImage ? (
              <></>
         
            ) : (
              <>
                {showImage && image && (
                  <div className="cp-ImageWrapper">
                    <img src={
                    image &&
                    typeof image === 'string' 
                      ? getImageUrl(image) 
                      : URL.createObjectURL(image)
                  } alt="Selected" className="cp-SelectedImage" />
                    <div className="cp-RemoveImageIcon" onClick={handleImageRemove}>
                      x
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {image && (
            <div className="cp-CaptionInputContainer">
              <span className="cp-CaptionLabel bold">Caption:</span>
              <input
                type="text"
                placeholder="Enter image caption"
                className="cp-CaptionInput"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          )}
        </div>

        {isQuotePostState && (
          <div className={`cp-QuotePostContainer ${isQuotePostState ? 'cp-FullWidth' : ''}`}>
            <div className="cp-QuotePostHeader">
              <img src={getImageUrl(quotePostDetailsState.profilePic)} alt="Profile" className="cp-QuoteProfilePic" />
              <div className="cp-QuotePostInfoWrapper" style={{ flexDirection: 'column' }}>
                <div className="cp-QuotePostInfo">
                  <div className="cp-QuotePostAuthor cp-bold">
                    <span style={{ marginRight: !quotePostDetailsState.author.isVerified ? '0.3rem' : '0rem' }}>
                      <span className="bold"> {quotePostDetailsState.author}</span>
                    </span>
                    {quotePostDetailsState.verified && (
                      <FontAwesomeIcon
                        style={{ marginLeft: '0.1rem', marginRight: '0.4rem' }}
                        icon={faCheckCircle}
                        className="Verified"
                      />
                    )}
                  </div>
                  <div className="cp-QuotePostDetails">
                    {quotePostDetailsState.username && `@${quotePostDetailsState.username}`}
                    {quotePostDetailsState.timePosted && ` · ${quotePostDetailsState.timePosted}`}
                    {quotePostTagLine && (
                    <>
                          {' · '}
                          {quotePostTagLine}
                        </>
                      )}
                  </div>
                </div>
                <h2 className="cp-QuotePostTitle bold">
                  {quotePostDetailsState.title && <>{quotePostDetailsState.title}</>}
                </h2>
                <div className="cp-QuotePostContent">
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(quotePostDetailsState.content, allowedHTML) }}
                    ></div>
                  </div>
                {quotePostDetailsState.images &&
                  quotePostDetailsState.images.map((image, index) => (
                    <img key={index} src={getImageUrl(image)} alt={`Post ${index}`} className="PostImage-sm" />
                  ))}
                {quotePostDetailsState.caption && (
                  <div className="cp-QuotePostCaption">{quotePostDetailsState.caption}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Link Popover */}
      <Popover open={showLink} anchorEl={anchorEl} onClose={() => setShowLink(false)}>
        <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField label="Link URL" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />
          <Button style={{backgroundColor: '#1DCF5B'}}variant="contained" onClick={handleLinkInsert}>
            Confirm
          </Button>
        </div>
      </Popover>
    </>
  );

  const handlePaste = (e) => {
    e.preventDefault();
  
    const pastedHTML = e.clipboardData.getData('text/html');
    const pastedText = e.clipboardData.getData('text/plain');
  
    let cleanHtml;
  
    if (pastedHTML) {
      // Sanitize the pasted HTML to allow only specific tags
      cleanHtml = DOMPurify.sanitize(pastedHTML, allowedHTML);
    } else {
      // If no HTML, use the plain text
      cleanHtml = pastedText;
    }
  
    // Insert the sanitized HTML or text
    document.execCommand('insertHTML', false, cleanHtml);
  };

  const renderDrafts = () => {
    return (
      <div className="cp-DraftList">
      

        <div className="cp-BackDraft">
          <button onClick={() => setView('create')} className="pv-BackButton">
            <FontAwesomeIcon icon={faArrowLeft} style = {{color: 'var(--action-grey)'}} /> <span style={{ color: 'var(--action-grey)' }}>Back</span>
          </button>
        </div>
        {draftLoading && (
          <LoadingSpinner/>
        )}
        {!draftLoading && drafts.length === 0 ? (
          <div className="cp-NoDrafts">No drafts found</div>
        ) : (
          drafts.map((draft) => (
            <div className="cp-DraftItemWrapper" key={draft._id}>
              <div className="cp-DraftItem">
                <div className="cp-DraftItemTitle">{draft.title}</div>
                <div className="cp-DraftItemContent">
              <span
              className="cp-DraftItemBody"
              style={{ width: draft.images ? '75%' : '100%', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                 draft.content.substring(0,1000) + '<span>...</span>' , allowedHTML

                )
              }}
            ></span>

                  {draft.images.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '25%' }}>
                      {draft.images && <img src={getImageUrl(draft.images[0])} alt="Post" className="cp-PostImageDraft" />}
                    </div>
                  )}
                </div>
                <div className="cp-DraftActions">
                  <button className="cp-EditButtonDraft" onClick={() => handleEdit(draft)}>
                    Edit
                  </button>
                  <button className="cp-DeleteButtonDraft" onClick={() => handleDelete(draft._id)}>
                    Delete
                  </button>
                  <div style = {{display: 'flex', flexDirection:'column'}}>

                  <span style={{ color: 'var(--action-grey)' }}>
                    Last edited: {formatTimeSince(timeSince(draft.lastEdited))}
                  </span>
                  <span style={{ color: 'var(--action-grey)'}}>
                   Scheduled for: {draft.scheduledAt ? new Date(draft.scheduledAt).toLocaleString() : 'not scheduled'}
                  </span>
                  </div>

                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };

  // almost same as render drafts but only show scheduled posts
  const renderSchedule = () => {
    const scheduledPosts = drafts?.filter((draft) => draft.scheduledAt !== null);
    return (
      <div className="cp-DraftList">
        <div className="cp-BackDraft">
          <button onClick={() => setView('create')} className="pv-BackButton">
            <FontAwesomeIcon icon={faArrowLeft} /> <span style={{ color: '#707070' }}>Back</span>
          </button>
        </div>
        {draftLoading && (
            <LoadingSpinner/>
        )}
        {!draftLoading && scheduledPosts.length === 0 ? (
          <div className="cp-NoDrafts">No scheduled posts found</div>
        ) : (
          scheduledPosts.map((draft) => (
            <div className="cp-DraftItemWrapper" key={draft._id}>
              <div className="cp-DraftItem">
                <div className="cp-DraftItemTopicLine">{draft.topicLine}</div>
                <div className="cp-DraftItemTitle">{draft.title}</div>
                <div className="cp-DraftItemContent">
              <span
              className="cp-DraftItemBody"
              style={{ width: draft.images ? '75%' : '100%', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                 draft.content.substring(0,1000) + '<span>...</span>' , allowedHTML

                )
              }}
            ></span>

                  {draft.images.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '25%' }}>
                      {draft.images && <img src={getImageUrl(draft.images[0])} alt="Post" className="cp-PostImageDraft" />}
                    </div>
                  )}
                </div>
                <div className="cp-DraftActions">
                  <button className="cp-EditButtonDraft" onClick={() => handleEdit(draft)}>
                    Edit
                  </button>
                  <button className="cp-DeleteButtonDraft" onClick={() => handleDelete(draft._id)}>
                    Delete
                  </button>
                  <div style = {{display: 'flex', flexDirection:'column'}}>

                  <span style={{ color: 'var(--action-grey)' }}>
                    Last edited: {formatTimeSince(timeSince(draft.lastEdited))}
                  </span>
                  <span style={{ color: 'var(--action-grey)' }}>
                   Scheduled for: {draft.scheduledAt ? new Date(draft.scheduledAt).toLocaleString() : 'Not scheduled'}
                  </span>
                  </div>

                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };
  
  const renderPreviewBody = () => {
    const sanitizedBody = DOMPurify.sanitize(body, allowedHTML);
   const shouldTruncate = sanitizedBody.length > 300;
const truncatedBody = shouldTruncate ? sanitizedBody.slice(0, 300) + '...' : sanitizedBody;
    return (
      <>
     
      <div className="cp-PreviewBody">
        <div
          dangerouslySetInnerHTML={{ __html: showFullText || !shouldTruncate ? sanitizedBody : truncatedBody }}
        ></div>
      
      </div>

        
      {shouldTruncate && !showFullText && (
        <button className="CreatePostFeedButton" onClick={toggleShowFullText}>
          View More
        </button>
      )}

      {showFullText && (
        <button className="CreatePostFeedButton" onClick={toggleShowFullText}>
          View Less
        </button>
      )}

    </>


    );
  };

  const renderPreviewImage = () => (
    <>
      <div className="cp-PreviewImageContainer">
        {image ? (
          <img src={
            image &&
            typeof image === 'string' 
              ? getImageUrl(image) 
              : URL.createObjectURL(image)
          } alt="Preview" className="cp-PreviewImage" />
        ) : (
          <FontAwesomeIcon icon={faCamera} size="2x" />
        )}
      </div>
      <span className="cp-CreatePostCaptionSpan">{caption}</span>
    </>
  );

  const renderQuotePost = () => (
    <div className={`cp-QuotePostContainer`}>
      <div className="cp-QuotePostHeader">
        <img src={getImageUrl(quotePostDetailsState.profilePic)} alt="Profile" className="cp-QuoteProfilePic" />
        <div className="cp-QuotePostInfoWrapper" style={{ flexDirection: 'column' }}>
          <div className="cp-QuotePostInfo">
            <div className="cp-QuotePostAuthor cp-bold">
              <span style={{ marginRight: !quotePostDetailsState.author.isVerified ? '0.3rem' : '0rem' }}>
                <span className="bold"> {quotePostDetailsState.author}</span>
              </span>
              {quotePostDetailsState.verified && (
                <FontAwesomeIcon
                  style={{ marginLeft: '0.1rem', marginRight: '0.4rem' }}
                  icon={faCheckCircle}
                  className="Verified"
                />
              )}
            </div>
            <div className="cp-QuotePostDetails">
              {quotePostDetailsState.username && `@${quotePostDetailsState.username}`}
              {quotePostDetailsState.timePosted && ` · ${quotePostDetailsState.timePosted}`}
              {quotePostTagLine && (
              <>
                {' · '}
                {quotePostTagLine}
              </>
            )}
            </div>
          </div>
          <h2 className="cp-QuotePostTitle bold">
            {quotePostDetailsState.title && <>{quotePostDetailsState.title}</>}
          </h2>
          <div className="cp-QuotePostContent">
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(quotePostDetailsState.content, allowedHTML) }}
                    ></div>
                  </div>
          {quotePostDetailsState.images &&
            quotePostDetailsState.images.map((image, index) => (
              <img key={index} src={getImageUrl(image)} alt={`Post ${index}`} className="PostImage-sm" />
            ))}
          {quotePostDetailsState.caption && (
            <div className="cp-QuotePostCaption">{quotePostDetailsState.caption}</div>
          )}
        </div>
      </div>
    </div>
  );


  return (
    <div className="cp-CreatePostContainer">
      <div className={`cp-CreatePostMiddle ${view !== 'create' ? 'expanded' : ''}`}>
        {view === 'create' ? renderCreatePost() : view === 'drafts' ?  renderDrafts() : renderSchedule()}
      </div>

      {view === 'create' && (
        <div className="cp-CreatePostRightSidebar">
          <span className="cp-PostPreviewSpan bold">Post preview:</span>
          <div className="cp-PreviewTopicLine">{renderTagLine(selectedType, selectedCategory, selectedSentiment, taggedAssets, dailyChangeData, tc)}</div>
          {isQuotePostState ? (
            renderPreviewBody()
          ) : (
            !isQuotePostState && showHeadline && <div className="cp-PreviewTitle bold">{title || 'Headline'}</div>
          )}

          {isQuotePostState && showImage && renderPreviewImage()}
          {isQuotePostState && renderQuotePost()}
          {!isQuotePostState && renderPreviewBody()}
          {!isQuotePostState && showImage && renderPreviewImage()}

          <div className="cp-PreviewFooter">
            <button className="cp-PreviewButton cp-Post bold" onClick={handleSubmit}>
              Post
            </button>
            <button className="cp-PreviewButton cp-Save" onClick={handleSaveToDrafts}>
              Save to drafts
            </button>
            <button className="cp-PreviewButton cp-Save" onClick={() => setView('drafts')}>
              View drafts
            </button>
            <button className="cp-PreviewButton cp-Save" onClick={() => setView('scheduled')}>
              View schedule
            </button>
          </div>

        </div>
      )}
    </div>
  );
};

export default CreatePost;
