import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import '../styles/Stock.css';
import { createStockChart } from '../helper/StockChartHelper';
import {
   getUnixTimestamp,
   formatNumber,
   getUnixTimestampRange,
   getESTDate,
   intervalMap,
   filters,
   getThreeMonthsAgo,
   getMarketStatus,
   filterHighMap,
   isOldCompany,
   getLastValidTradingDay,
   timespanToMilliseconds,
   handleBuyAsset,
   handleSellAsset,
   fetchUserPortfolio,
   fetchAllTransactions,
   fetchDailyOpenClose,
   getDailyChange,
   fetchCompanyDetails,
   fetchStockLists,
   fetchDividendYield,
   fetchEPSAndPERatio,
   renderAfterMarketChange,
   getStartOfTradingWeek,
   calculateReturns,
   getChangePercentageColor,
    handleAddToWatchlist,
    handleRemoveFromWatchlist,
    getWatchList,
    getTickerNews,
    getStartOfTradingDay,
    calculatePercentageChange,

} from '../helper/MarketsHelper';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import {  LoadingSpinner, CustomDateInput, formatCurrency as fc, removeCommas as rc} from '../helper/StylesHelper';
import DatePicker from 'react-datepicker'; 
import { ThemeContext } from '../context/ThemeContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import MarketSearchBar from './MarketSearchBar';
import BackButton from './BackButton';
import { DateTime } from 'luxon';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

export default function Stock() {
  useResponsiveWidth();
   const { ticker } = useParams();
   const navigate = useNavigate();
   const {tc,  theme} = useContext(ThemeContext);
   const {isAuthenticated, userToken, username} = useContext(AuthContext);
   const [stockData, setStockData] = useState([]);
   const [dailyData, setDailyData] = useState({});  // Store daily open/close data
   const [filter, setFilter] = useState('1d');
   const [currentPrice, setCurrentPrice] = useState(null);
   const [changeAmount, setChangeAmount] = useState(null);
   const [changePercent, setChangePercent] = useState(null);
   const [stockName, setStockName] = useState(ticker); // Use ticker as placeholder name
   const [companyDetails, setCompanyDetails] = useState({});
   const [companyDescription, setCompanyDescription] = useState('');
   const [eps, setEps] = useState(null); // Store EPS
   const [dividendYield, setDividendYield] = useState(null); // Store dividend yield
   const [peRatio, setPERatio] = useState(null); // Store P/E Ratio
   const [periodHigh, setPeriodHigh] = useState(null); 
   const [periodLow, setPeriodLow] = useState(null);  
   const [dateIPO, setDateIPO] = useState(null); // Store IPO date
   const [mainStockDataFetched, setMainStockDataFetched] = useState(false);
   //eslint-disable-next-line
   const [companyDetailsFetched, setCompanyDetailsFetched] = useState(false);
  //eslint-disable-next-line
   const [listOptions, setListOptions] = useState([]);
  //eslint-disable-next-line
   const [selectedLists, setSelectedLists] = useState([]);
   const chartRef = useRef(null);
   const chartInstance = useRef(null); // Store the chart instance
   const [referencePriceForChart, setReferencePriceForChart] = useState(null);
   const [marketStatus, setMarketStatus] = useState(null);
   const [dailyChangeData, setDailyChangeData] = useState(null);  // Store daily change data
   const [lastValidTradingDay, setLastValidTradingDay] = useState(null);
   const [livePrice, setLivePrice] = useState(null);    // This is the live price from the WebSocket
   const [liveChangeAmount, setLiveChangeAmount] = useState(null);  
   const [liveChangePercent, setLiveChangePercent] = useState(null);

    const [hoveredPrice, setHoveredPrice] = useState(null);
    const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
    const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
    const [hoveredDateTime, setHoveredDateTime] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [openBuyModal, setOpenBuyModal] = useState(false); // To handle modal state
    const [openSellModal, setOpenSellModal] = useState(false); // To handle modal state
 
    const wsRef = useRef(null); // Store WebSocket connection
    const instantPriceTimeoutRef = useRef(null); // Store timeout reference for instant price updates

    const [quantity, setQuantity] = useState(null); 
    const [estimatedCost, setEstimatedCost] = useState(null); 

    //eslint-disable-next-line
    const [bidAsk, setBidAsk] = useState({ bid: null, ask: null }); // Store bid and ask prices

    const [userPortfolio, setUserPortfolio] = useState(null); 
    const [userTickerPosition, setUserTickerPosition] = useState(null); // Store the user's position in the stock
    const [allTransactions, setAllTransactions] = useState([]); // Store all transactions for the stock

    const [returnData, setReturnData] = useState(null);

    const [openTradeOptionsModal, setOpenTradeOptionsModal] = useState(false);
    const [earliestDate, setEarliestDate] = useState(null); // Track the earliest available date
    const [expirationDate, setExpirationDate] = useState(null); 
    const [optionType, setOptionType] = useState('call'); // Default to 'call'
    const [optionsData, setOptionsData] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false); 
    const [isInWatchlist, setIsInWatchlist] = useState(false);
    const [stockNews, setStockNews] = useState([]);
    const [isConfirming, setIsConfirming] = useState(false);  
    const [isCongrats, setIsCongrats] = useState(false);  
    const [stock, setStock] = useState('');

    const [availableDates, setAvailableDates] = useState([]); // Available expiration dates for the calendar
    

  


    useEffect(() => {
      if (isAuthenticated) {
        getWatchList(ticker, userToken, setIsInWatchlist);
      }
    }, [isAuthenticated, userToken, ticker]);

    useEffect(()=> {
      getTickerNews(ticker).then((data) => {
        setStockNews(data);
      });
    }, [ticker])

    useEffect(()=> {
      document.getElementById('stock-container-wrapper')?.scrollIntoView();
    }, [])


  // Load options data based on the selected expiration date and option type
  const loadOptionsData = async (expiration, type) => {
    try {
      setLoadingOptions(true);
      
      // Format the date as 'YYYY-MM-DD' in Eastern Time
      const formattedExpiration = DateTime.fromJSDate(expiration, { zone: 'America/New_York' }).toFormat('yyyy-MM-dd');
      
      const response = await axios.get(`${config.backendUrl}/api/polygon/stock/options/${ticker}`, {
        params: { expiration: formattedExpiration, type },
      });
      setOptionsData(response.data);
    } catch (error) {
      setOptionsData([]);
      console.error('Error fetching options data:', error);
    } finally {
      setLoadingOptions(false);
    }
  };

  const loadAvailableDatesOfTheMonth = async (month, year) => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/stock/monthly-options/${ticker}`, {
        params: { month, year }
      });
  
      const allOptionsData = response.data.availableOptionsByDate || [];
      const newDates = allOptionsData.map(option => {
        const dateTimeInET = DateTime.fromISO(`${option.expirationDate}T16:00:00`, { zone: 'America/New_York' });
        if (!dateTimeInET.isValid) {
          console.error('Invalid DateTime:', dateTimeInET.invalidExplanation);
          return null;
        }
        return dateTimeInET.toJSDate();
      }).filter(date => date !== null); 
    setAvailableDates(prevDates => {
      const combinedDates = [...prevDates, ...newDates];
      const uniqueDates = Array.from(new Set(combinedDates.map(date => date.toDateString())))
        .map(dateString => new Date(dateString));
      setEarliestDate(uniqueDates[0]);
      
      return uniqueDates;
    });

   
    } catch (error) {
      console.error('Error loading dates:', error);
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    loadAvailableDatesOfTheMonth(currentDate.getMonth() + 1, currentDate.getFullYear());
  }, [ticker]);


  // Open the options modal and fetch the initial data
  const handleTradeOptionsClick = () => {
    setOpenTradeOptionsModal(true);
    loadOptionsData(expirationDate, optionType);
  };



  // Change expiration date
  const handleExpirationDateChange = (date) => {
    setExpirationDate(date);
    loadOptionsData(date, optionType);
  };

  // Change option type (call/put)
  const handleOptionTypeChange = (selectedOption) => {
    setOptionType(selectedOption);
    loadOptionsData(expirationDate, selectedOption);
  };



  // Handle month change in the DatePicker
  const handleMonthChange = (date) => {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    loadAvailableDatesOfTheMonth(month, year); // Fetch available dates for the selected month and year
  };



  useEffect(() => {
    if (earliestDate && !expirationDate) {
      setExpirationDate(earliestDate);
    }
  }, [earliestDate, expirationDate]);

    useEffect(()=> {
      if ((livePrice || currentPrice) && userTickerPosition && allTransactions) {
        let returns = calculateReturns(userTickerPosition, rc(livePrice || currentPrice), allTransactions);
        setReturnData(returns);
    }

    }, [livePrice, currentPrice, userTickerPosition, allTransactions])

    useEffect(()=> {
      if (userPortfolio && ticker) {
       setUserTickerPosition(userPortfolio?.assets.find((stock) => stock?.ticker?.toUpperCase() === ticker?.toUpperCase()));
      }
    }, [userPortfolio, ticker]);


    useEffect(()=> {
      if (userToken) {
        fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio);
        fetchAllTransactions(isAuthenticated, userToken, ticker, setAllTransactions);
      }
      //eslint-disable-next-line
    }, [userToken, ticker]);



    







    useEffect(()=> {
      // Calculate the estimated cost when the price changes
      if ((livePrice || currentPrice) ) {
        setEstimatedCost((quantity * rc(livePrice || currentPrice) ).toFixed(2));
      }
    }, [livePrice, quantity, currentPrice]);


    const updateChanges = (newPrice) => {
      if (!referencePriceForChart) {
        return;
      }
      setLivePrice(newPrice.toFixed(2));
      const change = newPrice - referencePriceForChart;
      const changePercentage = (change / referencePriceForChart) * 100;
      if (filter === '1d') {
            let priceChange;
            if (dailyChangeData?.todaysClosePrice) {
              priceChange = dailyChangeData.todaysClosePrice - dailyChangeData?.prevClosePrice;
              setLiveChangeAmount(priceChange.toFixed(2));
              setLiveChangePercent(dailyChangeData?.todaysPercentChange.toFixed(2));
            } else {
              priceChange = (livePrice || currentPrice) - dailyChangeData?.prevClosePrice;
              setLiveChangeAmount(priceChange.toFixed(2));
              setLiveChangePercent(changePercentage.toFixed(2));
            }
      } else {
        setLiveChangeAmount(change.toFixed(2));
        setLiveChangePercent(changePercentage.toFixed(2));
      }
    }

    useEffect(() => {
      // Function to fetch the instant price
      const fetchPrice = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        }
      };
      // Set interval to fetch the price every second
      const intervalId = setInterval(fetchPrice, 1000);
    
      // Cleanup the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, [ticker]); 


   const sendInterval = (intervalMs) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      console.log('Sending setInterval action to server:', intervalMs);
      wsRef.current.send(JSON.stringify({
        action: 'setInterval',
        ticker,
        intervalMs
      }));
    } else {
      console.log('WebSocket not ready, trying again...');
    }
  };

  useEffect(() => {
    
    // Retry every 500ms until the connection is open
    const interval = setInterval(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const [multiplier, timespan] = intervalMap[filter];
        const intervalMs = timespanToMilliseconds(multiplier, timespan);
        sendInterval(intervalMs);
        clearInterval(interval); // Stop retrying once it's sent
      }
    }, 500);

    //eslint-disable-next-line
  }, [filter, ticker]);

    const handleBuyClick = () => {
      if (!isAuthenticated) {
        alert('Please sign in to access this feature');
        return;
      }
      setOpenBuyModal(true);
    };

    const handleSellClick = () => { 
      if (!isAuthenticated) {
        alert('Please sign in to access this feature');
        return;
      }
      setOpenSellModal(true);
    };


    // Function to close the buy modal
    const handleCloseModal = () => {
      setOpenBuyModal(false);
      setOpenSellModal(false);
      setOpenTradeOptionsModal(false);
      setIsConfirming(false);
      setIsCongrats(false);
  

      // Clear the timeout when the modal is closed
      if (instantPriceTimeoutRef.current) {
        clearTimeout(instantPriceTimeoutRef.current);
      }
    };
  


 

   useEffect(() => {
       getMarketStatus().then((data) => {
           setMarketStatus(data);
       });
   }, []);

    const fetchStockData = async () => {
        setChangeAmount(null);
        setPeriodHigh(null);
        setPeriodLow(null);
        setMainStockDataFetched(false);
        setLiveChangeAmount(null);
        setLiveChangePercent(null);
        setLivePrice(null);
        try {
            let fromDateUnixMs;
            let multiplier, timespan;
            let referencePrice;
            let toDateUnixMs = getUnixTimestamp();
   
            if (filter === 'all time') {
                // For all-time filter, use the date of the IPO or earliest available stock data
                fromDateUnixMs = dateIPO || getESTDate();
                const earliestAvailableDateStr = '2003-08-01'; // Earliest available date for stock data
                if (new Date(fromDateUnixMs) < new Date(earliestAvailableDateStr)) {
                    fromDateUnixMs = earliestAvailableDateStr;
                }
            } else if (filter === '3m') {
                fromDateUnixMs = getThreeMonthsAgo();
            } else if (filter === '1d') {
                const dateInET = getStartOfTradingDay();
                fromDateUnixMs = dateInET.toMillis();
                toDateUnixMs = fromDateUnixMs + 57600000; 
                referencePrice = rc(dailyChangeData?.prevClosePrice);
            } else if (filter === '1w') {
                // Get the current date/time in ET
                const dateInET = getStartOfTradingWeek();
                fromDateUnixMs = dateInET.toMillis();
                toDateUnixMs = fromDateUnixMs + 403200000; 
            } else {
                // Other filters: use standard logic
                fromDateUnixMs = getUnixTimestampRange(filter);
            }
   
            const intervalArray = intervalMap[filter];
            multiplier = intervalArray[0];
            timespan = intervalArray[1];
           
            if (filter === 'all time' || filter === '3m') {
                toDateUnixMs = getESTDate(); //use YYYY-MM-DD format for all time and 3m filters
            }
 
            const response = await axios.get(`${config.backendUrl}/api/polygon/stock/datapoints`, {
                params: {
                    ticker,
                    fromDateUnixMs,
                    toDateUnixMs,
                    multiplier,
                    timespan
                }
            });
            const data = response.data || [];
            setStockData(data);
   
            if (data.length > 0) {
                const latestPrice =  rc(livePrice || currentPrice || data[data.length - 1].c);
                if (referencePrice) {
                    const change = latestPrice - referencePrice;
                    const changePercentage = (change / referencePrice) * 100;
                    if (filter === '1d') { 
                        let priceChange =
                        ((dailyChangeData?.todaysClosePrice ? rc(dailyChangeData.todaysClosePrice ): rc(livePrice ||  currentPrice))
                        - rc(dailyChangeData?.prevClosePrice));
                        setChangeAmount(priceChange.toFixed(2));
                        setChangePercent(dailyChangeData.todaysPercentChange.toFixed(2));
                    } else {
                         setChangeAmount(change.toFixed(2));
                         setChangePercent(changePercentage.toFixed(2));

                    }
                } else {
                    // Fallback if referencePrice is not available

                    const startPrice = rc(data[0].c);
                    const change = latestPrice - startPrice;
                    const changePercentage = (change / startPrice) * 100;

                    setChangeAmount(change.toFixed(2));
                    setChangePercent(changePercentage.toFixed(2));
                }
   
                const highPrices = data.map(day => day.h);
                const highestPrice = Math.max(...highPrices);
                const lowPrices = data.map(day => day.l);
                setPeriodHigh(highestPrice);
                setPeriodLow(Math.min(...lowPrices));

                setReferencePriceForChart((referencePrice || data[0].c));
            } else {
                setChangeAmount(null);
                setChangePercent(null);
                setPeriodHigh(null);
                setPeriodLow(null);
            }
        } catch (error) {
            console.error('Error fetching stock data:', error);
        }
        setMainStockDataFetched(true);
    };

     useEffect(()=> {
       getDailyChange(ticker, setCurrentPrice, setDailyChangeData);
       // eslint-disable-next-line
     }, [ticker]);

     useEffect(() => {
       if (marketStatus) {
           getLastValidTradingDay(marketStatus)
               .then((tradingDay) => {
                   setLastValidTradingDay(tradingDay);
               })
               .catch((error) => {
                   console.error("Error fetching the last valid trading day:", error);
               });
       }
   }, [marketStatus]);

   const setCompanyStates = (companyInfo) => { 
    setDateIPO(companyInfo.listDate); // Store IPO date
    setStockName(companyInfo.name || ticker); // Replace stockName with company name or fallback to ticker
    setCompanyDetails({
        marketCap: companyInfo.marketCap,
    });
    if (companyInfo.description === 'No description available.' || !companyInfo.description) {
      return;
    }
    setCompanyDescription(companyInfo.description || '');
  }




   useEffect(()=> {
       fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, ticker);
       // eslint-disable-next-line
   }, [userToken, ticker])





   const handleOptionClick = (optionTicker) => {
    navigate(`/options/${optionTicker}`, 
      {state: {
        underlyingTicker: ticker,
        companyName: stockName,
      }}
    );
  };


   // company details -> stock data -> stock statistics needs to be fetched in order
   useEffect(() => {
      setCompanyDescription('');
       fetchCompanyDetails(ticker, setCompanyStates, setCompanyDetailsFetched);
       // eslint-disable-next-line
   }, [ticker]);



   useEffect(() => {
       if (!dailyChangeData || !filter ) {
           return;
       }
       fetchStockData();
       // eslint-disable-next-line
   }, [filter, dailyChangeData]);


   useEffect(() => {
       if (!mainStockDataFetched) {
           return;
       }
       fetchDividendYield(ticker, currentPrice, setDividendYield);
       fetchEPSAndPERatio(ticker, setEps, setPERatio);
      
  //eslint-disable-next-line
   }, [ticker, mainStockDataFetched]);

const buyOrSellAssetCallback =()=> {
  setIsCongrats(true);
  fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio);
  fetchAllTransactions(isAuthenticated, userToken, ticker, setAllTransactions);
}


   useEffect(() => {
       if (lastValidTradingDay) {
           fetchDailyOpenClose(lastValidTradingDay, ticker, setDailyData);
       }
       //eslint-disable-next-line
   }, [lastValidTradingDay, ticker]);

   useEffect(() => {
    if (!chartRef?.current) return;
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });
    // Cleanup on component unmount
    return cleanup;
  }, [stockData, referencePriceForChart, changeAmount, chartRef, theme, openBuyModal, openSellModal, openTradeOptionsModal]);




  const renderConfirmationModal = () => {
    const isBuy = openBuyModal;
  
    // Handle confirmation
    const handleConfirm = async () => {
      try {
        let response;
        if (isBuy) {
         response =  await handleBuyAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            livePrice || currentPrice,
            'stock',
            buyOrSellAssetCallback
          );

        } else {
          response = await handleSellAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            livePrice || currentPrice,
            'stock',
            buyOrSellAssetCallback
          );
        }
       
      } catch (error) {
        console.error("Transaction failed:", error);
        alert(error.response?.data?.message || 'Transaction failed');
      }
    };
  
    // Calculate total cost or proceeds
    const totalCost = quantity * rc(livePrice || currentPrice);

    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : userTickerPosition?.quantity - quantity; // Adjust shares for sell
  
    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
        <h2 className="StockName bold">Confirm</h2>
        <div className="StockConfirmWrapper">
          <span>
            <span className="bold">{quantity}</span> {ticker} shares
          </span>
          <span>
            <span className="bold">${fc(rc(livePrice || currentPrice))}</span> share price
          </span>
        </div>
        <div className="StockConfirmWrapper" style={{ border: 'none' }}>
          <span>
            <span className="bold">${fc(totalCost)}</span> total
          </span>
          <span>
            <span className="bold">
              {isBuy
                ? <> ${fc(remainingAmount)} <span className='medium'> cash remaining </span></>
                :<> {fc(remainingAmount)}  <span className='medium'> shares remaining </span></>
                
                }
            </span>
          </span>
        </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isCongrats && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0}
        >
          Confirm
        </button>
      </div>
    );
  };
  const currentPriceRef = useRef(null);
  useEffect(() => {
    // Scroll into view once the options data is loaded and we have optionsData.length > 0
    if (optionsData.length > 0 && currentPriceRef.current) {
      currentPriceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [optionsData]); 

  const [lineWidth, setLineWidth] = useState(0);

  useEffect(() => {
    if (optionsData.length > 0 && currentPriceRef.current) {
      const currentPriceTd = currentPriceRef.current;
      const currentPriceWrapper = currentPriceTd.querySelector('.current-price-wrapper');

      if (currentPriceWrapper) {
        const tdWidth = currentPriceTd.offsetWidth;
        const wrapperWidth = currentPriceWrapper.offsetWidth;
        const calculatedWidth = (tdWidth - wrapperWidth) / 2;

        setLineWidth(calculatedWidth); // Update state with the calculated width
      }
    }
  }, [optionsData]);
  const renderOptionsModal = () => {  
  

    return (
      <>
        <div className="options-filters">
          <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
            <div className="option-type-container">
              <div className="se-toggle-container" style={{ padding: 0, height: '3rem', border: 'var(--border)' }}>
                <div
                  className={`se-toggle-option stock-toggle bold ${optionType === 'call' && 'active'}`}
                  onClick={() => handleOptionTypeChange('call')}
                >
                  Call
                </div>
                <div
                  className={`se-toggle-option stock-toggle bold ${optionType === 'put' && 'active'}`}
                  onClick={() => handleOptionTypeChange('put')}
                >
                  Put
                </div>
              </div>
            </div>
            <div className="option-type-container">
     <DatePicker
            selected={expirationDate}
            onChange={handleExpirationDateChange}
            minDate={earliestDate}                   // Optional minimum date
            onMonthChange={handleMonthChange}         // Triggered when the user navigates to a new month
            filterDate={(date) =>                     // Only allow specific dates
              availableDates.some(d => d.toDateString() === date.toDateString())
            }
            dropdownMode="select"                     // Optional: dropdowns as select menus
            customInput={<CustomDateInput pretext="Expiring" theme={tc()} />}
            dateFormat="MMMM d, yyyy"
            className="FeedSortButton"
          />
            </div>
          </div>
        </div>
  
        <div className="options-list">
          {loadingOptions ? (
            <LoadingSpinner />
          ) : optionsData.length > 0 ? (
            <table className="options-table">
              <thead>
                <tr className="options-table-heading">
                  <th>Strike Price</th>
                  <th>Breakeven</th>
                  <th>To breakeven</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>Open</th>
                  <th>High</th>
                  <th>Low</th>
                  <th>Close</th>
                  <th>1d Change %</th>
                </tr>
              </thead>
              <tbody>
                {optionsData.map((option, index) => (
                  <>
                    {index > 0 && currentPrice < option.strikePrice && currentPrice >= optionsData[index - 1].strikePrice && (
                     <tr className="current-price-row" ref={currentPriceRef} >
                     <td colSpan="10" className="current-price-td">
                     <div
                          className="current-price-wrapper"
                          style={{
                            "--line-width": `${lineWidth}px` // Set the line width as a CSS variable
                          }}
                        >
                         <span className="current-price-text bold">Current share price: ${fc(rc(livePrice || currentPrice))}</span>
                       </div>
                     </td>
                   </tr>
                    )}
                    <tr style = {{cursor: 'pointer'}}key={index} onClick={() => handleOptionClick(option.optionTicker)} >
                      <td className="bold">{option?.strikePrice !== undefined ? fc(option.strikePrice) : '--'}</td>
                      <td>{option.breakEvenPrice !== undefined ? fc(option.breakEvenPrice) : '--'}</td>
                      <td>
                    {option.changeToBreakEven !== undefined
                      ? option.changeToBreakEven > 0
                        ? `+${option.changeToBreakEven} (${option.changeToBreakEven > 0 ? '+' : ''}${fc(
                            calculatePercentageChange(
                              rc(livePrice || currentPrice),
                              option.breakEvenPrice
                            )
                          )}%)`
                        : `${option.changeToBreakEven} (${fc(
                            calculatePercentageChange(
                              rc(livePrice || currentPrice),
                              option.breakEvenPrice
                            )
                          )}%)`
                      : '--'}
                  </td>
                                    
                      <td>{option.bid !== undefined ? fc(option.bid) : '--'}</td>
                      <td>{option.ask !== undefined ? fc(option.ask) : '--'}</td>
                      <td>{option.dailyChangeData?.open !== undefined ? fc(option.dailyChangeData.open) : '--'}</td>
                      <td>{option.dailyChangeData?.high !== undefined ? fc(option.dailyChangeData.high) : '--'}</td>
                      <td>{option.dailyChangeData?.low !== undefined ? fc(option.dailyChangeData.low) : '--'}</td>
                      <td>{option.dailyChangeData?.close !== undefined ? fc(option.dailyChangeData.close) : '--'}</td>
                      <td
                      className='bold'
                        style={{
                          color: option.dailyChangeData?.change_percent !== undefined
                            ? getChangePercentageColor(option.dailyChangeData?.change_percent, tc())
                            : 'inherit'
                        }}
                      >
                       
                       {option.dailyChangeData?.change_percent !== undefined
                  ? `${option.dailyChangeData.change_percent >= 0 ? '+' : ''}${fc(option.dailyChangeData.change_percent)}%`
                  : '--'}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ fontSize: 'var(--font-med-l)' }}>No options available for this date.</p>
          )}
        </div>
      </>
    );
  };


  const renderBuyOrSellModal = () => {
  
    // Handle changes in the shares input
    const handleQuantityChange = (event) => {

      setIsConfirming(false);
      setIsCongrats(false);
     
      const value = event.target.value;
      const newQuantity = parseFloat(value);
  
      // Prevent negative values
      if (newQuantity < 0) {
        setQuantity(null);
        setEstimatedCost(null);
        return;
      }
  
      if (isNaN(newQuantity) || value === '') {
        setQuantity(null);
        setEstimatedCost(null);
      } else {
        setQuantity(newQuantity);
  
        // Calculate estimated cost as a number
        const price = rc(livePrice || currentPrice);
        const newEstimatedCost = newQuantity * price;
        setEstimatedCost(newEstimatedCost);
      }
    };
  
    // Determine if modal is for Buy or Sell
    const isBuy = openBuyModal;
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`;
    let buttonLabel = isBuy ? 'Buy' : 'Sell';
    let originalButtonLabel = buttonLabel;
    buttonLabel = isConfirming ? 'Edit' : buttonLabel;
  
   
          const handleConfirmClick = () => {  
            if (isConfirming) {
              setIsCongrats(false);
              document.getElementById('share-input').focus();
            }
            setIsConfirming((prev) => !prev);
          }


  
    return (
      <div style = {{display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center'}}>


      <div className="stock-purchase-modal">
        <div className='stock-transaction-content'>
        <h2 className="StockName bold">{originalButtonLabel}</h2>
        <p className="stock-cash-available">{availableText}</p>
  
        <div className="stock-input-section">
          <label htmlFor="shares" className="StockName bold">
            Shares
          </label>
          <input
            type="number"
            id = "share-input"
            className="StockName StockInput bold"
            value={quantity !== null ? quantity : ''}
            onChange={handleQuantityChange}
            inputMode="decimal"
            step="any"
            min="0"
            placeholder="Enter shares"
          />
        </div>
  
        <div className="stock-input-section" style={{ borderBottom: 'none' }}>
          <label className="StockName bold">Total</label>
          <input
            type="text"
            className="StockName StockInput bold"
            value={
              estimatedCost !== null ? `$${fc(estimatedCost)}` : ''
            }
            readOnly
          />
        </div>
        </div>
  
        <button
          onClick={handleConfirmClick}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0}
        >
          {buttonLabel}
        </button>
      </div>
{isConfirming &&
      <><div>
           <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
          </div>
          
         {renderConfirmationModal()}
            
            </>
      
      }

  {isCongrats && 
        <><div>
        <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }} />
       </div>
       
       {renderCongratsModal()}
         
         </>
   
  }
      </div>
    );
  };

  const renderCongratsModal = () => {

    return (
      <div className="stock-purchase-modal" style = {{borderColor: 'var(--stock-change-pos)'}}>
        <div className="stock-transaction-content">
      <h2 className="StockName bold">Congrats!</h2>
      <div className="StockConfirmWrapper" style = {{border: 'none'}}>
        <span>
          Your transaction was successful.
        </span>
      </div>
      </div>
      <div className='stock-congrats-btns'>
      <button
      onClick={()=> handleCloseModal()}
        style={{ width: 'fit-content', alignSelf: 'flex-end', marginTop: '1rem' }}
        className="CreatePostFeedButton greenButton"
      >
        Done
      </button>
      <button
      onClick={()=> navigate(`/portfolio/${username}`)}
        style={{ width: 'fit-content', alignSelf: 'flex-end', marginTop: '1rem' }}
        className="CreatePostFeedButton greenButton"
      >
        View portfolio
      </button>
      </div>
    </div>
    )




  };




// WebSocket connection for real-time updates
useEffect(() => {
  if (!referencePriceForChart || !chartRef.current) {
    return;
  }

  const ws = new WebSocket(config.socketUrl);

  ws.onopen = () => {
    console.log('Connected to WebSocket server');
    ws.send(JSON.stringify({ action: 'subscribe', ticker }));
  };

  ws.onmessage = (event) => {
    let message;
    try {
      message = JSON.parse(event.data);  // Ensure message is parsed correctly
    } catch (e) {
      console.error('Error parsing WebSocket message:', e);
      return;
    }

    if (message.type === 'instantPrice') {
      const data = message.data[0];
      if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
        const tradePrice = data.p;
        updateChanges(tradePrice); // Ensure updateChanges is a valid function
      }

      const dataBidAsk = message.data[1];
      if (dataBidAsk && dataBidAsk.ev === 'Q' && dataBidAsk.sym === ticker.toUpperCase()) {
        const bidPrice = dataBidAsk.bp;
        const askPrice = dataBidAsk.ap;
        setBidAsk({ bid: bidPrice, ask: askPrice });
      }

    } else {
      let data = message[0];  // Make sure data is an array
      if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
        const tradePrice = data.p; // New price from WebSocket
        const tradeTime = data.t; // New timestamp from WebSocket
      
        if (!tradePrice || !tradeTime) {
          console.warn('Invalid trade data:', data);
          return;
        }
      
        // Update chart by adding new data point
        const newPoint = {
          t: tradeTime, // Unix timestamp from WebSocket
          c: parseFloat(tradePrice).toFixed(2), // Price from WebSocket
        };
      
        // Add new label and data point to the chart
        if (chartInstance.current) {
          // Optional: limit the number of data points on the chart
          const maxDataPoints = 287; 
      
          // Remove the first point if the limit is reached
          if (chartInstance.current.data.labels.length >= maxDataPoints && filter === 'live') {
            chartInstance.current.data.labels.shift();
            chartInstance.current.data.datasets[0].data.shift();
          }
      
          chartInstance.current.data.labels.push(
            new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }).format(new Date(newPoint.t))
          );
      
          chartInstance.current.data.datasets[0].data.push(newPoint.c);
      
          // Calculate change from reference price
          const change = newPoint.c - referencePriceForChart;
      
          // Dynamically update chart colors based on liveChangeAmount
          const borderColor = getChangePercentageColor(change, tc());
          const pointBackgroundColor = getChangePercentageColor(change, tc());
      
          // Update chart dataset colors
          chartInstance.current.data.datasets[0].borderColor = borderColor;
          chartInstance.current.data.datasets[0].pointBackgroundColor = pointBackgroundColor;
      
          // Update the chart only if the chart has valid data
          if (chartRef.current && chartInstance.current.data.datasets[0].data.length > 0) {
            try {
              chartInstance.current.update({
                duration: 100, // Set animation duration
                lazy: true,
                easing: 'easeInOutBounce', // Use a custom easing function
              });
            } catch (updateError) {
              console.error('Chart update failed:', updateError);
            }
          }
        }
      }
    
    }
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  wsRef.current = ws;

  return () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
      wsRef.current.close();
    }
  };
  //dont include currentPrice/liveprice in the dependencies array because that will cause a re-render loop 
  //eslint-disable-next-line
}, [ticker, filter, dailyChangeData, referencePriceForChart, liveChangeAmount]);

const renderStockNews = () => {
  if (stockNews.length === 0) {
    return <p>No news available</p>;
  }

  return stockNews.map((news, index) => (
    <div key={index} className="StockNewsItem">
      <a style = {{textDecoration: 'none'}} href={news.article_url} target="_blank" rel="noopener noreferrer">
        <img src={news.image_url} alt={news.title} className="StockNewsImage" />
        <h2 className="StockNewsTitle">{news.title}</h2>
      </a>
      <div className="StockNewsMeta">
        <span className="StockNewsAuthor">{news.publisher.name}</span>
        <span className="StockNewsDate">{new Date(news.published_utc).toLocaleDateString()}</span>
      </div>
    </div>
  ));
};

   return (
       <div id ="stock-container-wrapper" className='StockContainerWrapper'>
           <div className="StockContainer">
            <MarketSearchBar
              stock={stock}
              setStock={setStock}
              isAuthenticated={isAuthenticated}
              userToken={userToken}
              navigate={navigate}
            />

               <div className="MainStockContainer">
                   <div className="StockPriceContent">
                       <div className="StockGraph">
                      
                           <div className="StockHeader">
                            
                               <div className="StockInfo">
                                <BackButton callback={()=> {window.history.back()}} fontSize='var(--font-med-l)' padding='0 0 1.25rem 0'/>
                               <h2 className="StockTickerHeader bold">{ticker}</h2>
                                   <h1 className="StockName bold">{stockName}</h1>
                                   <p className="StockPrice bold">
                    ${isHovering ? fc(hoveredPrice) : fc(rc(livePrice || currentPrice))}
                  </p>
                  <p
                    className={`StockChange bold ${
                      isHovering
                        ? hoveredChangeAmount >= 0
                          ? 'positive'
                          : 'negative'
                        : (liveChangeAmount || changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0.00 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : (liveChangeAmount || changeAmount) !== null ? (
                      <>
                        {(liveChangeAmount || changeAmount) >= 0.00 ? '+' : ''}
                        {fc(liveChangeAmount || changeAmount)} ({fc(liveChangePercent || changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}{' '}
                    <span className="StockDuration">
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                          ? (
                            <>
                              {filters[filter]} {isOldCompany(dateIPO) && '(since 8/30/2003)'}
                            </>
                          )
                          : filters[filter] === 'live' ? 'live' :`past ${filters[filter]}`
                      }
                    </span>
                  </p>
                          
                                   {!isHovering && renderAfterMarketChange(marketStatus, dailyChangeData, filter, livePrice || currentPrice)}
                               

                             
                    { (!openBuyModal && !openSellModal && !openTradeOptionsModal) ?              <div className="StockActions">
                                   <button onClick= {handleBuyClick} className="CreatePostFeedButton greenButton">Buy</button> 
                                   <button onClick = {handleSellClick} className="CreatePostFeedButton">Sell</button> 
                                    <button onClick = {handleTradeOptionsClick} className="CreatePostFeedButton">Trade Options</button>
                               

                                     <button className="CreatePostFeedButton" onClick = {
                                          isInWatchlist ? 
                                          () => handleRemoveFromWatchlist(ticker, userToken, setIsInWatchlist) :  
                                          () => handleAddToWatchlist(ticker, userToken, setIsInWatchlist)
                                     }>
                                          {isInWatchlist ? 'Watchlist -' : 'Watchlist + '}
                                     </button>

                                   </div>
                                   :
                                   <div className="StockActions">
                                   <button onClick= {handleCloseModal} className="CreatePostFeedButton">Cancel Order</button> 
                                   </div>
                                  }
                               </div>
                           </div>
                           <div className="StockGraphMain">

                           {!mainStockDataFetched ? (
                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: 'var(--stock-chart-height)' }}>
                           <LoadingSpinner />
                       </div>
                   ) : (
                    !openBuyModal && !openSellModal && !openTradeOptionsModal ?
                    <div style={{ height: 'var(--stock-chart-height)'}}>
                       <canvas id="stock-chart" ref={chartRef}></canvas>
                       </div>

                       :
                    openBuyModal || openSellModal ?
                       renderBuyOrSellModal()

                       : openTradeOptionsModal  && 
                       renderOptionsModal()
                    
                   )}
                               {!openBuyModal && !openSellModal && <div className="StockFilters">
                                   {Object.keys(filters).map((key) => (
                                       <button key={key} className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`} onClick={() => setFilter(key)}>
                                           {key}
                                       </button>
                                   ))}
                               </div>}

                                         {/*Borrowing class names */}
                                         {allTransactions?.length > 0 && (
  <div className="StockStatistics">
    <h2 className="StatsHeader">Your Position</h2>
    <div className="StatsGrid">
      <div className="label-value-pair">
        <span>Shares</span>
        <strong>{fc(userTickerPosition?.quantity)}</strong>
      </div>
      <div className="label-value-pair">
        <span>Market Value</span>
        <strong>{fc(userTickerPosition?.quantity * rc(livePrice || currentPrice))}</strong>
      </div>
      <div className="label-value-pair">
        <span>Portfolio Allocation</span>
        <strong>
          {fc(userPortfolio?.totalValue && userPortfolio?.totalValue !== 0
            ? ((userTickerPosition?.quantity * userTickerPosition?.avgBuyPrice) /
                userPortfolio.totalValue) *
              100
            : 0.0)}
          %
        </strong>
      </div>
      <div className="label-value-pair">
        <span>Average Cost</span>
        <strong>{fc(userTickerPosition?.avgBuyPrice)}</strong>
      </div>
      <div className="label-value-pair">
        <span>Unrealized Return</span>
        <strong
          className={
            returnData?.unrealizedDollar >= 0
              ? 'StockChange bold positive'
              : 'StockChange bold negative'
          }
        >
          {returnData ? (
            <>
              {returnData.unrealizedDollar >= 0 ? '+' : ''}
              {fc(returnData.unrealizedDollar)} ({fc(returnData.unrealizedPercentage, 2, true)}%)
            </>
          ) : (
            '--'
          )}
        </strong>
      </div>
      <div className="label-value-pair">
        <span>Realized Return</span>
        <strong
          className={
            returnData?.realizedDollar >= 0
              ? 'StockChange bold positive'
              : 'StockChange bold negative'
          }
        >
          {returnData ? (
            <>
              {returnData.realizedDollar >= 0 ? '+' : ''}
              {fc(returnData.realizedDollar)} ({fc(returnData.realizedPercentage, 2, true)}%)
            </>
          ) : (
            '--'
          )}
        </strong>
      </div>
    </div>
  </div>
)}
                                        


                            {companyDescription &&   <div className="StockDescription">
                                   <h2 className="StockDescriptionHeader">Summary</h2>
                                   <p style = {{margin:0}}>{companyDescription}</p>
                               </div>}
                               <div className="StockStatistics">
                              <h2 className="StatsHeader">Stats</h2>
                              <div className="StatsGrid">
                                <div className="label-value-pair">
                                  <span>Open</span>
                                  <strong>{fc(dailyData?.open) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Close</span>
                                  <strong>{fc(dailyData?.close) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>High</span>
                                  <strong>{fc(dailyData?.high) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Low</span>
                                  <strong>{fc(dailyData?.low) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Pre-Market</span>
                                  <strong>{fc(dailyData?.preMarket) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>After-Hours</span>
                                  <strong>{fc(dailyData?.afterHours) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Volume</span>
                                  <strong>{dailyData?.volume ? formatNumber(dailyData.volume) : '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>P/E Ratio</span>
                                  <strong>{fc(peRatio) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span style={{ textTransform: 'capitalize' }}>
                                    {filterHighMap[filter]} High
                                  </span>
                                  <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span style={{ textTransform: 'capitalize' }}>
                                    {filterHighMap[filter]} Low
                                  </span>
                                  <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>EPS (TTM)</span>
                                  <strong>{fc(eps) || '--'}</strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Dividend Yield %</span>
                                  <strong>
                                    {dividendYield}
                                    {dividendYield !== 'N/A' ? '%' : ''}
                                  </strong>
                                </div>
                                <div className="label-value-pair">
                                  <span>Market Cap</span>
                                  <strong>
                                    {companyDetails?.marketCap
                                      ? formatNumber(companyDetails.marketCap)
                                      : '--'}
                                  </strong>
                                </div>
                              </div>
                            </div>
                                                        
                            { stockNews?.length > 0 &&   <div className="StockNews">
                                <h2 className='StockNewsHeader'> News</h2>
                            <div className="StockNewsList">
                                   {renderStockNews()}
                                </div>

                                </div>
                              }

                                      

                           </div>
                       </div>
                   </div>
               </div>


           </div>



       </div>

   );

}