import React, { useState } from 'react';
import {  fetchTickerSuggestions, handleSearchStock, handleSuggestionClick } from '../helper/MarketsHelper';

export default function MarketSearchBar({ stock, setStock, isAuthenticated, userToken, navigate }) {
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setStock(query);
    debounceFetchSuggestions(query);
  }; 
  
  // Debounced function to fetch suggestions
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debounceFetchSuggestions = (query) => debounce(fetchTickerSuggestions(query, setSuggestions), 500);

  return (
    <div className="MarketsSearchWrapper">
      <input
        id="marketsSearchBar"
        className="MarketsSearchBar bold"
        type="text"
        placeholder="Search markets"
        value={stock}
        onChange={handleInputChange}
        onKeyDown={(e) => {handleSearchStock(e, stock, isAuthenticated, userToken, navigate); if (e.key === 'Enter') setStock('');}}
      />
      {suggestions.length > 0 && stock && (
        <ul className="SuggestionsDropdown">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion?.ticker}
              onClick={() =>{ handleSuggestionClick(suggestion?.ticker, isAuthenticated, userToken, navigate); setStock('');}}
              className="SuggestionItem"
            >
              {suggestion?.ticker} - {suggestion?.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}