import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import '../styles/Markets.css';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import { getChangeColor, isOption } from '../helper/MarketsHelper';
import Select from 'react-select'; // Import react-select
import { customStyles, LoadingSpinner, formatOptionNameFromTicker, formatCurrency as fc } from '../helper/StylesHelper';
import MarketSearchBar from './MarketSearchBar';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
/*
Pre-market is 4 AM EST - 9:30 AM EST
Regular hours is 9:30AM - 4PM EST
After-hours is 4 PM EST - 8 PM EST
*/

export default function Markets() {
  useResponsiveWidth();
  const [stock, setStock] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [stockLists, setStockLists] = useState([]);
  const [popularStocks, setPopularStocks] = useState([]);

  //eslint-disable-next-line
  const [showPopover, setShowPopover] = useState(false);
  const [newListName, setNewListName] = useState('');
  const navigate = useNavigate();
  const { userToken, isAuthenticated } = useContext(AuthContext);
  const { tc } = useContext(ThemeContext);

  // Added state variables for sort options
  const [popularStocksSortOption, setPopularStocksSortOption] = useState('change');
  const [recentSearchesSortOption, setRecentSearchesSortOption] = useState('change');
  const [stockListSortOptions, setStockListSortOptions] = useState({});
  const [popularStocksLoading, setPopularStocksLoading] = useState(true);
  const [recentSearchesLoading, setRecentSearchesLoading] = useState(true);
  const [stockListsLoading, setStockListsLoading] = useState(true);


  const [gainersSortOption, setGainersSortOption] = useState('change');
  const [losersSortOption, setLosersSortOption] = useState('change');
  const [gainersLoading, setGainersLoading] = useState(true);
  const [losersLoading, setLosersLoading] = useState(true);

  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);

  // Options for the react-select component
  const sortOptions = [
    { value: 'change', label: '% Change' },
    { value: 'price', label: 'Price' },
    { value: 'alphabetical', label: 'Alphabetical' },
  ];

  const renderStockCard = (stock) => {
    let changeColor =  getChangeColor(stock?.todaysPercentChange, tc());
    return (
      <div
        className="MarketsStockCard"
        style={{ backgroundColor: changeColor, border: `0.05rem solid ${changeColor}`}}
        onClick={() => handleNavigateToAsset(stock?.ticker)}
      >
        <div className="MarketsStockCardHeading">
          <span className="MarketTicker">{formatOptionNameFromTicker(stock?.ticker)}</span>
          <span className="MarketName bold">
  {stock?.companyName?.length > 50 ? `${stock.companyName.slice(0, 50)}...` : stock.companyName}
</span>
        </div>

        <div className="MarketsStockCardFooting">
          <span className="MarketItemPrice">
            $
            {stock?.todaysClosePrice > 0
              ? fc(stock?.todaysClosePrice)
              : fc(stock?.currentPrice)}
          </span>
          <span
            className={`MarketItemPrice marginZeroFive bold ${
              stock?.todaysPercentChange >= 0 ? 'ma-positive' : 'ma-negative'
            }` }
          >
            {stock?.todaysPercentChange >= 0 ? '+' : ''}
            {fc(stock?.todaysPercentChange)}%
          </span>
        </div>
      </div>
    );
  };
  



  const fetchGainersAndLosers = async () => {
    setGainersLoading(true);
    setLosersLoading(true);
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/gainerslosers`);
      setGainers(response.data.gainers);
      setLosers(response.data.losers);
    } catch (error) {
      console.error('Error fetching gainers and losers:', error);
    }
    setGainersLoading(false);
    setLosersLoading(false);
  };



  // Fetch popular stocks
  const fetchPopularStocks = async () => {
    setPopularStocksLoading(true);

    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/popularstocks`);
      setPopularStocks(response.data);
    } catch (error) {
      console.error('Error fetching popular stocks data:', error);
    }

    setPopularStocksLoading(false);
  };

  // Fetch recent searches
  const fetchRecentSearches = async () => {
    setRecentSearchesLoading(true);
    if (!isAuthenticated) {
      setRecentSearchesLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/users/recentsearches`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setRecentSearches(response.data);
    } catch (error) {
      console.error('Error fetching recent searches:', error);
    }
    setRecentSearchesLoading(false);
  };

  // Fetch stock lists
  const fetchStockLists = async () => {
    setStockListsLoading(true);
    if (!isAuthenticated) {
      setStockListsLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/stocklists`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setStockLists(response.data);
    } catch (error) {
      console.error('Error fetching stock lists:', error);
    }
    setStockListsLoading(false);
  };

 


  const handleNavigateToAsset = (ticker) => {
    if (isOption(ticker)) {
      navigate(`/options/${ticker}`);
    } else {
      navigate(`/stocks/${ticker}`);
    }
  };


  //eslint-disable-next-line
  const handleCreateList = async () => {
    if (!newListName.trim()) {
      alert('Please enter a list name');
      return;
    }
    if (newListName.trim()) {
      try {
        const response = await axios.post(
          `${config.backendUrl}/api/polygon/stocklists`,
          { name: newListName },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );
        setStockLists([...stockLists, response.data]);
        setNewListName('');
        setShowPopover(false);
      } catch (error) {
        console.error('Error creating new stock list:', error);
      }
    }
  };

  //eslint-disable-next-line
  const handleDeleteList = async (listId) => {
    if (!window.confirm('Are you sure you want to delete this stock list?')) {
      return;
    }

    try {
      await axios.delete(`${config.backendUrl}/api/polygon/stocklists/${listId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setStockLists((prev) => prev.filter((list) => list?._id !== listId));
    } catch (error) {
      console.error('Error deleting stock list:', error);
    }
  };

  // Handle sort option change for user stock lists
  const handleStockListSortOptionChange = (listId, selectedOption) => {
    setStockListSortOptions((prev) => ({
      ...prev,
      [listId]: selectedOption.value,
    }));
  };

  // Sorting function for stocks
  const sortStocks = (stocks, sortOption) => {
    const stocksCopy = [...stocks]; // Avoid mutating the original array
    switch (sortOption) {
      case 'alphabetical':
        return stocksCopy.sort((a, b) => a.ticker.localeCompare(b.ticker));
      case 'price':
        return stocksCopy.sort((a, b) => b.currentPrice - a.currentPrice); // Descending order
      case 'change':
        return stocksCopy.sort((a, b) => b.todaysPercentChange - a.todaysPercentChange); // Descending order
      default:
        return stocksCopy;
    }
  };

  useEffect(() => {
    fetchPopularStocks();
    fetchRecentSearches();
    fetchStockLists();
    fetchGainersAndLosers();
    //eslint-disable-next-line
  }, []);



  const renderStockLists = () => {
    if (stockListsLoading) {
      return <LoadingSpinner />;
    }
    if (stockLists.length === 0) {
      return null; // No stock lists available
    }

    return stockLists.map((list) => {
      const sortOption = stockListSortOptions[list?._id] || 'change';
      const sortedStocks = sortStocks(list?.stocks || [], sortOption);

      return (
        <div key={list?._id} className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">{list?.name}</h3>
            <div className="ListActionButtons">
              {/* Added react-select dropdown for sorting */}
              <Select
                className="SortSelect"
                value={sortOptions.find((option) => option.value === sortOption)}
                onChange={(selectedOption) => handleStockListSortOptionChange(list?._id, selectedOption)}
                options={sortOptions}
                isSearchable={false}
                styles={customStyles(tc())}
                unstyled
                title="Sort stocks"
              />
            </div>
          </div>
          {sortedStocks.length === 0 ? (
                <div
                  className="MarketsStockCard MarketsStockCardEmpty"
                  onClick={() => {
                    alert('Please search for an asset you wish to add.'); 
                    document.getElementById('marketsSearchBar').focus()}}
                  style={{ backgroundColor: 'transparent', border: 'var(--border)' }}
                >
                  <span className='MarketsStockCardEmptyText'> Add first stock + </span>
                </div>
          ) : (
            <div className="MarketsStockCardsContainer">
              {sortedStocks.map((stock, idx) => (
                <React.Fragment key={idx}>{renderStockCard(stock)}</React.Fragment>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="MarketsContainer">
      {/* Search Bar */}
      <MarketSearchBar
        stock={stock}
        setStock={setStock}
        isAuthenticated={isAuthenticated}
        userToken={userToken}
        navigate={navigate}
      />
      <div className="MarketsListContainer">
            {/* User Stock Lists */}
            {renderStockLists()}

  {/* Recent Searches */}
  {isAuthenticated && (
  <div className="MarketsList">
  <div className="MarketListHeaderWrapper">
  <h3 className="MarketsListHeader">Recent Searches</h3>
  <Select
  className="SortSelect"
  value={sortOptions.find((option) => option.value === recentSearchesSortOption)}
  onChange={(selectedOption) => setRecentSearchesSortOption(selectedOption.value)}
  options={sortOptions}
  isSearchable={false}
  unstyled
  styles={customStyles(tc())}
  title="Sort stocks"
  />
  </div>
  <>
  {recentSearchesLoading && <LoadingSpinner />}

  {recentSearches?.length === 0 && (
  <div className="MarketItem">
    <div className="MarketItemInfo">
      <p className="MarketName bold">No recent searches</p>
    </div>
  </div>
  )}
<div className="MarketsStockCardsContainer">
{sortStocks(recentSearches, recentSearchesSortOption).map((stock, idx) => (
  <React.Fragment key={idx}>{renderStockCard(stock)}</React.Fragment>
))}
</div>
</>
</div>
)}


        <div className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">Popular Stocks</h3>
            {/* Added react-select dropdown for sorting Popular Stocks */}
            <Select
              className="SortSelect"
              value={sortOptions.find((option) => option.value === popularStocksSortOption)}
              onChange={(selectedOption) => setPopularStocksSortOption(selectedOption.value)}
              options={sortOptions}
              isSearchable={false}
              unstyled
              styles={customStyles(tc())}
              title="Sort stocks"
            />
          </div>
          {popularStocksLoading && <LoadingSpinner />}
          <div className="MarketsStockCardsContainer">
            {sortStocks(popularStocks, popularStocksSortOption).map((stock, idx) => (
              <React.Fragment key={idx}>{renderStockCard(stock)}</React.Fragment>
            ))}
          </div>
        </div>

  
     

         {/* Gainers Section */}
         <div className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">Biggest winners</h3>
            <Select
              className="SortSelect"
              value={sortOptions.find((option) => option.value === gainersSortOption)}
              onChange={(selectedOption) => setGainersSortOption(selectedOption.value)}
              options={sortOptions}
              isSearchable={false}
              styles={customStyles(tc())}
              unstyled
              title="Sort gainers"
            />
          </div>
          {gainersLoading && <LoadingSpinner />}
          <div className="MarketsStockCardsContainer">
            {sortStocks(gainers, gainersSortOption).map((stock, idx) => (
              <React.Fragment key={idx}>{renderStockCard(stock)}</React.Fragment>
            ))}
          </div>
        </div>

        {/* Losers Section */}
        <div className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">Biggest losers</h3>
            <Select
              className="SortSelect"
              value={sortOptions.find((option) => option.value === losersSortOption)}
              onChange={(selectedOption) => setLosersSortOption(selectedOption.value)}
              options={sortOptions}
              isSearchable={false}
              styles={customStyles(tc())}
              unstyled
              title="Sort losers"
            />
          </div>
          {losersLoading && <LoadingSpinner />}
          <div className="MarketsStockCardsContainer">
            {sortStocks(losers, losersSortOption).map((stock, idx) => (
              <React.Fragment key={idx}>{renderStockCard(stock)}</React.Fragment>
            ))}
          </div>
        </div>



      </div>


    </div>
  );
}