import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./styles/App.css";
import Header from "./components/Header";
import MrVestr from "./components/MrVestr";
import Profile from "./components/Profile";
import Feed from "./components/Feed";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import { AuthContext } from "./context/AuthContext";
import { ThemeContext } from "./context/ThemeContext";
import CreatePost from "./components/CreatePost";
import Settings from "./components/Settings";
import PostView from "./components/PostView";
import Overview from "./components/Overview";
import VestrPlus from "./components/VestrPlus";
import Markets from "./components/Markets";
import Stock from "./components/Stock";
import Option from "./components/Option";
import News from "./components/News";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PaymentComplete from "./components/PaymentComplete";
import Analytics from "./components/Analytics";
import Portfolio from "./components/Portfolio"; 
import NewsV2 from "./components/NewsV2";
import { LoadingSpinner } from "./helper/StylesHelper";

function App() {
  const [isUserLink, setisUserLink] = useState(false);
  const {theme} = useContext(ThemeContext);

  
  useEffect(() => {
    setisUserLink(window.location.pathname.includes("analytics"));
  }, []);

  useEffect(()=> {
    document.body.className = theme;
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          {!isUserLink && (
            <div className="App">
              <Header />
              <div className="Content">
                <Routes>
                  <Route
                    path="/payment-complete"
                    element={<PaymentComplete />}
                  />
                  <Route path="/news" element={<NewsV2 />} />
                  <Route path="/" element={<Feed />} /> {/* Default route changed from overview*/}
                  <Route path="/vestrplus" element={<VestrPlus />} />
                  <Route path="/feed" element={<Feed />} />
                  <Route path="/post/:postId" element={<PostView />} />
                  <Route path="/mrvestr" element={<MrVestr />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/portfolio/:username" 
                  
                  element={
                    <ProtectedRoute>
                    <Portfolio />
                  </ProtectedRoute>
                    } /> 
                  <Route
                    path="/profile/:username"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/createpost"
                    element={
                      <ProtectedRoute>
                        <CreatePost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/signin"
                    element={
                      <AuthRedirect>
                        <SignIn />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <AuthRedirect>
                        <SignUp />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/markets" element={<Markets />} />
                  <Route path="/stocks/:ticker" element={<Stock />} />
                  <Route path="/options/:optionTicker" element={<Option/>} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          )}
          {isUserLink && (
            <div className="App">
              <div className="Content">
                <Routes>
                  <Route path="/analytics" element={<Analytics />} />
                </Routes>
              </div>
            </div>
          )}
        </Router>
    </LocalizationProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingSpinner />;
  }

  return isAuthenticated ? children : <Navigate to="/signin" />;
};

const AuthRedirect = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <Navigate to="/" /> : children;
};

export default App;
