import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Feed.css';
import Post from './Post';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import config from '../config';
import pfp from '../assets/addUser.png';
import { allowedHTML, formatTimeSince, timeSince } from '../helper/PostHelper';
import Select from 'react-select';
import { customStylesPlusTrans, customStylesTrans} from '../helper/StylesHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { handleQuickPostSaveToDrafts, handleQuickPostSubmit } from '../helper/FeedHelper';
import simplify from '../assets/simplify.png';
import { fetchUser, followUser } from '../helper/ProfileHelper';
import { postTypeOptions, categoryOptions, sentimentOptions, sortOptions, timeOptions } from '../helper/FeedHelper';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import DOMPurify from 'dompurify';
import { LoadingSpinner } from '../helper/StylesHelper';
import { getImageUrl } from '../helper/StylesHelper';
import useResponsiveWidth from '../hooks/useResponsiveWidth';


/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * */


const Feed = () => {

  useResponsiveWidth();

  const [activeTab, setActiveTab] = useState('everything');
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();



  const [loading, setLoading] = useState(true);

  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');

  /** @type {[PostObject[], Function]} */
  const [posts, setPosts] = useState([]);

  /** @type {[PostObject[], Function]} */
  //eslint-disable-next-line
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);
 



  const { userToken, userId, isAuthenticated } = useContext(AuthContext);
  const {tc, dk} = useContext(ThemeContext);  

  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null);


  //Filtering
  const [selectedTime, setSelectedTime] = useState('Today');
  const [selectedSort, setSelectedSort] = useState('Most popular');
  const [selectedPostTypes, setSelectedPostTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);

  const filterOptions = [
    { value: 'time', label: 'Time' },
    { value: 'postType', label: 'Post Type' },
    { value: 'category', label: 'Category' },
    { value: 'sentiment', label: 'Sentiment' },
  ];

  // State to keep track of selected filters to display
  const [selectedFilters, setSelectedFilters] = useState([]);



  //Quick post
  const [quickPostContent, setQuickPostContent] = useState('');

  //eslint-disable-next-line
  const [selectedPostTypesQuick, setSelectedPostTypesQuick] = useState([]);

  //eslint-disable-next-line
  const [selectedCategoriesQuick, setSelectedCategoriesQuick] = useState([]);
  //These are sent to backend when submitting a post
  const [selectedCategoryQuick, setSelectedCategoryQuick] = useState([]);
  const [selectedPostTypeQuick, setSelectedPostTypeQuick] = useState('');


  //Trending accounts and user search
  const [editTrending, setEditTrending] = useState(false);
  const [editTrendingAccounts, setEditTrendingAccounts] = useState([]); //trending accounts while editing
  const [trendingAccounts, setTrendingAccounts] = useState([]); //trending accounts to display
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [trendingAccountsLoading, setTrendingAccountsLoading] = useState(true);
  const [trendingPostsLoading, setTrendingPostsLoading] = useState(true);


  //Pagination
  const viewMoreRef = useRef(null); // Ref for the "View more" button
  const page = useRef(0);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const [showFilters, setShowFilters] = useState(false);
  



  const placeholderUser = {
    _id: null,
    username: 'Placeholder',
    profilePicture: pfp,
    trendingRank: null,
  };

  const ensureArrayLength8 = (accounts) => {
    const filledAccounts = [...accounts];
    for (let i = 0; i < 8; i++) {
      if (!filledAccounts[i]) {
        filledAccounts[i] = { ...placeholderUser, trendingRank: i + 1 }; // Ensure the placeholder has a trendingRank
      }
    }
    return filledAccounts;
  };


  const handleFollowToggle = async (accountId, currentStatus) => {
    try {
      const newStatus = await followUser(userId, accountId, userToken);

      // Update the `trendingAccounts` state with the modified `isFollowing` status
      setTrendingAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account._id === accountId ? { ...account, isFollowing: newStatus } : account
        )
      );
    } catch (error) {
      console.error('Error toggling follow status:', error);
    }
  };

  
  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 0) {
      try {
        
        const response = await axios.get(`${config.backendUrl}/api/auth/search-users`, {
          params: { query },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user); 
    setSearchQuery('');
    setSearchResults([]);
  };

  useEffect(() => {
    if (selectedUser && selectedAccountIndex !== null) {
      handleSubmitEdit();
    }
    //eslint-disable-next-line
  }, [selectedUser]);

  const handleSubmitEdit = () => {
    if (selectedUser && selectedAccountIndex !== null) {
      const userAlreadyExists = editTrendingAccounts.some(account => account._id === selectedUser._id);
      
      if (userAlreadyExists) {
        alert('This user is already in the trending accounts.');
        return;
      }
      
      const updatedAccounts = [...editTrendingAccounts];
      
      // Insert the selected user into the correct index and set the trendingRank
      updatedAccounts[selectedAccountIndex] = {
        ...selectedUser,
        trendingRank: selectedAccountIndex + 1,  // Assign the correct rank based on the placeholder's index
      };
      
      setEditTrendingAccounts(updatedAccounts);
      setAnchorEl(null);  // Close the popover
      setSelectedUser(null);  // Clear the selected user
    }
  };

  
  const handleCancelEdit = () => {
    setEditTrending(false);
    setEditTrendingAccounts(trendingAccounts);
  };

  const handleSaveTrendingAccounts = async () => {
    try {
      await axios.put(`${config.backendUrl}/api/auth/edit-trending-accounts`, {
        accounts: editTrendingAccounts.map(account => ({
          userId: account._id || null,
          username: account.username || null,
          trendingRank: account.trendingRank,  // Use the existing trendingRank
        })),
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      
      setTrendingAccounts(editTrendingAccounts);
      setEditTrending(false);
      alert('Trending accounts updated successfully.');
    } catch (error) {
      console.error('Error updating trending accounts:', error);
      alert('Failed to update trending accounts.');
    }
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.backendUrl}/api/posts`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          page: page.current,
          limit: 5,
          selectedPostTypes: selectedPostTypes.map(type => type.value),
          selectedCategories: selectedCategories.map(category => category.value),
          selectedSentiments: selectedSentiments.map(sentiment => sentiment.value),
          selectedTime,
          selectedSort,
          activeTab,
          keywords,
          currentUserId: userId,
        }
      });

      if (page.current === 0) {
        setPosts(response.data);

      
      } else {
        setPosts([...posts, ...response.data]); 
      }

      setHasMorePosts(response.data.length === 5);

    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setLoading(false);
  }
  

  useEffect(() => { 
    page.current = 0;
    fetchPosts();
  }, 
  //eslint-disable-next-line
  [selectedFilters, selectedPostTypes, selectedCategories, selectedSentiments, selectedTime, selectedSort, keywords, activeTab]);


  useEffect(() => {
    const fetchTrendingAccounts = async() => {
      setTrendingAccountsLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/auth/trending-accounts`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        const filledTrendingAccounts = ensureArrayLength8(response.data);
        setTrendingAccounts(filledTrendingAccounts);
        setEditTrendingAccounts(filledTrendingAccounts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
      setTrendingAccountsLoading(false);
    }

    const fetchCurrentUser = async () => {
      try {
        const fetchedUser = await fetchUser(userId, userToken);
        setCurrentUser(fetchedUser.data);  
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchBookmarkedPosts = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/bookmarks`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setBookmarkedPosts(response.data);
      } catch (error) {
        console.error('Error fetching bookmarked posts:', error);
      }
      setReady(true);
    };

    const fetchTrendingPosts = async () => {
      setTrendingPostsLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/trending-posts`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setTrendingPosts(response.data);
      } catch (error) {
        console.error('Error fetching trending posts:', error);
      }
      setTrendingPostsLoading(false);
    };
  
  

    const fetchData = async () => {
      await fetchCurrentUser();
      await fetchPosts();
      await fetchBookmarkedPosts();
      await fetchTrendingAccounts();
      await fetchTrendingPosts();
    };

    fetchData();
    //eslint-disable-next-line
  }, [userToken, userId]);



  const navigateToProfile = (username) => {
    if (!isAuthenticated) {
      alert('Please sign in to view profiles');
      return;
    }
    navigate(`/profile/${username}`);
  };

  const handleEditTrending = () => {
    if (!currentUser?.isAdmin) {
      alert('You are not authorized to edit trending accounts');
      return;
    }
    setEditTrending(!editTrending);
  };
  

  useEffect(() => {
    //when posts change, render the content or when user follows someone render again
    renderContent();
      //eslint-disable-next-line
  }, [posts, currentUser]);

  useEffect(() => {
    const currentRef = viewMoreRef.current; // Capture the current value of viewMoreRef
  
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMorePosts && !loading) {
          page.current += 1;
          fetchPosts(); // Fetch more posts when "View more" is in the viewport
        }
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: '0px', // Adjust as needed to trigger earlier
        threshold: 1.0,
      }
    );
  
    if (currentRef) {
      observer.observe(currentRef); // Start observing the "View more" button
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the captured ref for cleanup
      }
    };
  // eslint-disable-next-line
  }, [hasMorePosts, loading]);


  

  const refreshFollowingStatus = (post) => {
    post.isAuthorFollowed = !post.isAuthorFollowed;
  };





  const renderContent = () => {
    // Filtering is done in the backend, so we can just display the posts
    const filteredPosts = posts;
  
    if (filteredPosts.length === 0 && !loading) {
      return <div>No posts found</div>;
    }

  
    return (
      <div>
        {filteredPosts.flatMap((post) => {
          const postElements = [
            <Post
              key={post._id}
              postId={post._id}
              profilePic={post.author.profilePicture}
              author={post.author.name}
              verified={post.author.isUserVerified}
              username={post.author.username}
              time={formatTimeSince(timeSince(post.timePosted))}
              content={post.content}
              tradeDetails={post.tradeDetails}
              title={post.title}
              sentiment={post.sentiment}
              type={post.type}
              categories={post.categories}
              taggedAssets={post.taggedAssets}
              onProfileClick={() => navigateToProfile(post.author.username)}
              bookmarked={post.isBookmarked}
              onBookmarkClick={() =>
                handleBookmarkClick(
                  post._id,
                  post.isBookmarked
                )
              }
              isQuotePost={post.isQuotePost}
              quotePostId={post.quotePostId}
              isCopyRepost={false}
              images={post.images}
              isUpvoted={post.isUpvoted}
              isDownvoted={post.isDownvoted}
              isAdmin={currentUser?.isAdmin}
              caption={post.caption}
              refreshFeed={() => setPosts((posts) => posts.filter((p) => p._id !== post._id))}
              isAuthorFollowed={post.isAuthorFollowed || userId === post.author._id}
              authorId={post.author._id}
              refreshFollowingStatus={()=> refreshFollowingStatus(post)}
              upvoteCountParam={post.upvoteCount}
              downvoteCountParam={post.downvoteCount}
              commentCountParam={post.commentCount}
              repostCountParam={post.repostCount}
              isCopyRepostedParam = {post.isCopyReposted}
            />
          ];
  
          // Conditionally render copyReposts only if the filter is 'Most recent'
          if (selectedSort === 'Most recent') {
            postElements.push(
              ...post.copyReposts.map((reposterId) => (
                <Post
                  key={`${post._id}-${reposterId}`}
                  postId={post._id}
                  profilePic={`${post.author.profilePicture}`}
                  author={post.author.name}
                  verified={post.author.isUserVerified}
                  username={post.author.username}
                  taggedAssets={post.taggedAssets}
                  time={formatTimeSince(timeSince(post.timePosted))}
                  content={post.content}
                  tradeDetails={post.tradeDetails}
                  title={post.title}
                  sentiment={post.sentiment}
                  type={post.type}
                  categories={post.categories}
                  onProfileClick={() =>
                    navigateToProfile(post.author.username, post.author._id)
                  }
                  bookmarked={post.isBookmarked}
                  onBookmarkClick={() =>
                    handleBookmarkClick(
                      post._id,
                      post.isBookmarked
                    )
                  }
                  isQuotePost={post.isQuotePost}
                  quotePostId={post.quotePostId}
                  isCopyRepost={true}
                  copyReposterId={reposterId}
                  images={post.images}
                  isUpvoted={post.isUpvoted}
                  isDownvoted={post.isDownvoted}
                  isAdmin={currentUser?.isAdmin}
                  caption={post.caption}
                  refreshFeed={() => setPosts((posts) => posts.filter((p) => p._id !== post._id))}
                  isAuthorFollowed={ post.isAuthorFollowed || userId === post.author._id}
                  authorId={post.author._id}
                  refreshFollowingStatus={refreshFollowingStatus}
                  upvoteCountParam={post.upvoteCount}
                  downvoteCountParam={post.downvoteCount}
                  commentCountParam={post.commentCount}
                  repostCountParam={post.repostCount}
                  isCopyRepostedParam = {post.isCopyReposted}
                />
              ))
            );
          }
  
          return postElements;
        })}
        {hasMorePosts && !loading && (
          // Borrowing styles from CreatePost.css
          <button
            ref={viewMoreRef}
            style={{ margin: '0 auto', marginTop: '1rem' }}
            className="cp-AddElementButton"
            onClick={() => {
              page.current += 1;
              fetchPosts();
            }}
          >
            View more
          </button>
        )}
        {
          !hasMorePosts && !loading && posts.length > 0 && <p style={{textAlign: 'center', margin: '1rem 0'}}>No more posts to show</p>
        }

      </div>
    );
  };


  const handleAddKeyword = (keyword) => {
    if (keywords.includes(keyword) || keyword.trim() === '') {
      alert('Keyword already added or empty');
      return;
    }
    
    setKeywords((prevKeywords) => [...prevKeywords, keyword.trim()]);
    setNewKeyword('');
  };

  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddKeyword(newKeyword);
    }
  };
  

  const handleRemoveKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };


  const handleBookmarkClick = async (postId, isBookmarked) => {
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`;
      const method = isBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });
      const fetchBookmarkedPosts = async () => {
        try {
          const response = await axios.get(`${config.backendUrl}/api/bookmarks`, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          setBookmarkedPosts(response.data);
        } catch (error) {
          console.error('Error fetching bookmarked posts:', error);
        }
      };
      fetchBookmarkedPosts();
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
    if (filters.length === 0) {
      // Reset individual filter states when all filters are cleared
      setSelectedTime('Today');
      setSelectedSort('Most popular');
      setSelectedPostTypes([]);
      setSelectedCategories([]);
      setSelectedSentiments([]);
    }
  };




  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption.value);
  };

  const handleSortChange = (selectedOption) => {
    setSelectedSort(selectedOption.value);
  };

  const handlePostTypeChange = (selectedOption) => {
    setSelectedPostTypes(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategories(selectedOption);
  };

  const handleSentimentChange = (selectedOption) => {
    setSelectedSentiments(selectedOption);
  };

  // const handlePostTypeChangeQuick = (selectedOption) => {
  //   if (selectedOption.length > 1) {
  //     alert('You can only select one post type');
  //     return;
  //   }
  //   if (selectedOption.value === selectedPostTypeQuick) {
  //     setSelectedPostTypesQuick([]);
  //     setSelectedPostTypeQuick('');
  //     return;
  //   }
  //   setSelectedPostTypeQuick(selectedOption.value); 
  //   setSelectedPostTypesQuick(selectedOption);
  // }

  // const handleCategoryChangeQuick = (selectedOption) => {
  //   if (selectedOption.length > 3) {
  //     alert('You can only select up to 3 categories');
  //     return;
  //   }
  //   setSelectedCategoriesQuick(selectedOption);
  //   setSelectedCategoryQuick(selectedOption.map((option) => option.value));
  // };

  const handleReset = () => {
    setSelectedTime('Today');
    setSelectedSort('Most popular');
    setSelectedPostTypes([]);
    setSelectedCategories([]);
    setSelectedSentiments([]);
    setKeywords([]);
    setSelectedFilters([]);
  };

  const handleInputChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const handleAccountClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccountIndex(index);
  };

  const getExcerpt = (content) => {
    const words = content.split(" ");
    const excerpt = words.slice(0, 30).join(" "); 
    return words.length > 30 ? `${excerpt}...` : excerpt; 
  };


 


  
  const renderFeaturedPosts = () => {
    if (trendingPostsLoading) {
      return <LoadingSpinner />;
    }
    if (trendingPosts.length === 0) {
      return <p>No trending posts available.</p>;
    }

    return (
      <div className="featured-posts-list">
      {trendingPosts.map((post) => (
        <div className="featured-post-item" onClick={() => navigate(`/post/${post._id}`)} key={post._id}>
          <p className="featured-post-title bold">
            {post.title ? post.title : <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getExcerpt(post.content), allowedHTML) }} />}
          </p>
          <p className="featured-post-author">by {post.author.name}</p>
        </div>
      ))}
    </div>
    
    );
  };
  /*

      <div
        className="FeedSearchWrapper"
        style={{
          margin: 0,
          borderBottomLeftRadius: showFilters && 0,
          borderBottomRightRadius: showFilters && 0,
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <input
          className="FeedSearchBar bold"
          type="text"
          placeholder="Search news topics"
          value={newSearchQuery}
          onChange={handleInputChange}
          onKeyDown={handleAddSearchQuery}
          disabled={searchingNews}
        />
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <button
            onClick={() => searchNews(newSearchQuery, selectedStyle, selectedLength)}
            className="CreatePostFeedButton ne-search-button"
            disabled={searchingNews}
          >
            Search
          </button>
          <button
            className="ne-filter-button"
            onClick={() => setShowFilters((prev) => !prev)}
            disabled={searchingNews}
          >
            {`Filters ${showFilters ? '-' : '+'}`}
          </button>
        </div>

        {showFilters && (
          <div className="ne-FilterDropdown">
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Style</span>
              <div className="ne-FilterOptions ne-style">
                {['Creative', 'Funny', 'Blunt', 'Simple'].map((style) => (
                  <button
                    key={style}
                    className={`ne-FilterOption ${selectedStyle === style ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(style, setSelectedStyle, selectedStyle)}
                    disabled={searchingNews}
                  >
                    {style}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Length</span>
              <div className="ne-FilterOptions ne-length">
                {['Detailed', 'Quick'].map((length) => (
                  <button
                    key={length}
                    className={`ne-FilterOption ${selectedLength === length ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(length, setSelectedLength, selectedLength)}
                    disabled={searchingNews}
                  >
                    {length}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Timeframe</span>
              <div className="ne-FilterOptions ne-timeframe">
                {['Today', 'Past week', 'Past month', 'Past year'].map((timeFrame) => (
                  <button
                    key={timeFrame}
                    className={`ne-FilterOption ${selectedTimeFrame === timeFrame ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(timeFrame, setSelectedTimeFrame, selectedTimeFrame)}
                    disabled={searchingNews}
                  >
                    {timeFrame}
                  </button>
                ))}
              </div>
            </div>
            <div className="ne-FilterCategory">
              <span className="ne-FilterCategoryTitle bold">Sources</span>
              <div className="ne-FilterOptions ne-sources">
                {['Top financial sources', 'Major publications'].map((source) => (
                  <button
                    key={source}
                    className={`ne-FilterOption ${selectedSource === source ? 'ne-active' : ''}`}
                    onClick={() => toggleSelection(source, setSelectedSource, selectedSource)}
                    disabled={searchingNews}
                  >
                    {source}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>


  */

  return (
    <div className="FeedContainer">
   <div className="FeedSearchWrapper"
     style={{
       borderBottomLeftRadius: showFilters && 0,
       borderBottomRightRadius: showFilters && 0,
       position: 'relative',
       overflow: 'visible',
     }}
>
  <div className="FeedSearchBarKeywordContainer" style={{ marginRight: keywords.length > 0 ? '1rem' : '0rem' }}>
    {keywords.map((keyword) => (
      <div className="FeedSearchBarKeyword" key={keyword}>
        {keyword}
        <span className="keyword-x">
          <FontAwesomeIcon icon={faTimes} onClick={() => handleRemoveKeyword(keyword)} />
        </span>
      </div>
    ))}
  </div>

  <input
    className="FeedSearchBar bold"
    type="text"
    placeholder="Search posts"
    value={newKeyword}
    onChange={handleInputChange}
    onKeyDown={handleKeyDown}
  />

<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <button
            className="CreatePostFeedButton ne-search-button"
            onClick={()=> {handleAddKeyword(newKeyword)}}
          >
            Search
          </button>
          <button
            className="ne-filter-button"
            onClick={() => setShowFilters((prev) => !prev)}
          >
            {`Filters ${showFilters ? '-' : '+'}`}
          </button>
        </div>
  {showFilters && (
  <div className="ne-FilterDropdown">
    
    {/* Filter by Time */}
    <div className="ne-FilterCategory">
      <span className="ne-FilterCategoryTitle bold">Time</span>
      <div className="ne-FilterOptions">
        <Select
          className="FeedSortButton"
          value={timeOptions.find((option) => option.value === selectedTime)}
          onChange={handleTimeChange}
          options={timeOptions}
          isSearchable={false}
          unstyled
          styles={customStylesTrans(tc())}
          title="Filter by time"
        />
      </div>
    </div>

    {/* Filter by Post Type */}
    <div className="ne-FilterCategory">
      <span className="ne-FilterCategoryTitle bold">Post Type</span>
      <div className="ne-FilterOptions">
        <Select
          className="FeedPostType"
          value={selectedPostTypes}
          onChange={handlePostTypeChange}
          options={postTypeOptions}
          isMulti
          unstyled
          isSearchable={false}
          styles={customStylesPlusTrans(tc())}
          closeMenuOnSelect={false}
          placeholder="Post type +"
        />
      </div>
    </div>

    {/* Filter by Category */}
    <div className="ne-FilterCategory">
      <span className="ne-FilterCategoryTitle bold">Category</span>
      <div className="ne-FilterOptions">
        <Select
          className="FeedCategory"
          value={selectedCategories}
          onChange={handleCategoryChange}
          options={categoryOptions}
          isMulti
          unstyled
          isSearchable={false}
          styles={customStylesPlusTrans(tc())}
          closeMenuOnSelect={false}
          placeholder="Category +"
        />
      </div>
    </div>

    {/* Filter by Sentiment */}
    <div className="ne-FilterCategory">
      <span className="ne-FilterCategoryTitle bold">Sentiment</span>
      <div className="ne-FilterOptions">
        <Select
          className="FeedSentiment"
          value={selectedSentiments}
          onChange={handleSentimentChange}
          options={sentimentOptions}
          isMulti
          unstyled
          isSearchable={false}
          styles={customStylesPlusTrans(tc())}
          closeMenuOnSelect={false}
          placeholder="Sentiment +"
        />
      </div>
    </div>
    
  </div>
)}
</div>

<div className="FeedSortBarWrapper">
  <div className="FeedSortBar" style={{ paddingTop: '0rem' }}>
    <div className="FeedSortItems">
      <span className="FeedSortText">Sort by:</span>
      <Select
        className="FeedSortButton"
        value={sortOptions.find((option) => option.value === selectedSort)}
        onChange={handleSortChange}
        options={sortOptions}
        isSearchable={false}
        unstyled
        styles={customStylesTrans(tc())}
      />
    </div>
    <div className="FeedSortItems">
      <button className="FeedKeywordButton transBorder" onClick={async () => fetchPosts()}>
        Refresh
      </button>
      <button className="FeedKeywordReset transBorder" onClick={handleReset}>
        Reset
      </button>
    </div>
  </div>
</div>
   
      <div className="FeedSubHeader">
        <div className="FeedNavItems">
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'everything' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => setActiveTab('everything')}
          >
            Everything
          </button>
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'following' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => {
              if (!isAuthenticated) {
                alert('Please sign in to view your following feed');
                return;
              }

              setActiveTab('following');
            }}
          >
            Following
          </button>
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'friends' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => {
              if (!isAuthenticated) {
                alert('Please sign in to view your friends feed');
                return;
              }

              setActiveTab('friends');
            }}
          >
            Friends
          </button>
          <button
            className={`FeedNavButton FeedBold ${activeTab === 'bookmarks' ? 'FeedNavButtonActive' : ''}`}
            onClick={() => {
              if (!isAuthenticated) {
                alert('Please sign in to view your bookmarks');
                return;
              }

              setActiveTab('bookmarks');
            }}
          >
            Bookmarks
          </button>
        </div>

            {/*Temporarily removed the add new and edit buttons */}
        <div className="FeedNavActions">
            <button style ={{backgroundColor: 'transparent', color: 'transparent',cursor:'default'}} className="FeedNavActionButton">
              Add new +
            </button>
            <button style ={{backgroundColor: 'transparent', color: 'transparent',cursor:'default'}} className="FeedNavActionButton">
              Edit
            </button>
          </div>
      </div>
   
      <div className="FeedMainContent">
        <div className="FeedContent" id = "feedContent">
          {ready && renderContent()}
        </div>
        <div id = "sidebar" className="RightSidebar">
          <div className="TrendingTopics">
          <div className="CreatePostFeed">
            <h2 className='RightSideBarH2 bold'>Share your thoughts: </h2>
            <textarea 
            placeholder="Write something to the vestr community!" 
            className="CreatePostFeedInput" 
            value={quickPostContent}
            onChange={(e) => setQuickPostContent(e.target.value)}
            
            />


{/* 
             <div className="CreatePostFeedButtons">
             <Select
              className="FeedPostType"
              value={selectedPostTypesQuick}
              onChange={handlePostTypeChangeQuick}
              options={postTypeOptions}
              unstyled
              isSearchable={false}
              styles={customStylesPlus(tc())}
              closeMenuOnSelect={true}
              placeholder="Post type +"
              
            />
            <Select
              className="FeedCategory Quick"
              value={selectedCategoriesQuick}
              onChange={handleCategoryChangeQuick}
              options={categoryOptions}
              isMulti
              unstyled
              isSearchable={false}
              styles={customStylesPlus(tc())}
              closeMenuOnSelect={false}
              placeholder="Category +"

            />
             
              <button className="CreatePostFeedButton" 
              onClick={()=> {
                if (!isAuthenticated) {
                  alert('Please sign in to create a post');
                  return
                }
                navigate(`/createpost`, { state: { viewingDraft: true, fromFeed: true } })
                }}>
                 Drafts 
              </button> 
              </div>
             */}
            <div className="CreatePostFeedButtons">
              <button 
              className="CreatePostFeedButton transBorder" onClick={() => {
                if (!isAuthenticated) {
                  alert('Please sign in to create a post');
                  return;
                }
                navigate(`/createpost`, { state: { 
                  quickPostContent: quickPostContent,
                  selectedCategoryQuick: selectedCategoryQuick,
                  selectedPostTypeQuick: selectedPostTypeQuick,
                   fromFeed: true 
                  } });
              }}>Full View</button>
              <button 
              className="CreatePostFeedButton transBorder" onClick={async() => {
                if (!isAuthenticated) {
                  alert('Please sign in to create a post');
                  return;
                }
                if (!quickPostContent) {
                  alert('Content cannot be empty');
                  return;
                }
                const response = await handleQuickPostSaveToDrafts(quickPostContent, userToken);
                if (response.status === 201) {
                  alert('Post saved to drafts');
                  setQuickPostContent('');
                } else {
                  alert('Error saving post');
                }

              }}>Save to drafts</button>
        

              <button
              style = 
              {{
                color: 'var(--stock-change-pos)',
                backgroundColor: 'transparent'
                , border: '0.5px solid var(--stock-change-pos)'}}
              className="CreatePostFeedButton bold" onClick={async() => {
                if (!isAuthenticated) {
                  alert('Please sign in to create a post');
                  return;
                }
                if (!quickPostContent) {
                  alert('Content cannot be empty');
                  return;
                }
                const response = await handleQuickPostSubmit(quickPostContent,selectedCategoryQuick,selectedPostTypeQuick, userToken);
                if (response.status === 201) {
                  alert('Post submitted successfully');
                  setQuickPostContent('');
                  setSelectedCategoriesQuick([]);
                  setSelectedCategoryQuick([]);
                  setSelectedPostTypesQuick([]);
                  setSelectedPostTypeQuick('');
             
                } else {
                  alert('Error submitting post');
                }
              }
              }>Post</button>
            </div>


            
          </div>


          </div>
              <div className="TrendingAccounts">
      <h2 className="RightSideBarH2 bold" style={{ display: 'flex', gap: currentUser?.isAdmin ? '0.5rem' : '0rem', marginTop: 0 }}>
        <span>
          {currentUser?.isAdmin && (
            <FontAwesomeIcon 
              icon={faEdit} 
              style={{ cursor: 'pointer', fontSize: '1.25rem' }} 
              onClick={handleEditTrending}
            />
          )}
        </span>
        Trending Accounts:
      </h2>

      {trendingAccountsLoading ? (
      <LoadingSpinner />
      ) : (
        <ul className="account-grid">
          {/* Ensure there are always exactly 8 slots */}
          {Array.from({ length: 8 }).map((_, index) => {
            // Fetch the account that has the correct trendingRank
            const account = editTrending 
              ? editTrendingAccounts.find(account => account.trendingRank === index + 1)
              : trendingAccounts.find(account => account.trendingRank === index + 1);

            return account && account._id ? (
              <li
                key={account._id}
                className="account-item"
             
              >
                   <span id={`${index + 1}-rank`} className="rank bold">{index + 1}</span>
                <img 
                onClick={(event) => {
                  if (editTrending) {
                    handleAccountClick(event, index);  // Handle the click to edit the specific index
                  } else {
                    navigateToProfile(account.username);  // Navigate to the profile
                  }
                }}
                
                src={getImageUrl(account.profilePicture) || pfp} alt={account.name} className="profile-pic" />

                  <div className="trending-account-info"
                  onClick={(event) => {
                    if (editTrending) {
                      handleAccountClick(event, index);  // Handle the click to edit the specific index
                    } else {
                      navigateToProfile(account.username);  // Navigate to the profile
                    }
                  }}
                  
                  >
              <span className='bold'>{account.name}</span>
              <span className='bold' style ={{color:'var(--action-grey)'}}>@{account.username}</span>
           
            </div>

            {account._id ? (
                <button
                  onClick={() =>{
                    if (!isAuthenticated){
                    alert('Please sign in to follow an account');
                    return;
                  } 
                  handleFollowToggle(account._id, account.isFollowing)}}
                  className="CreatePostFeedButton transBorder"
                >
                  {account.isFollowing ? 'Unfollow' : 'Follow'}
                </button>
              ) : null}

              </li>
            ) : editTrending && (
              // Show placeholder when no account exists for this rank
              <li
                key={`placeholder-${index}`}
                className="account-item"
                onClick={(event) => handleAccountClick(event, index)}  // Handle placeholder click
              >
                      <span className="rank bold">{index + 1}</span>
                <img src={pfp} alt="Placeholder" className="profile-pic" style = {{border:'var(--border)'}}/>
              
              </li>
            );
          })}
        </ul>
      )}

      {editTrending && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', gap: '1rem' }}>
          {/* Borrowing styles from CreatePost.css */}
          <button onClick={handleSaveTrendingAccounts} className="cp-AddElementButton">
            Submit
          </button>
          <button onClick={handleCancelEdit} className="cp-AddElementButton">
            Cancel
          </button>
        </div>
      )}
    </div>

 
        <Popover
  open={Boolean(anchorEl)}
  anchorEl={anchorEl}
  onClose={() => setAnchorEl(null)}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
>
  <div style={{ padding: '1rem', maxWidth: '50rem' }}>
    <TextField
      label="Search for a user"
      fullWidth
      value={searchQuery}
      onChange={(e) => handleSearch(e.target.value)}
    />
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {searchResults.map(user => (
        <li 
          key={user._id} 
          onClick={() => {
            handleUserSelect(user); // Set the selected user immediately
            handleSubmitEdit(); // Immediately submit the edit
          }} 
          className="TrendingUserResult"

        >
          {user.name} (@{user.username})
        </li>
      ))}
    </ul>
  </div>
</Popover>
<a style= {{color: dk() ? '#fff' : '#000', textDecoration:'none'}}href="https://simplify.jobs?utm_source=vestr" target="_blank" rel="noopener noreferrer" className="SponsorLink">
  <div className="Sponsor">
    <span className="bold" style={{ color: 'var(--action-grey)', fontSize: '1.25rem' }}>Sponsored</span>
    <h2 style={{ margin: 0 }} className="RightSideBarH2 bold">Simplify</h2>
    <p className="SponsorText">
      Your entire job search. Powered by one profile. Get personalized job recommendations, craft tailored resumes, autofill and track your job applications.
    </p>
    <img src={simplify} alt="simplify" />
    <span style={{ color: 'var(--action-grey)', fontSize: '1.25rem' }}>Want to sponsor this page? Email adam@vestr.io</span>
  </div>
</a>
          <div className="FeaturedPosts">
          <h2 className="RightSideBarH2 bold">Featured Posts:</h2>
          {renderFeaturedPosts()}
        </div>

        </div>
      </div>
      </div>
  );
};

export default Feed;
