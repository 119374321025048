/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import {  useParams, useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../styles/Stock.css'; // Reuse the same styles
import { createStockChart } from '../helper/StockChartHelper';
import {
   getUnixTimestamp,
   formatNumber,
   getUnixTimestampRange,
   filters,
   getThreeMonthsAgo,
   fetchDailyOpenClose,
   getDailyChange,
   fetchStockLists,
   filterHighMap,
   getLastValidTradingDay,
   portfolioIntervalMap,
   fetchUserPortfolio,
    handleAddToWatchlist,
    handleRemoveFromWatchlist,
    getWatchList
} from '../helper/MarketsHelper';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import config from '../config';
import {  LoadingSpinner, formatOptionNameFromTicker, customStylesPlus, removeCommas as rc, formatCurrency as fc} from '../helper/StylesHelper';
import { Dialog, DialogActions, DialogTitle, Button, TextField } from '@mui/material';
import MarketSearchBar from './MarketSearchBar';
import BackButton from './BackButton';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
export default function Option() {
    useResponsiveWidth();
    const { optionTicker } = useParams();
    const location = useLocation();
    const underlyingTicker = location.state?.underLyingTicker ||  null;
    const { isAuthenticated, userToken } = useContext(AuthContext);
    const {tc, dk, theme} = useContext(ThemeContext);
    const [optionData, setOptionData] = useState([]);
    //eslint-disable-next-line
    const [dailyChangeData, setDailyChangeData] = useState({});
    const [dailyData, setDailyData] = useState({});
    const [filter, setFilter] = useState('1d');
    const [currentPrice, setCurrentPrice] = useState(null);
    const [changeAmount, setChangeAmount] = useState(null);
    const [changePercent, setChangePercent] = useState(null);
    const [optionName, setOptionName] = useState(optionTicker); // Use optionTicker as placeholder
    const [mainOptionDataFetched, setMainOptionDataFetched] = useState(false);
    const chartRef = useRef(null);
    const chartInstance = useRef(null); // Store the chart instance
    const [referencePriceForChart, setReferencePriceForChart] = useState(null);
    const [periodHigh, setPeriodHigh] = useState(null); // Store 52-week high
    const [periodLow, setPeriodLow] = useState(null); // Store 52-week high
    const [hoveredPrice, setHoveredPrice] = useState(null);
    const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
    const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
    const [hoveredDateTime, setHoveredDateTime] = useState(null);
    const [isHovering, setIsHovering] = useState(false);

    //eslint-disable-next-line
    const [userPortfolio, setUserPortfolio] = useState(null); 

    // List management states
    const [listOptions, setListOptions] = useState([]); // Options for stock lists
    const [selectedLists, setSelectedLists] = useState([]); // Selected lists for the option


    const [openBuyModal, setOpenBuyModal] = useState(false);
    const [openSellModal, setOpenSellModal] = useState(false);
    const [contractQuantity, setContractQuantity] = useState(1);
    const [estimatedCost, setEstimatedCost] = useState(null);
    const [estimatedRevenue, setEstimatedRevenue] = useState(null);
    const [stock, setStock] = useState('');

    const navigate = useNavigate();


    const [isInWatchlist, setIsInWatchlist] = useState(false);
    useEffect(() => {
        if (isAuthenticated) {
          getWatchList(optionTicker, userToken, setIsInWatchlist);
        }
      }, [isAuthenticated, userToken]);
  




  const handleBuyOptionClick = () => {
    alert('This feature is not available yet');
    return;
    setOpenBuyModal(true);
  
    if (currentPrice) {
        setEstimatedCost((contractQuantity * currentPrice * 100).toFixed(2)); // Price is multiplied by 100 for options
    }
};

// Update: Open the sell modal
const handleSellOptionClick = () => {
  alert('This feature is not available yet');
  return;
    setOpenSellModal(true);
    if (currentPrice) {
        setEstimatedRevenue((contractQuantity * currentPrice * 100).toFixed(2)); // Price is multiplied by 100 for options
    }
};

// Update: Handle the change in contract quantity input
const handleContractQuantityChange = (event) => {
    const quantity = parseFloat(event.target.value);
    setContractQuantity(quantity);
    if (currentPrice) {
        setEstimatedCost((quantity * currentPrice * 100).toFixed(2)); // Multiply by 100
        setEstimatedRevenue((quantity * currentPrice * 100).toFixed(2)); // Multiply by 100
    }
};


  const handleCloseSellModal = () => {
    setOpenSellModal(false);
};


  const handleCloseBuyModal = () => {
    setOpenBuyModal(false);
  };

    useEffect(() => {
        setOptionName(formatOptionNameFromTicker(optionTicker)); // Format and set the option name
    }, [optionTicker]);

    const fetchOptionData = async () => {
        setChangeAmount(null);
        setPeriodHigh(null);
        setPeriodLow(null);
        setMainOptionDataFetched(false);
        setReferencePriceForChart(null);
        try {
            let fromDateUnixMs;
            let multiplier, timespan;
            let toDateUnixMs = getUnixTimestamp();
            if (filter === '3m') {
                fromDateUnixMs = getThreeMonthsAgo();
            } else if (filter === '1d') {
                fromDateUnixMs = getUnixTimestampRange('1d').startTime;
                toDateUnixMs = getUnixTimestampRange('1d').endTime;
            } else {
                fromDateUnixMs = getUnixTimestampRange(filter);
            }   

            //use portfolioIntervalMap for now
            const intervalArray = portfolioIntervalMap[filter];
            multiplier = intervalArray[0];
            timespan = intervalArray[1];

            const response = await axios.get(`${config.backendUrl}/api/polygon/option/datapoints`, {
                params: {
                    optionTicker,
                    fromDateUnixMs,
                    toDateUnixMs,
                    multiplier,
                    timespan
                }
            });

            const data = response.data || [];
            setOptionData(data);

            if (data.length > 0) {
                const latestPrice = rc(currentPrice || data[data.length - 1].c);
                const startPrice = rc(data[0].c);
                const change = latestPrice - startPrice;
                const changePercentage = (change / startPrice) * 100;

                setChangeAmount(change.toFixed(2));
                setChangePercent(changePercentage.toFixed(2));

                const highPrices = data.map(day => day.h);
                const lowPrices = data.map(day => day.l);
                const highestPrice = Math.max(...highPrices);
                const lowestPrice = Math.min(...lowPrices);
                setPeriodHigh(highestPrice);
                setPeriodLow(lowestPrice);
                
                setReferencePriceForChart(startPrice);
            } else {
                setChangeAmount(null);
                setChangePercent(null);
                setPeriodHigh(null);
                setPeriodLow(null);
            }
        } catch (error) {
            console.error('Error fetching option data:', error);
        }
        setMainOptionDataFetched(true);
    };


    useEffect(() => {
        getDailyChange(optionTicker, setCurrentPrice, setDailyChangeData, underlyingTicker);
    }, [optionTicker]);


    useEffect(()=> {
        const fetchOptionDailyData = async () => {
            try {
              const lastValidDay = await getLastValidTradingDay();  
              fetchDailyOpenClose(lastValidDay, optionTicker, setDailyData);  
            } catch (error) {
              console.error('Error fetching last valid trading day or daily open/close data:', error);
            }
          };
          fetchOptionDailyData();
    }, [optionTicker]);

 

    useEffect(() => {
        fetchOptionData();
    }, [filter]);

    useEffect(() => {
        if (!chartRef?.current) return;
        const cleanup = createStockChart({
            chartRef,
            chartInstanceRef: chartInstance,
            stockData: optionData, // Use optionData for charting
            referencePriceForChart,
            changeAmount,
            setHoveredPrice,
            setHoveredChangeAmount,
            setHoveredChangePercent,
            setHoveredDateTime,
            setIsHovering,
            theme,
        });
        return cleanup;
    }, [optionData, referencePriceForChart, changeAmount, chartRef, theme]);

    const handleBackClick = () => {
        window.history.back();
    };

    // Fetch the stock lists for the authenticated user and pre-select lists containing the option
    useEffect(() => {
        if (userToken) {
            fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, optionTicker);
        }
        //eslint-disable-next-line
    }, [userToken]);

    const handleConfirmOptionBuy = async () => {  
        try {
            await axios.post(`${config.backendUrl}/api/stockActions/option/buy`, {
                optionTicker,
                contractQuantity,
            }, {headers: { Authorization: `Bearer ${userToken}` }});
            setOpenBuyModal(false);
            fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio);
            alert('Option bought successfully');
        } catch (error) {
            alert(error.response.data.message || 'Error buying option');
            console.error('Error buying option:', error);
        }
    };

    const handleConfirmOptionSell = async () => {
        try {
            await axios.post(`${config.backendUrl}/api/stockActions/option/sell`, {  
                optionTicker,
                contractQuantity,
            },
            {headers: { Authorization: `Bearer ${userToken}` }}
        );
            setOpenSellModal(false);
            fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio);
            alert('Option sold successfully');
        } catch (error) {
            alert(error.response.data.message || 'Error selling option');
            console.error('Error selling option:', error);
        }   
    };

    
    return (
        <div className="StockContainerWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="StockContainer">
            <MarketSearchBar
              stock={stock}
              setStock={setStock}
              isAuthenticated={isAuthenticated}
              userToken={userToken}
              navigate={navigate}
            />


                <div className="MainStockContainer">
                    <div className="StockPriceContent">
                        <div className="StockGraph">
                            <div className="StockHeader">
                                <div className="StockInfo">
                                <BackButton callback={()=> {window.history.back()}} fontSize='var(--font-med-l)' padding='0 0 1.25rem 0'/>
                                    <h2 className="StockTickerHeader bold">{optionTicker}</h2>
                                    <h1 className="StockName bold">{optionName}</h1>
                                    <p className="StockPrice bold">
                                        ${isHovering ? hoveredPrice : currentPrice}
                                    </p>
                                    <p className={`StockChange bold ${(isHovering ? hoveredChangeAmount : changeAmount) >= 0 ? 'positive' : 'negative'}`}>
                                        {isHovering ? (
                                            <>
                                                {hoveredChangeAmount >= 0 ? '+' : ''}
                                                {hoveredChangeAmount} ({hoveredChangePercent}%)
                                            </>
                                        ) : changeAmount !== null ? (
                                            <>
                                                {changeAmount >= 0 ? '+' : ''}
                                                {changeAmount} ({changePercent}%)
                                            </>
                                        ) : (
                                            <span style={{ color: 'var(--action-grey)' }}>--</span>
                                        )}
                                        <span className="StockDuration"> {isHovering ? hoveredDateTime : filter!=='all time' ?
                                        ` past ${filters[filter]}` : ' all time'}
                                        </span>
                                    </p>

                                    <div className="StockActions">
                                        <button onClick={handleBuyOptionClick} className="CreatePostFeedButton greenButton">Buy</button>
                                        <button className="CreatePostFeedButton" onClick={handleSellOptionClick}>Sell</button>

                                        <button className="CreatePostFeedButton" onClick = {
                                          isInWatchlist ? 
                                          () => handleRemoveFromWatchlist(optionTicker, userToken, setIsInWatchlist) :  
                                          () => handleAddToWatchlist(optionTicker, userToken, setIsInWatchlist)
                                     }>
                                          {isInWatchlist ? 'Watchlist -' : 'Watchlist + '}
                                     </button>
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="StockGraphMain">
                                {!mainOptionDataFetched ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--stock-chart-height)' }}>
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    <canvas style = {{height: 'var(--stock-chart-height)'}} ref={chartRef}></canvas>
                                )}
                                <div className="StockFilters">
                                    {Object.keys(filters).map((key) => (
                                        <button
                                            key={key}
                                            className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
                                            onClick={() => setFilter(key)}
                                        >
                                            {key}
                                        </button>
                                    ))}
                                </div>

                                <div className="StockStatistics" style = {{paddingBottom: '4rem'}}>
                            <h2 className="StatsHeader">Stats</h2>
                            <table className="StatsTable">
                            <tbody style={{ width: '100%' }}>
      <tr>
        <td>
          <div className="label-value-pair">
            <span>Open</span>
            <strong>{fc(dailyData?.open) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span>Close</span>
            <strong>{fc(dailyData?.close) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span>Volume</span>
            <strong>{dailyData?.volume ? formatNumber(dailyData.volume) : '--'}</strong>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div className="label-value-pair">
            <span>High</span>
            <strong>{fc(dailyData?.high) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span>Low</span>
            <strong>{fc(dailyData?.low) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span style={{ textTransform: 'capitalize' }}>{filterHighMap[filter]} High</span>
            <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
          </div>
        </td>
       
      </tr>
      <tr>
        <td>
          <div className="label-value-pair">
            <span>Pre-Market</span>
            <strong>{fc(dailyData?.preMarket) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span>After-Hours</span>
            <strong>{fc(dailyData?.afterHours) || '--'}</strong>
          </div>
        </td>
        <td>
          <div className="label-value-pair">
            <span style={{ textTransform: 'capitalize' }}>{filterHighMap[filter]} Low</span>
            <strong>{periodLow ? fc(periodLow) : '--'}</strong>
          </div>
        </td>
       
      </tr>
    </tbody>
                            </table>
                               </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Buy Modal */}
            <Dialog
                className="stock-dialog"
                open={openBuyModal}
                onClose={handleCloseBuyModal}
                sx={{ 
                    '& .MuiDialog-paper': {
                      padding: '2rem',
                      borderRadius: '1rem',
                      backgroundColor: dk() ? '#030902' : '#fff',
                      width: '30vw'
                    
                    },
                }}
            >
                <DialogTitle sx={{ fontSize: '1.39rem', paddingTop: '1rem', paddingBottom: '1rem',  color: 'var(--text-color)', }}>
                    Do you want to place a market order to buy <b>{optionName}</b> at <b>${fc(currentPrice)}</b>?
                </DialogTitle>
                <div style={{ padding: '2rem' }}>
                    <TextField
                        label="Contracts"
                        type="number"
                        value={contractQuantity}
                        onChange={handleContractQuantityChange}
                        fullWidth
                        margin="normal"
                        sx={{ 
                            fontSize: '1.39rem',
                            '& .MuiInputBase-root': { 
                              fontSize: '1.39rem', 
                              color: 'var(--text-color)', // Custom font color for input text
                            },
                            // Custom border color
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'var(--border-color)',  // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--border-color)',  // Border color on hover
                              },
                            },
                          }}
                   
                    />
                    <p style={{ fontSize: '1.25rem', marginTop: '1rem' }}>
                        Estimated cost: <b>${fc(estimatedCost)}</b> (${fc(currentPrice)} x 100 x {fc(contractQuantity, 0)} contracts)
                    </p>
                </div>
                <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                    <Button onClick={handleCloseBuyModal} sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Cancel</Button>
                    <Button onClick={handleConfirmOptionBuy} color="primary" sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>
                        Confirm Buy
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Sell Modal */}
            <Dialog
                className="stock-dialog"
                open={openSellModal}
                onClose={handleCloseSellModal}
                sx={{ 
                    '& .MuiDialog-paper': {
                      padding: '2rem',
                      borderRadius: '1rem',
                      backgroundColor: dk() ? '#030902' : '#fff',
                      width: '30vw'
                    
                    },
                }}
            >
                <DialogTitle  sx={{ fontSize: '1.39rem', paddingTop: '1rem', paddingBottom: '1rem',  color: 'var(--text-color)', }}>
                    Do you want to place a market order to sell <b>{optionName}</b> at <b>${fc(currentPrice)}</b>?
                </DialogTitle>
                <div style={{ padding: '2rem' }}>
                    <TextField
                        label="Contracts"
                        type="number"
                        value={contractQuantity}
                        onChange={handleContractQuantityChange}
                        fullWidth
                        margin="normal"
                        sx={{ 
                            fontSize: '1.39rem',
                            '& .MuiInputBase-root': { 
                              fontSize: '1.39rem', 
                              color: 'var(--text-color)', // Custom font color for input text
                            },
                            // Custom border color
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'var(--border-color)',  // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: 'var(--border-color)',  // Border color on hover
                              },
                              
                            },
                          }}
                        
                   
            
                    />
                    <p style={{ fontSize: '1.25rem', marginTop: '1rem',  color: 'var(--text-color)' }}>
                        Estimated revenue: <b>${estimatedRevenue}</b>  (${fc(currentPrice)} x 100 x {fc(contractQuantity, 0)} contracts)
                    </p>
                </div>
                <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                    <Button onClick={handleCloseSellModal} sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Cancel</Button>
                    <Button onClick={handleConfirmOptionSell} color="primary" sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>
                        Confirm Sell
                    </Button>
                </DialogActions>
            </Dialog>

 
        </div>
    );
}